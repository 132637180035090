import type { RuntimeParams } from '@24i/nxg-core-utils/src/api';
import type { RuntimeConfig } from '@24i/nxg-sdk-photon';
import { Api } from 'renative';

export const getParamsFromRuntimeConfig = (runtimeConfig: RuntimeConfig): RuntimeParams => {
    const val = runtimeConfig.apiService;
    let serviceId;
    let applicationId;

    if (val) {
        serviceId = val.id;
        applicationId = val.appId[Api.platform] || val.appId.default;
    }

    return {
        serviceId,
        applicationId,
    };
};
