import { BaseApiParams } from '@24i/nxg-core-utils/src/api';
import { ASYNC_STORAGE_KEY_USER_TOKEN } from '@24i/nxg-core-utils/src/constants';
import { extractCleengDataFromBackstageToken } from '@24i/nxg-core-utils';
import autoBind from 'auto-bind';
import {
    AddToFavoriesRequest,
    Asset,
    CanDeleteProfileContext,
    CanDeleteProfileResponse,
    CleengTokenData,
    CreateProfileRequest,
    DeleteFromFavoritesRequest,
    DeleteProfileRequest,
    DeleteProfileResolutionStep,
    Event,
    EventGuard,
    EventsRequest,
    Favorite,
    FetchProfileRequest,
    LoginRequest,
    Profile,
    ProfileImage,
    Rating,
    RATING_SYSTEM,
    RecoverPasswordRequest,
    RegisterRequest,
    SelectUserProfileRequest,
    Token,
    UpdatePasswordRequest,
    UpdateProfileRequest,
    UpdateUserEmailRequest,
    UpdateUserRequest,
    User,
    UserDataClient,
    ValidatePinRequest,
    ValidPin,
    ValidPinGuard,
} from '@24i/nxg-sdk-photon';
import { Storage } from '@24i/nxg-sdk-quantum';
import { BackstageApiBase } from '../../base';
import {
    AddToFavoriteResponseGuard,
    CreateProfileResponseGuard,
    FetchFavoritesResponseGuard,
    FetchProfileImagesResponseGuard,
    FetchProfileResponseGuard,
    FetchProfilesResponseGuard,
    FetchUserResponseGuard,
    GetAssetRatingForUserResponseGuard,
    GetOAuthUrlResponseGuard,
    GetUserOauthTokenResponseGuard,
    LoginResponseGuard,
    SelectUserProfileResponseGuard,
    UpdateProfileResponseGuard,
} from './guards';
import { mapProfileResponse, mapProfilesResponse, mapUser } from './mappers';
import { mapRatingResponseToRating, toBSRating, setPin, getPinIfExists } from './utils';

const USER_ERROR = {
    ITEM_NOT_FOUND: 'ITEM_NOT_FOUND',
    FAVORITE_ALREADY_EXISTS: 'FAVORITE_ALREADY_EXISTS',
};

const getToken = () => Storage.getItem(ASYNC_STORAGE_KEY_USER_TOKEN);
const setToken = async (token: string) => Storage.setItem(ASYNC_STORAGE_KEY_USER_TOKEN, token);
const removeToken = async () => Storage.removeItem(ASYNC_STORAGE_KEY_USER_TOKEN);

export class BackstageUserDataClient extends BackstageApiBase implements UserDataClient {
    constructor(opts: BaseApiParams) {
        super(opts);
        autoBind(this);
        this.setToken = opts.setToken || setToken;
        this.removeToken = opts.removeToken || removeToken;
        this.getToken = opts.getToken || getToken;
    }

    /**
     * Register a new user.
     */
    async register(requestParams: RegisterRequest): Promise<void> {
        await this.request({
            method: 'POST',
            path: '/user/register',
            body: requestParams,
        });
    }

    /**
     * Login user and returns permanent authorization token, which should be used in the next requests
     * in header Authorization. To logout just dissmis token. If profileId is provided
     * then the user is created with that profile, otherwise a default profile is selected.
     */
    async login(
        username: LoginRequest['username'],
        password: LoginRequest['pasword'],
        profileId?: LoginRequest['profileId']
    ): Promise<Token> {
        const tokenData = await this.request({
            method: 'POST',
            path: '/user/login',
            body: {
                username,
                password,
                profileId,
            },
            guard: LoginResponseGuard,
        });

        if (tokenData) {
            await this.setToken?.(tokenData.token);
        }

        return tokenData;
    }

    async logout(): Promise<void> {
        await this.removeToken();
    }

    /**
     * Get logged in user information. This data can vary slightly across connected providers.
     */
    async fetchUser(): Promise<User | null> {
        const token = await this.getToken?.();
        if (!token) return null;

        return this.request({
            method: 'GET',
            path: '/user',
            guard: FetchUserResponseGuard,
        }).then(mapUser);
    }

    /**
     * Updates user information. Support for this endpoint depends on the connected identity provider.
     */
    async updateUser(userData: UpdateUserRequest): Promise<void> {
        return this.request({
            method: 'PUT',
            path: '/user',
            body: userData,
        });
    }

    /**
     * Remove logged in user account. Support for this endpoint depends on the connected identity provider.
     */
    async deleteUser(): Promise<void> {
        return this.request({
            method: 'DELETE',
            path: '/user',
        });
    }

    /**
     * Get all profiles for user.
     */
    async fetchUserProfiles(): Promise<Profile[]> {
        const token = await this.getToken?.();
        if (!token) return [];

        return this.request({
            method: 'GET',
            path: '/user/profiles',
            guard: FetchProfilesResponseGuard,
        }).then(mapProfilesResponse);
    }

    /**
     * Create a new profile
     */
    async createProfile(
        name: CreateProfileRequest['name'],
        imageId: CreateProfileRequest['imageId'],
        age: CreateProfileRequest['age'],
        isPinProtected: CreateProfileRequest['isPinProtected']
    ): Promise<Profile> {
        return this.request({
            method: 'POST',
            path: '/user/profiles',
            body: {
                name,
                imageId,
                age,
                isPinRequired: isPinProtected,
                pin: getPinIfExists().pinCode,
            },
            guard: CreateProfileResponseGuard,
        }).then(mapProfileResponse);
    }

    /**
     * Get a specific profile for user.
     */
    async fetchProfile(profileId: FetchProfileRequest['profileId']): Promise<Profile> {
        return this.request({
            method: 'GET',
            path: `/user/profiles/${profileId}`,
            guard: FetchProfileResponseGuard,
        }).then(mapProfileResponse);
    }

    /**
     * Updates profile with ID.
     */
    async updateProfile(
        profileId: string,
        { name, imageId, age, defaultProfile, isPinProtected }: UpdateProfileRequest
    ): Promise<Profile> {
        return this.request({
            method: 'PUT',
            path: `/user/profiles/${profileId}`,
            body: {
                name,
                age,
                imageId,
                defaultProfile,
                isPinRequired: isPinProtected,
                pin: getPinIfExists().pinCode,
            },
            guard: UpdateProfileResponseGuard,
        }).then(mapProfileResponse);
    }

    /**
     * Delete user profile.
     */
    async deleteProfile(profileId: DeleteProfileRequest['profileId']): Promise<void> {
        return this.request({
            method: 'DELETE',
            path: `/user/profiles/${profileId}`,
        });
    }

    async canDeleteProfile(
        profileId: string,
        { profiles }: CanDeleteProfileContext
    ): Promise<CanDeleteProfileResponse> {
        let cause: string | null = null;
        const stepsToResolve: DeleteProfileResolutionStep[] = [];
        const profileToDelete = profiles.find((userProfile) => userProfile.id === profileId);
        const selectedProfile = profiles.find((profile) => profile.selected);
        // If there is no profile that is marked as "default", we fallback to the first profile that is not the profile we don't want to delete
        // We need some default profile to exist, this fallback will work just fine
        const fallbackProfile = profiles.find((profile) => profile.id !== profileId);
        const currentDefaultProfile =
            profiles.find((profile) => profile.defaultProfile) ?? fallbackProfile;

        if (!currentDefaultProfile) {
            throw new Error(
                `The default profile could not be determined, even when using a simple fallback. This probably means that there is only one profile left!`
            );
        }

        if (!profileToDelete) {
            throw new Error(
                `Could not find profile data for the profile: ${profileId}. Have you provided the latest profile data?`
            );
        }

        const tryingToDeleteDefaultProfile = currentDefaultProfile.id === profileToDelete.id;
        const newDefault = tryingToDeleteDefaultProfile
            ? this.pickNewDefaultProfile(profiles, { selectedProfile })
            : currentDefaultProfile;

        if (tryingToDeleteDefaultProfile) {
            stepsToResolve.push({ action: 'SELECT_NEW_DEFAULT', profile: newDefault });
            cause = 'Trying to delete the default profile';
        }

        const tryingToDeleteCurrentlySelectedProfile = profileToDelete.id === selectedProfile?.id;
        if (tryingToDeleteCurrentlySelectedProfile) {
            stepsToResolve.push({ action: 'SWITCH_TO_PROFILE', profile: newDefault });
            cause = cause?.length
                ? `${cause} and also trying to delete currently selected profile`
                : `Trying to delete currently selected profile`;
        }

        if (stepsToResolve.length) {
            return {
                canDelete: false,
                resolutionSteps: stepsToResolve,
                cause: new Error(cause ?? 'Unknown cause'),
            };
        }

        return {
            canDelete: true,
        };
    }

    async getDecodedCleengToken(): Promise<CleengTokenData | null> {
        const backstageToken = await this.getToken?.();
        if (backstageToken) {
            return extractCleengDataFromBackstageToken(backstageToken);
        }
        return null;
    }

    async refetchBackstageToken() {
        const currentToken = await this.getToken?.();

        if (!currentToken) return null;

        const refreshedTokenData = await this.request({
            method: 'POST',
            baseUri: 'https://backstage-api.com',
            path: '/user/refresh-token',
            token: currentToken,
            guard: LoginResponseGuard,
        });

        if (refreshedTokenData.token) {
            await setToken(refreshedTokenData.token);
            return refreshedTokenData;
        }

        return null;
    }

    private pickNewDefaultProfile = (
        profiles: Profile[],
        context: { selectedProfile: Profile | undefined }
    ) => {
        const isDefaultProfileSelected = Boolean(context.selectedProfile?.defaultProfile);

        const canUseSelectedAsNewDefault =
            Boolean(context.selectedProfile) && !isDefaultProfileSelected;
        const newDefaultProfile = canUseSelectedAsNewDefault
            ? context.selectedProfile
            : profiles?.find((profile) => !profile.defaultProfile);

        if (!newDefaultProfile) {
            throw new Error(`Could not pick new default profile! No more profiles to choose from!`);
        }

        return newDefaultProfile;
    };

    /**
     * Change users email.
     */
    async updateUserEmail(email: UpdateUserEmailRequest['email']): Promise<void> {
        return this.request({
            method: 'PATCH',
            path: '/user/email',
            body: {
                email,
            },
        });
    }

    /**
     * Recovers users password/access to the account
     */
    async recoverPassword(email: RecoverPasswordRequest['email']): Promise<void> {
        return this.request({
            method: 'POST',
            path: '/user/recover',
            body: {
                email,
            },
        });
    }

    /**
     * Update user's password using password reset token
     */
    async updatePassword(
        email: UpdatePasswordRequest['email'],
        resetPasswordToken: UpdatePasswordRequest['resetPasswordToken'],
        newPassword: UpdatePasswordRequest['newPassword']
    ): Promise<void> {
        return this.request({
            method: 'PUT',
            path: '/user/password/token',
            body: {
                email,
                resetPasswordToken,
                newPassword,
            },
        });
    }

    /**
     * Update currently active profile. Assuming the profile matching the provided ID belongs to the logged in user,
     * the active profile will be changed.
     */
    async selectUserProfile({ profileId }: SelectUserProfileRequest): Promise<Token> {
        const tokenData = await this.request({
            method: 'PUT',
            path: `/user/profiles/select/${profileId}`,
            guard: SelectUserProfileResponseGuard,
            body: {
                ...getPinIfExists(),
            },
        });

        this.setToken?.(tokenData.token);

        return tokenData;
    }

    /**
     * Get all available profile images.
     */
    async fetchProfileImages(): Promise<ProfileImage[]> {
        return this.request({
            method: 'GET',
            path: '/profile-images',
            guard: FetchProfileImagesResponseGuard,
        });
    }

    /**
     * Get list of favorites.
     */
    async fetchFavorites(): Promise<Favorite[]> {
        return this.request({
            method: 'GET',
            path: `/user/favorites`,
            guard: FetchFavoritesResponseGuard,
        });
    }

    /**
     * Add favorite.
     */
    async addToFavorites(
        entityType: AddToFavoriesRequest['entityType'],
        entityId: AddToFavoriesRequest['entityId']
    ): Promise<Favorite | false> {
        try {
            return await this.request({
                method: 'POST',
                path: '/user/favorites',
                body: {
                    entityType,
                    entityId,
                },
                guard: AddToFavoriteResponseGuard,
            });
        } catch (err: any) {
            // if type of an error is ITEM_NOT_FOUND just return false and if not throw an actual error
            if (err.error === USER_ERROR.FAVORITE_ALREADY_EXISTS) {
                return false;
            }

            throw err;
        }
    }

    /**
     * Delete favorite.
     */
    async deleteFromFavorites(id: DeleteFromFavoritesRequest['id']): Promise<void | false> {
        try {
            await this.request({
                method: 'DELETE',
                path: `/user/favorites/${id}`,
            });

            return;
        } catch (err: any) {
            if (err.error === USER_ERROR.ITEM_NOT_FOUND) {
                // eslint-disable-next-line consistent-return
                return false;
            }

            throw err;
        }
    }

    /**
     * Store event record.
     */
    async events(
        assetId: EventsRequest['assetId'],
        assetType: EventsRequest['assetType'],
        action: EventsRequest['action'],
        offset: EventsRequest['offset'] = 0
    ): Promise<Event> {
        return this.request({
            method: 'POST',
            path: `/user/events`,
            guard: EventGuard,
            body: {
                assetId,
                assetType,
                action,
                offset,
            },
        });
    }

    /**
     * Validate profile pin.
     */
    async validatePin(validatedPin: ValidatePinRequest['validatedPin']): Promise<ValidPin> {
        const response = await this.request({
            method: 'POST',
            path: '/user/pin/validation',
            guard: ValidPinGuard,
            body: {
                pinCode: validatedPin,
            },
        });
        if (validatedPin && response.isPinCodeValid) {
            setPin(validatedPin);
        }

        return response as ValidPin;
    }

    /**
     * Get User(Profile) Rating for Specific Asset
     * @param asset Asset to get rating
     * @param ratingSystem Rating System that the app is using, defined in the app's config file
     * @returns Rating object form request or undefined
     */
    async getAssetRatingForUser(
        asset: Asset,
        ratingSystem: RATING_SYSTEM = RATING_SYSTEM.FIVESTARS
    ): Promise<Rating | undefined> {
        return this.request({
            method: 'GET',
            path: '/user/ratings',
            guard: GetAssetRatingForUserResponseGuard,
        }).then((response) => mapRatingResponseToRating(response.items, asset, ratingSystem));
    }

    /**
     * Provide User Rating for asset
     * @param asset Asset to get rating
     * @param rating Rating given by user
     * @returns nothing
     */
    async rateAssetForUser(asset: Asset, rating: Rating): Promise<void> {
        return this.request({
            method: 'POST',
            path: '/user/ratings',
            body: {
                assetId: asset.id,
                assetType: asset.type,
                rating: toBSRating(rating),
            },
        });
    }

    /**
     * Delete User Rating for Specific Asset
     * For now there is no DELETE API, so rating will be updated to 0
     * @param asset Asset to get rating
     * @param profileId Identifier for current active profile
     * @returns nothing
     */
    async deleteAssetRatingForUser(asset: Asset): Promise<void> {
        return this.request({
            method: 'POST',
            path: '/user/ratings',
            body: {
                assetId: asset.id,
                assetType: asset.type,
                rating: 0,
            },
        });
    }

    /**
     * Update user's PIN.
     */
    async updateAccountPin(pin: string): Promise<void> {
        await this.request({
            method: 'POST',
            path: '/user/pin',
            body: {
                pinCode: pin,
            },
        });

        setPin(pin);
    }

    /**
     * Get oAuth authorization URL
     */
    async getOAuthUrl(): Promise<{ redirectUrl: string }> {
        return this.request({
            method: 'GET',
            path: '/user/oauth/authorize',
            guard: GetOAuthUrlResponseGuard,
        });
    }

    /**
     * Get oAuth token for user by code
     */
    async getUserOauthToken(code: string): Promise<Token> {
        return this.request({
            method: 'GET',
            path: `/user/oauth/token?code=${code}`,
            guard: GetUserOauthTokenResponseGuard,
        });
    }
}

export const createBackstageUserDataClient = (params: BaseApiParams) => {
    return new BackstageUserDataClient(params);
};
