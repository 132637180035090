import { useEffect, useMemo, useState } from 'react';
import { isFactorMobile } from 'renative';
import {
    Asset,
    Broadcast,
    Favorite,
    isSeries,
    ASSET_TYPE,
    PRODUCT_SCREENS,
} from '@24i/nxg-sdk-photon/src';
import { ErrorCodes } from '@24i/nxg-sdk-smartott/src/utils/errorCodesMapper/types';
import { log } from '@24i/nxg-core-utils/src/logger';
import prepareAutoPlay from '@24i/nxg-sdk-smartott/src/utils/prepareAutoPlay';
import { useIsMutating } from 'react-query';
import { useRemindersDataClient } from '@24i/nxg-sdk-smartott-shared/src/context/Reminders';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useAssetActions } from '@24i/nxg-sdk-smartott';
import { showToast } from '@24i/nxg-sdk-gluons/src/components/ui/Toast';
import {
    useRecordingAssetQuery,
    useCreateRecordingForBroadcast,
    useDeleteRecordingForBroadcast,
    useCreateSeriesRecordingForBroadcast,
    useCancelRecordingsForSeries,
} from '@24i/nxg-sdk-smartott/src/hooks/query/recordings';
import {
    useAddToFavoritesMutation,
    useDeleteFromFavoritesMutation,
} from '@24i/nxg-sdk-smartott/src/hooks/query/favorites/useFavoritesMutation';
import useMyFavoritesQuery from '@24i/nxg-sdk-smartott/src/hooks/query/favorites/useMyFavoritesQuery';
import useContinueWatchingQuery from '@24i/nxg-sdk-smartott/src/hooks/query/continueWatching/useContinueWatchingQuery';
import {
    useAssetDetailsQuery,
    useAssetRelatedQuery,
    useAssetSeasonsQuery,
    useAssetExtrasQuery,
} from '@24i/nxg-sdk-smartott/src/hooks/query/asset';
import { useTrackBuffering } from '@24i/nxg-sdk-smartott-shared/src/analytics/hooks/useTrackBuffering';
import { BlockModalTypes } from '../../../components/BlockedModal/types';
import { useStore } from '../../../context/ApplicationStore';

import { mapAssetToTrailer, mapUserSeasons } from './utils';
import {
    DetailsScreenProps,
    DETAILS_ERROR_ENUM,
    UseSharedModelDetails,
    ErrorToDiplay,
} from '../types';
import useAssetBlockersValidation from '../../../hooks/useAssetBlockersValidation';
import useBlockedModal from '../../../components/BlockedModal/hooks';

const useShared = (props: DetailsScreenProps): UseSharedModelDetails => {
    const {
        query: assetDetailsFromQuery = { id: '', type: undefined, channelId: '', sectionLabel: '' },
        recordError,
    } = props;

    const { id: assetId, type: assetType, channelId, sectionLabel } = assetDetailsFromQuery;

    const { startPlayback, getEpisodeToWatch } = useAssetActions();

    const { userData, selectedProfile } = useStore();
    const { data: continueWatchingPlaylist, refetch: refetchContinueWatching } =
        useContinueWatchingQuery();
    const { handleBlockersCheck } = useAssetBlockersValidation({});
    const { t } = useTranslation();
    const { openBlockedModal } = useBlockedModal();

    // Error handling

    const [recordingError, setRecordingError] = useState<string | undefined>(undefined);
    const [errorForModal, setErrorForModal] = useState<ErrorToDiplay | null>(null);

    const handleRecordingError = (error) => {
        const errorMessage = error instanceof Error ? error.message : t('error.A00.body');
        setRecordingError(errorMessage);
    };
    const handleDetailsError = (error, errorSection?: DETAILS_ERROR_ENUM) => {
        if (
            error.error === ErrorCodes.ITEM_NOT_FOUND ||
            error.message.includes(ErrorCodes.ITEM_NOT_FOUND)
        ) {
            setErrorForModal({ title: t('error.A01.title'), message: t('error.A01.body') });
        } else {
            setErrorForModal({ title: t('error.B00.title'), message: t('error.B00.body') });
        }
        log(`Error fetching asset details: ${errorSection}`, JSON.stringify(error));
        recordError?.(JSON.stringify(error));
    };

    // Query hooks

    const {
        data: asset,
        isLoading: isDetailsLoading,
        refetch: refetchDetails,
    } = useAssetDetailsQuery(
        { assetId, assetType, continueWatchingPlaylist, channelId, sectionLabel },
        {
            onError: (err) => handleDetailsError(err, DETAILS_ERROR_ENUM.DETAILS),
            staleTime: assetType === ASSET_TYPE.BROADCAST ? 0 : undefined,
        }
    );
    useTrackBuffering({
        asset: {
            ...asset,
            id: assetId,
        },
        screen: PRODUCT_SCREENS.DETAILS,
    });

    const { data: extras, isLoading: isExtrasLoading } = useAssetExtrasQuery(asset, {
        onError: (err) => handleDetailsError(err, DETAILS_ERROR_ENUM.EXTRAS),
        enabled: !_.isEmpty(asset) && assetType !== ASSET_TYPE.BROADCAST,
    });

    const { data: related, isLoading: isRelatedLoading } = useAssetRelatedQuery(
        { assetId, assetType },
        {
            onError: (err) => handleDetailsError(err, DETAILS_ERROR_ENUM.RELATED),
            enabled: !_.isEmpty(asset) && assetType !== ASSET_TYPE.BROADCAST,
        }
    );

    const { data: seasons, isLoading: isSeasonsLoading } = useAssetSeasonsQuery(assetId, {
        onError: (err) => handleDetailsError(err, DETAILS_ERROR_ENUM.SEASONS),
        enabled:
            (!_.isEmpty(asset) && assetType === ASSET_TYPE.SERIES) ||
            assetType === ASSET_TYPE.PODCAST_SERIES,
        select: (data) => mapUserSeasons(data, continueWatchingPlaylist, userData),
    });

    const mutationLoading = useIsMutating();
    const createRecordingForBroadcast = useCreateRecordingForBroadcast(handleRecordingError);
    const deleteRecordingForBroadcast = useDeleteRecordingForBroadcast({
        handleError: handleRecordingError,
        successToast: null,
    });
    const createSeriesRecordingForBroadcast =
        useCreateSeriesRecordingForBroadcast(handleRecordingError);
    const cancelRecordingsForSeries = useCancelRecordingsForSeries(handleRecordingError);
    const broadcastAsset = (asset || assetDetailsFromQuery) as Broadcast;
    const { data, isLoading: recordingAssetLoading } = useRecordingAssetQuery(broadcastAsset, {
        onError: (err) => handleRecordingError(err),
        enabled: !_.isEmpty(asset) && !!userData,
    });
    const {
        data: userFavorites,
        isFetching: favoritesFetching,
        isLoading: favoritesLoading,
        refetch: refetchUserFavorites,
    } = useMyFavoritesQuery({
        enabled: !_.isEmpty(asset),
    });
    const { mutate: deleteFromFavorites } = useDeleteFromFavoritesMutation();
    const { mutate: addToFavorites } = useAddToFavoritesMutation();

    const favoriteAsset = useMemo(
        () => userFavorites?.find((userFavorite: Favorite) => userFavorite.entityId === assetId),
        [userFavorites, assetId]
    );

    // States

    const [isAddedToMyList, setIsAddedToMyList] = useState(!!favoriteAsset);
    const [reminderIsSet, setReminderIsSet] = useState(false);
    const [fetchingReminder, setFetchingReminder] = useState(true);
    const [extraItems, setExtraItems] = useState<Asset[] | undefined>();
    const toggleIsAddedToMyList = () => setIsAddedToMyList((prevState) => !prevState);
    const { remindersDataClient } = useRemindersDataClient();

    // Constants

    const isFavoritesLoading = favoritesFetching || favoritesLoading;

    const isLoading = isDetailsLoading || isExtrasLoading || isRelatedLoading || isSeasonsLoading;

    // Functions

    const onMyListPress = async (): Promise<void> => {
        try {
            if (userData) {
                if (!isFavoritesLoading) {
                    if (favoriteAsset) {
                        deleteFromFavorites(favoriteAsset.id);
                        setIsAddedToMyList(false);
                    } else if (assetType && assetId) {
                        addToFavorites({ entityType: assetType, entityId: assetId });
                        setIsAddedToMyList(true);
                    }
                }
            }
        } catch (err) {
            // If there was an error when adding or removing item, it toggles `My list` button value back
            toggleIsAddedToMyList();
            log('Error adding or removing item from user favorite list', err);
        }
    };

    const handlePurchaseAsset = (
        selectedAsset: Asset,
        openPurchaseModal: (asset: Asset) => void
    ) => {
        if (!userData) {
            openBlockedModal(BlockModalTypes.ACCESS);
            return;
        }

        let episode;
        if (selectedAsset.type === ASSET_TYPE.SERIES && !selectedAsset.isTrailer) {
            episode = getEpisodeToWatch(seasons || []);
        }

        openPurchaseModal(episode ?? selectedAsset);
    };

    const handleOnPlayPress = async ({
        streamAsset,
        isStartOver = false,
        onPurchaseAsset,
    }: {
        streamAsset: Asset;
        isStartOver?: boolean;
        onPurchaseAsset?: () => void;
    }) => {
        await prepareAutoPlay();

        const { hasBlocker } = await handleBlockersCheck({
            asset: streamAsset,
            handlePurchase: onPurchaseAsset,
        });

        if (hasBlocker) return;

        startPlayback(streamAsset, isStartOver);
    };

    const logReminderError = (error: unknown): void => {
        if (error instanceof Error) {
            log('Error setting reminder', error);
        }
        showToast(t('error.B00.title'));
    };

    const fetchReminder = async (): Promise<void> => {
        if (selectedProfile && assetId) {
            setFetchingReminder(true);
            const { id: profileId } = selectedProfile;
            try {
                const reminder = await remindersDataClient.getReminderForBroadcast(
                    assetId,
                    profileId
                );
                if (reminder) {
                    setReminderIsSet(true);
                } else {
                    setReminderIsSet(false);
                }
                setFetchingReminder(false);
            } catch (error: unknown) {
                logReminderError(error);
            }
        }
    };

    const refetchData = () => {
        refetchDetails();
        if (userData) refetchContinueWatching();
    };

    // Use Effects

    useEffect(() => {
        const mappedExtras = extras?.map((extraAsset) => mapAssetToTrailer(extraAsset, t));
        if (
            isSeries(asset) &&
            mappedExtras &&
            seasons &&
            seasons.length > 0 &&
            seasons[0].name === 'Extra'
        )
            setExtraItems([
                ...mappedExtras,
                // TODO: Temporary limit items to 3 because it causes performance issues on some mobile devices.
                // Figure out what would be a better solution (maybe `see all` feature)
                // P.S. this can be overridden via `renderPackshotGrid` prop if some projects wouldn't need to have this item limit
                ...seasons[0].episodes.filter((_i, index) => !isFactorMobile || index < 3),
            ]);
        else setExtraItems(mappedExtras);
    }, [extras, asset, seasons]);

    useEffect(() => {
        if (selectedProfile?.id) refetchUserFavorites();
    }, [selectedProfile]);

    useEffect(() => {
        if (!isFavoritesLoading) setIsAddedToMyList(!!favoriteAsset);
    }, [favoriteAsset, isFavoritesLoading]);

    useEffect(() => {
        refetchDetails();
    }, [continueWatchingPlaylist]);

    return {
        isAddedToMyList,
        reminderIsSet,
        fetchingReminder,
        isLoading,
        asset,
        seasons,
        related,
        extraItems,
        errorForModal,
        recordingState: {
            status: data?.status,
            error: recordingError,
            loading: recordingAssetLoading || Boolean(mutationLoading),
            seriesId: data?.seriesId,
        },
        refetchData,
        fetchReminder,
        onMyListPress,
        setReminderIsSet,
        logReminderError,
        handleOnPlayPress,
        onSetSingleRecordingPress: () => createRecordingForBroadcast(broadcastAsset),
        onRemoveSingleRecordingPress: () => deleteRecordingForBroadcast(broadcastAsset),
        onSetMultipleRecordingPress: () => createSeriesRecordingForBroadcast(broadcastAsset),
        onRemoveMultipleRecordingPress: () => cancelRecordingsForSeries(broadcastAsset),
        handlePurchaseAsset,
    };
};

export { useShared };
