/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/display-name */
/* eslint-disable import/no-cycle */
// Disabling the display name because:
// React thinks that we are defining components
// But we are actually using render functions
import { RouteToCheck, ScreenProps } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import TopBarPrimary from '@24i/nxg-sdk-gluons/src/components/navigation/TopBarPrimary';
import Loader from '@24i/nxg-sdk-gluons/src/components/ui/Loader';
import { ACCESS_MODE, PRODUCT_SCREENS } from '@24i/nxg-sdk-photon';
import { DeviceInfo } from '@24i/nxg-sdk-quantum';
import { MenuItem } from '@24i/nxg-sdk-photon/src/models/menuItem';
import React, { ComponentType } from 'react';
import { Dimensions } from 'react-native';
import { isFactorMobile, isPlatformWeb } from 'renative';
import { v4 as uuid } from 'uuid';
import AccountDetailsScreen from '../../screens/AccountDetails';
import AccountParentalControlsScreen from '../../screens/AccountParentalControls';
import AccountScreen from '../../screens/AccountScreen';
import AccountSubscriptionScreen from '../../screens/AccountSubscription';
import AdditionalInfoScreen from '../../screens/AdditionalInfoScreen';
import AddSubscriptionScreen from '../../screens/AddSubscriptionScreen';
import CreatePinScreen from '../../screens/CreatePinScreen';
import DeleteAccountScreen from '../../screens/DeleteAccountScreen';
import DeleteAccountSuccessScreen from '../../screens/DeleteAccountSuccessScreen';
import DetailsScreen from '../../screens/DetailsScreen';
import DynaRowScreen from '../../screens/DynaRowScreen';
import EnterPinScreen from '../../screens/EnterPinScreen';
import EpgScreen from '../../screens/EpgScreen';
import FallbackScreen from '../../screens/FallbackScreen';
import ForgotPasswordScreen from '../../screens/ForgotPasswordScreen';
import GenreScreen from '../../screens/GenreScreen';
import HomeScreen from '../../screens/HomeScreen';
import LandingScreen from '../../screens/LandingScreen';
import LanguageScreen from '../../screens/LanguageScreen';
import ManageDevicesScreen from '../../screens/ManageDevicesScreen';
import ManageProfilesScreen from '../../screens/ManageProfilesScreen';
import { ManageProfilesScreenModes } from '../../screens/ManageProfilesScreen/constants';
import MoreScreen from '../../screens/MoreScreen';
import MyContentScreen from '../../screens/MyContentScreen';
import MyListScreen from '../../screens/MyListScreen';
import MyPurchasesScreen from '../../screens/MyPurchasesScreen';
import MySubscriptionsScreen from '../../screens/MySubscriptionsScreen';
import OnboardingIntroScreen from '../../screens/OnboardingIntroScreen';
import PlaybackScreen from '../../screens/PlaybackScreen';
import PlaylistScreen from '../../screens/PlaylistScreen';
import RecentlyWatchedScreen from '../../screens/RecentlyWatchedScreen';
import RecordingsScreen from '../../screens/RecordingsScreen';
import SearchScreen from '../../screens/SearchScreen';
import SeeAllScreen from '../../screens/SeeAllScreen';
import SettingsScreen from '../../screens/SettingsScreen';
import SigninScreen from '../../screens/SigninScreen';
import SignoutScreen from '../../screens/SignoutScreen';
import SignupScreen from '../../screens/SignupScreen';
import SignUpSuccessScreen from '../../screens/SignUpSuccessScreen';
import StreamingScreen from '../../screens/StreamingScreen';
import { SubMenuScreen } from '../../screens/SubMenuScreen';
import TechnicalInfoScreen from '../../screens/TechnicalInfoScreen';
import TermsAndConditionsScreen from '../../screens/TermsAndConditionsScreen';
import TextPageScreen from '../../screens/TextPageScreen';
import ThemeScreen from '../../screens/ThemeScreen';
import UpsertProfileScreen from '../../screens/UpsertProfileScreen';
import WelcomeScreen from '../../screens/WelcomeScreen';
import MvpdSignInScreen from '../../screens/MvpdSignInScreen';
import { Breakpoint } from '../../utils/styles/constants';
import { OpenModalLink } from '../components/TopBarMenu/components/OpenModalLink/index.web';
import { SOTT_DEFAULT_SCREENS, SOTT_DEFAULT_WEB_SCREENS, SOTT_HOME_ROUTE } from '../constants';
import { WebScreenLink } from '../types';
import { DISABLE_SCREEN_RESULT, isDisableResult } from './config-builders-lib';
import {
    MenuItemWithSubScreens,
    PlacementNative,
    PlacementWeb,
    ScreenConfigBuilder,
    ScreenConfigBuilderResult,
    SottNavScreenConfsExtended,
    SottScreenConfigBuilders,
} from './types';
import PurchaseSubscriptionScreen from '../../screens/PurchaseSubscriptionScreen';
import PaymentMethodScreen from '../../screens/PaymentMethodScreen';
import PaymentHistoryScreen from '../../screens/PaymentHistoryScreen';
import PaymentResultScreen from '../../screens/PaymentResultScreen';
import SubscriptionsListScreen from '../../screens/SubscriptionsListScreen';
import SecondScreenActivation from '../../screens/SecondScreenActivationScreen';

export type SottStaticScreenDefs = typeof SOTT_DEFAULT_SCREEN_CONFIG_BUILDERS;

const header = (headerProps) => <TopBarPrimary {...headerProps} />;
const headerWithBack = (headerProps) => <TopBarPrimary {...headerProps} previous />;
const transparentHeader = (headerProps) => <TopBarPrimary {...headerProps} startsTransparent />;
const leftTitleHeader = (headerProps) => <TopBarPrimary {...headerProps} alignLeftTitle />;

export const matchAllSubPages =
    <TWebScreenLink extends WebScreenLink<any>>(
        rootLink: TWebScreenLink,
        screenTypeToUse?: string
    ) =>
    (routeToCheck: RouteToCheck) =>
        routeToCheck.asPath.startsWith(rootLink.as?.toString() ?? rootLink.href) ||
        (routeToCheck.asPath === SOTT_HOME_ROUTE && screenTypeToUse === PRODUCT_SCREENS.HOME);

export const matchSlug =
    <TParams extends Partial<Record<keyof TParams, unknown>> | void = void>(
        webLink: WebScreenLink<TParams>
    ) =>
    (routeToCheck: RouteToCheck) =>
        routeToCheck.href.startsWith(webLink.href);

export const matchExact =
    <TParams extends Partial<Record<keyof TParams, unknown>> | void = void>(
        webLink: WebScreenLink<TParams>
    ) =>
    (routeToCheck: RouteToCheck) =>
        routeToCheck.asPath.startsWith(webLink.as as string);

const BlankComponent = () => <></>;

export const getStaticValuesIfNotParametrized = (context: {
    parameters: MenuItem | null;
    staticValues: Pick<MenuItem, 'title' | 'name'> &
        Pick<SottNavScreenConfsExtended, 'correspondingProductScreen'>;
}): Pick<SottNavScreenConfsExtended, 'name' | 'menuTitle' | 'correspondingProductScreen'> =>
    context.parameters
        ? {
              name: context.parameters.name,
              menuTitle: context.parameters.title,
              correspondingProductScreen: context.parameters.screenTypeToUse,
          }
        : {
              name: context.staticValues.name,
              menuTitle: context.staticValues.title,
              correspondingProductScreen: context.staticValues.correspondingProductScreen,
          };
export const STANDARD_WEB_MENU_PLACEMENT = [PlacementWeb.TOP_BAR_LEFT, PlacementWeb.BOTTOM_BAR];
export const STANDARD_NATIVE_MENU_PLACEMENT = [PlacementNative.MAIN_MENU];

export const getStandardWebMenuPlacement = (
    menuItem: MenuItemWithSubScreens | null
): PlacementWeb[] => {
    if (menuItem?.preferredWebMenuPlacement === 'profile_dropdown') {
        return [PlacementWeb.PROFILE_DROPDOWN, PlacementWeb.BOTTOM_BAR];
    }
    if (menuItem?.preferredWebMenuPlacement === 'footer') {
        return [PlacementWeb.FOOTER];
    }
    return STANDARD_WEB_MENU_PLACEMENT;
};

export const buildNotFoundScreen: ScreenConfigBuilder = (parameters): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.NotFound.getLink();
    if (parameters) {
        throw new Error(
            `The not found screen cannot be built using parameters! The only valid build is the static version of the screen!`
        );
    }
    return {
        name: SOTT_DEFAULT_WEB_SCREENS.NotFound.uniqueWebId || '',
        menuTitle: 'Not found',
        // Web only screen
        nativeRenderOptions: {
            renderComponent: () => null,
        },
        webMenuLink,
        matchesWebRoute: matchSlug(webMenuLink),
        /** This screen is web only and only serves as a options holder, no native screens created from this, because of its placement! */
        placementWeb: {},
        placementNative: {},
        accessMode: ACCESS_MODE.ALL,
    };
};

const buildSearchScreen: ScreenConfigBuilder = (parameters, { t }): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.Search.getLink();
    const propsWithStaticFallback = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.SEARCH,
            title: t('search.title'),
            correspondingProductScreen: PRODUCT_SCREENS.SEARCH,
        },
    });
    return {
        ...parameters,
        ...propsWithStaticFallback,
        menuTitle: isPlatformWeb ? '' : t('search.title'),
        options: { orientation: !DeviceInfo.isTablet() ? 'portrait' : 'default' },
        nativeRenderOptions: {
            Component: SearchScreen.Main,
        },
        renderWebLink: ({ menuItem }) => (
            <OpenModalLink
                key={menuItem.menuTitle}
                menuItem={menuItem}
                onModalClose={() => window.history.back()}
                modalOverlayClassName="ReactModal__Overlay__Search"
                nameModal="search"
                ModalComponent={SearchScreen.Main as ComponentType<unknown>}
            />
        ),
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.Search.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        placementWeb: {
            menu: [PlacementWeb.TOP_BAR_RIGHT, PlacementWeb.BOTTOM_BAR],
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.OTHER_SCREENS],
        },
        accessMode: ACCESS_MODE.GUEST,
    };
};

const buildEpgScreen: ScreenConfigBuilder = (parameters, { t }): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.TvGuide.getLink();
    const propsWithStaticFallback = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.TV_GUIDE,
            title: t('epg.title'),
            correspondingProductScreen: PRODUCT_SCREENS.EPG,
        },
    });
    return {
        ...parameters,
        ...propsWithStaticFallback,
        nativeRenderOptions: {
            Component: EpgScreen.Main,
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.TvGuide.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            headerShown: false,
            orientation: 'default',
        },
        optionsWeb: {
            showFooter: false,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.OTHER_SCREENS],
        },
        accessMode: ACCESS_MODE.GUEST,
        initialParams: {
            enableCastButton: true,
        },
    };
};

const buildSettingsScreen: ScreenConfigBuilder = (parameters, { t }): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.Settings.getLink();
    const propsWithStaticFallback = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.SETTINGS,
            title: t('settings.title'),
            correspondingProductScreen: PRODUCT_SCREENS.SETTINGS,
        },
    });

    return {
        ...parameters,
        ...propsWithStaticFallback,
        icon: 'settings',
        nativeRenderOptions: {
            Component: SettingsScreen.Main,
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.Settings.getLink,
        matchesWebRoute: matchExact(webMenuLink),
        initialParams: {
            title: parameters?.title || t('settings.title'),
        },
        options: { headerShown: false },
        optionsWeb: {
            headerShown: true,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
            static: [],
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.OTHER_SCREENS],
        },
        accessMode: ACCESS_MODE.GUEST,
    };
};

const buildExternalScreen: ScreenConfigBuilder = (parameters): ScreenConfigBuilderResult => {
    if (!parameters) {
        // The screen cannot be built without parameters
        return DISABLE_SCREEN_RESULT;
    }

    const href = parameters?.reference || parameters?.name;
    if (!href) {
        throw new Error(
            `Could not construct external page as some required properties were not provided via the parameters.`
        );
    }
    return {
        ...parameters,
        menuTitle: parameters.title,
        nativeRenderOptions: {
            // it seems like a cleaner solution to pass a React node and not use it than to make any
            // of these properties optional
            renderComponent: BlankComponent,
        },
        webMenuLink: { href },
        matchesWebRoute: (routeToCheck) => routeToCheck.href === href,
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
        },
        accessMode: ACCESS_MODE.GUEST,
    };
};

const buildDynarowScreen: ScreenConfigBuilder = (parameters): ScreenConfigBuilderResult => {
    if (!parameters) {
        // The screen cannot be built without parameters
        return DISABLE_SCREEN_RESULT;
    }

    const name = parameters.name || uuid();
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.Dynarow.getLink({ slug: name });
    return {
        ...parameters,
        menuTitle: parameters.title,
        name,
        nativeRenderOptions: {
            Component: DynaRowScreen.Main,
        },
        // The SOTT Dynarow has to handle multiple pages
        // That is why we are passing the "name" as a route parameter
        // This way the screen can tell what page it should render
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.Dynarow.getLink,
        matchesWebRoute: matchExact(webMenuLink),
        options: {
            title: parameters.title,
        },
        initialParams: {
            title: parameters.title,
            reference: parameters.reference,
            enableCastButton: true,
        },
        placementWeb: { menu: getStandardWebMenuPlacement(parameters) },
        placementNative: { menu: STANDARD_NATIVE_MENU_PLACEMENT },
        correspondingProductScreen: parameters.screenTypeToUse ?? PRODUCT_SCREENS.DYNAMIC_CONTENT,
        accessMode: ACCESS_MODE.GUEST,
    };
};

const buildPageScreen: ScreenConfigBuilder = (
    parameters,
    { params }
): ScreenConfigBuilderResult => {
    if (parameters) {
        throw new Error(`Playback screen cannot construct a parametrized screen!`);
    }
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.Page.getLink({ id: params.id as string });

    return {
        title: SOTT_DEFAULT_SCREENS.PAGE,
        menuTitle: SOTT_DEFAULT_SCREENS.PAGE,
        name: SOTT_DEFAULT_SCREENS.PAGE,
        nativeRenderOptions: {
            Component: DynaRowScreen.Main,
        },
        // The SOTT Dynarow has to handle multiple pages
        // That is why we are passing the "name" as a route parameter
        // This way the screen can tell what page it should render
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.Page.getLink,
        matchesWebRoute: matchExact(webMenuLink),
        options: {
            title: ' ',
        },
        initialParams: {
            reference: params.id,
        },
        placementWeb: {},
        placementNative: { static: [PlacementNative.SUB_SCREENS, PlacementNative.OTHER_SCREENS] },
        correspondingProductScreen: PRODUCT_SCREENS.DYNAMIC_CONTENT,
        accessMode: ACCESS_MODE.GUEST,
    };
};

const buildPlaybackScreen: ScreenConfigBuilder = (
    parameters,
    { params }
): ScreenConfigBuilderResult => {
    if (parameters) {
        throw new Error(`Playback screen cannot construct a parametrized screen!`);
    }

    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.Playback.getLink({
        type: params.type as string,
        id: params.id as string,
    });

    return {
        name: SOTT_DEFAULT_SCREENS.PLAYBACK,
        menuTitle: 'Playback',
        correspondingProductScreen: PRODUCT_SCREENS.PLAYBACK,
        nativeRenderOptions: {
            Component: PlaybackScreen.Main,
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.Playback.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            title: SOTT_DEFAULT_SCREENS.PLAYBACK,
            headerShown: false,
            orientation: !DeviceInfo.isTablet() ? 'landscape_right' : 'default',
            gestureEnabled: false,
            navigationBarHidden: true,
        },
        optionsWeb: {
            showNavBarMenu: false,
            showFooter: false,
        },
        placementWeb: {},
        placementNative: {
            static: [PlacementNative.OTHER_SCREENS],
        },
        accessMode: ACCESS_MODE.ALL,
        initialParams: {
            enableCastButton: true,
        },
    };
};

const buildDetailsScreen: ScreenConfigBuilder = (
    parameters,
    { params }
): ScreenConfigBuilderResult => {
    if (parameters) {
        throw new Error(`The details screen cannot be build with parameters!`);
    }
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.Details.getLink({
        type: params.itemType as string,
        id: params.itemId as string,
    });

    return {
        menuTitle: 'Details',
        name: SOTT_DEFAULT_SCREENS.DETAILS,
        correspondingProductScreen: PRODUCT_SCREENS.DETAILS,
        nativeRenderOptions: {
            Component: DetailsScreen.Main,
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.Details.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            title: SOTT_DEFAULT_SCREENS.DETAILS,
            headerTransparent: true,
            headerShown: isFactorMobile,
            header: (props) => transparentHeader({ ...props, alignLeftTitle: true }),
        },
        placementWeb: {},
        placementNative: { static: [PlacementNative.SUB_SCREENS, PlacementNative.OTHER_SCREENS] },
        accessMode: ACCESS_MODE.GUEST,
        initialParams: {
            enableCastButton: true,
        },
    };
};

const buildGenreScreen: ScreenConfigBuilder = (menuItem): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.Genre.getLink({ genre: '', id: '', type: '' });
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters: menuItem,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.GENRE,
            title: 'Genre',
            correspondingProductScreen: PRODUCT_SCREENS.GENRE,
        },
    });
    return {
        ...menuItem,
        ...propsWithStaticFallback,
        menuTitle,
        nativeRenderOptions: {
            Component: GenreScreen.Main,
        },
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.Genre.getLink,
        webMenuLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            title: menuTitle,
            header: leftTitleHeader,
            headerShown: isFactorMobile,
        },
        placementWeb: { menu: getStandardWebMenuPlacement(menuItem) },
        placementNative: {
            static: [PlacementNative.SUB_SCREENS],
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
        },
        accessMode: ACCESS_MODE.GUEST,
    };
};

const buildSeeAllScreen: ScreenConfigBuilder = (
    menuItem,
    { t, params }
): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.SeeAll.getLink({
        pageId: params.pageId as string,
        sectionId: params.sectionId as string,
    });
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters: menuItem,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.SEE_ALL,
            title: t('common.seeAll'),
            correspondingProductScreen: PRODUCT_SCREENS.SEE_ALL,
        },
    });
    return {
        ...menuItem,
        ...propsWithStaticFallback,
        menuTitle,
        nativeRenderOptions: {
            Component: SeeAllScreen.Main,
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.SeeAll.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            title: menuTitle,
            header: leftTitleHeader,
            headerShown: isFactorMobile,
        },
        placementWeb: { menu: getStandardWebMenuPlacement(menuItem) },
        placementNative: {
            static: [PlacementNative.SUB_SCREENS],
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
        },
        accessMode: ACCESS_MODE.GUEST,
    };
};

const buildManageProfilesScreen: ScreenConfigBuilder = (
    parameters,
    { t, user, features }
): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.ManageProfiles.getLink();
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.MANAGE_PROFILES,
            title: t('profiles.manage.title'),
            correspondingProductScreen: PRODUCT_SCREENS.MANAGE_PROFILES,
        },
    });
    const isVisible = user && features?.accounts?.select_profiles;
    return {
        ...parameters,
        ...propsWithStaticFallback,
        menuTitle,
        nativeRenderOptions: {
            Component: ManageProfilesScreen.Main,
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.ManageProfiles.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            title: menuTitle,
        },
        optionsWeb: {
            showFooter: false,
            showNavBarMenu: false,
        },
        initialParams: {
            mode: ManageProfilesScreenModes.EDIT_ONLY,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
            static: isVisible ? [PlacementWeb.PROFILE_DROPDOWN] : [],
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: isFactorMobile ? [PlacementNative.OTHER_SCREENS] : [PlacementNative.MAIN_MENU],
        },
        accessMode: ACCESS_MODE.USER,
    };
};

const buildWhoIsWatchingScreen: ScreenConfigBuilder = (parameters): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.WhoIsWatching.getLink();
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.WHO_IS_WATCHING,
            title: SOTT_DEFAULT_SCREENS.WHO_IS_WATCHING,
            correspondingProductScreen: PRODUCT_SCREENS.WHO_IS_WATCHING,
        },
    });
    return {
        ...parameters,
        ...propsWithStaticFallback,
        menuTitle,
        nativeRenderOptions: {
            Component: ManageProfilesScreen.Main,
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.WhoIsWatching.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            title: menuTitle,
        },
        optionsWeb: {
            showFooter: false,
            showNavBarMenu: false,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.OTHER_SCREENS],
        },
        accessMode: ACCESS_MODE.USER,
    };
};

export const buildMyListScreen: ScreenConfigBuilder = (
    parameters,
    { t }
): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.MyList.getLink();
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.MY_LIST,
            title: t('favorites.manage.title'),
            correspondingProductScreen: PRODUCT_SCREENS.MY_LIST,
        },
    });
    return {
        ...parameters,
        ...propsWithStaticFallback,
        icon: 'add',
        menuTitle,
        nativeRenderOptions: {
            Component: MyListScreen.Main,
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.MyList.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            title: menuTitle,
            header,
            headerShown: isFactorMobile,
        },
        optionsWeb: {
            headerShown: true,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.SUB_SCREENS],
        },
        accessMode: ACCESS_MODE.USER,
    };
};

export const buildLanguageScreen: ScreenConfigBuilder = (
    parameters,
    { t }
): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.Language.getLink();
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.LANGUAGE,
            title: t('settings.language.title'),
            correspondingProductScreen: PRODUCT_SCREENS.LANGUAGE,
        },
    });

    return {
        ...parameters,
        ...propsWithStaticFallback,
        menuTitle,
        nativeRenderOptions: {
            Component: LanguageScreen,
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.Language.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            title: menuTitle,
            header,
            headerShown: isFactorMobile,
        },
        optionsWeb: {
            headerShown: true,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.SUB_SCREENS],
        },
        accessMode: ACCESS_MODE.GUEST,
    };
};

export const buildTechnicalInfoScreen: ScreenConfigBuilder = (
    parameters,
    { t }
): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.TechnicalInfo.getLink();
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.TECHNICAL_INFO,
            title: t('settings.technicalInfo.title'),
            correspondingProductScreen: PRODUCT_SCREENS.TECHNICAL_INFO,
        },
    });

    return {
        ...parameters,
        ...propsWithStaticFallback,
        menuTitle,
        nativeRenderOptions: {
            Component: TechnicalInfoScreen,
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.TechnicalInfo.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            title: menuTitle,
            header,
            headerShown: isFactorMobile,
        },
        optionsWeb: {
            headerShown: true,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.SUB_SCREENS],
        },
        accessMode: ACCESS_MODE.GUEST,
    };
};

export const buildThemeScreen: ScreenConfigBuilder = (
    parameters,
    { t }
): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.Theme.getLink();
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.THEME,
            title: t('settings.theme.title'),
            correspondingProductScreen: PRODUCT_SCREENS.THEME,
        },
    });

    return {
        ...parameters,
        ...propsWithStaticFallback,
        menuTitle,
        nativeRenderOptions: {
            Component: ThemeScreen,
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.Theme.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            title: menuTitle,
            header,
            headerShown: isFactorMobile,
        },
        optionsWeb: {
            headerShown: true,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.SUB_SCREENS],
        },
        accessMode: ACCESS_MODE.GUEST,
    };
};

export const buildServiceScreen: ScreenConfigBuilder = (
    parameters,
    { t }
): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.Service.getLink();
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.SERVICE,
            title: t('settings.service.title'),
            correspondingProductScreen: PRODUCT_SCREENS.SERVICE,
        },
    });

    return {
        ...parameters,
        ...propsWithStaticFallback,
        menuTitle,
        nativeRenderOptions: {
            Component: FallbackScreen.Main, // TODO: once the service screen is implemented, replace the fallback page
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.Service.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            title: menuTitle,
            header,
            headerShown: isFactorMobile,
        },
        optionsWeb: {
            headerShown: true,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.SUB_SCREENS],
        },
        accessMode: ACCESS_MODE.ALL,
    };
};

export const buildStreamingScreen: ScreenConfigBuilder = (
    parameters,
    { t }
): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.Streaming.getLink();
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.STREAMING,
            title: t('settings.streaming.title'),
            correspondingProductScreen: PRODUCT_SCREENS.STREAMING,
        },
    });

    return {
        ...parameters,
        ...propsWithStaticFallback,
        menuTitle,
        nativeRenderOptions: {
            Component: StreamingScreen,
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.Streaming.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            title: menuTitle,
            header,
            headerShown: isFactorMobile,
        },
        optionsWeb: {
            headerShown: true,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.SUB_SCREENS],
        },
        accessMode: ACCESS_MODE.GUEST,
    };
};

export const buildRecordingsScreen: ScreenConfigBuilder = (
    parameters,
    { t }
): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.Recordings.getLink({
        status: parameters?.status || '',
        id: parameters?.fileId || '',
        containingMenuTitle: t('myContent.title'),
    });
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.RECORDINGS,
            title: t('recordings.manage.title'),
            correspondingProductScreen: PRODUCT_SCREENS.RECORDINGS,
        },
    });
    return {
        ...parameters,
        ...propsWithStaticFallback,
        icon: 'fiber-manual-record',
        menuTitle: parameters?.title || menuTitle,
        nativeRenderOptions: {
            Component: RecordingsScreen.Main,
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.Recordings.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            title: parameters?.title || menuTitle,
            header,
            headerShown: isFactorMobile,
        },
        optionsWeb: {
            headerShown: true,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.SUB_SCREENS],
        },
        accessMode: ACCESS_MODE.USER,
    };
};

export const buildRecentlyWatchedScreen: ScreenConfigBuilder = (
    parameters,
    { t }
): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.RecentlyWatched.getLink();
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.RECENTLY_WATCHED,
            title: t('watchHistory.title'),
            correspondingProductScreen: PRODUCT_SCREENS.RECENTLY_WATCHED,
        },
    });
    return {
        ...parameters,
        ...propsWithStaticFallback,
        icon: 'history',
        menuTitle,
        nativeRenderOptions: {
            Component: RecentlyWatchedScreen.Main,
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.RecentlyWatched.getLink,
        optionsWeb: {
            headerShown: true,
        },
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            title: menuTitle,
            header,
            headerShown: isFactorMobile,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.SUB_SCREENS],
        },
        accessMode: ACCESS_MODE.USER,
    };
};

const buildForgotPasswordScreen: ScreenConfigBuilder = (
    parameters,
    { t }
): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.ForgotPassword.getLink();
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.FORGOT_PASS,
            title: t('password.forgot.title'),
            correspondingProductScreen: PRODUCT_SCREENS.FORGOT_PASS,
        },
    });
    return {
        ...parameters,
        ...propsWithStaticFallback,
        menuTitle,
        nativeRenderOptions: {
            Component: ForgotPasswordScreen.Main,
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.ForgotPassword.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            title: menuTitle,
            headerShown: isFactorMobile,
            header,
        },
        optionsWeb: {
            showFooter: false,
            showNavBarMenu: false,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.OTHER_SCREENS, PlacementNative.SUB_SCREENS],
        },
        accessMode: ACCESS_MODE.ANONYMOUS_ONLY,
    };
};

const buildSignInScreen: ScreenConfigBuilder = (
    parameters,
    { t, user }
): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.SignIn.getLink();
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.SIGN_IN,
            title: t('auth.signIn.title'),
            correspondingProductScreen: PRODUCT_SCREENS.SIGN_IN,
        },
    });
    return {
        ...parameters,
        ...propsWithStaticFallback,
        icon: 'sign-in',
        menuTitle,
        webLinkType: 'button',
        nativeRenderOptions: {
            Component: SigninScreen.Main,
        },
        renderWebLink: ({ menuItem }) => (
            <OpenModalLink
                menuItem={menuItem}
                ModalComponent={() => {
                    throw new Error(
                        `Please override the web link component and add your overridden sign in screen as the modal component. The current implementation of the sign in screen is not complete!`
                    );
                }}
            />
        ),
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.SignIn.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            title: menuTitle,
            headerShown: isFactorMobile,
            header,
        },
        optionsWeb: {
            showFooter: false,
            showNavBarMenu: false,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
            static: !user ? [PlacementWeb.TOP_BAR_RIGHT] : [],
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.OTHER_SCREENS, PlacementNative.SUB_SCREENS],
        },
        accessMode: ACCESS_MODE.ANONYMOUS_ONLY,
    };
};

const buildEnterPinScreen: ScreenConfigBuilder = (): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.EnterPin.getLink();
    return {
        menuTitle: SOTT_DEFAULT_SCREENS.ENTER_PIN,
        name: SOTT_DEFAULT_SCREENS.ENTER_PIN,
        icon: '',
        nativeRenderOptions: {
            Component: EnterPinScreen,
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.EnterPin.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            headerShown: isFactorMobile,
        },
        optionsWeb: {
            showFooter: false,
            showNavBarMenu: false,
        },
        placementWeb: {
            menu: [],
            static: [],
        },
        placementNative: {
            menu: [],
            static: [PlacementNative.OTHER_SCREENS],
        },
        accessMode: ACCESS_MODE.USER,
    };
};

const buildSignUpScreen: ScreenConfigBuilder = (parameters, { t }): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.SignUp.getLink();
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.SIGN_UP,
            title: t('register.title'),
            correspondingProductScreen: PRODUCT_SCREENS.SIGN_UP,
        },
    });
    return {
        ...parameters,
        ...propsWithStaticFallback,
        icon: 'account',
        menuTitle: t('register.title'),
        nativeRenderOptions: {
            Component: SignupScreen.Main,
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.SignUp.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            title: menuTitle,
            headerShown: isFactorMobile,
            header,
        },
        optionsWeb: {
            showFooter: false,
            showNavBarMenu: true,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
            static: [],
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.OTHER_SCREENS, PlacementNative.SUB_SCREENS],
        },
        accessMode: ACCESS_MODE.ANONYMOUS_ONLY,
    };
};

const buildSignUpSuccessScreen: ScreenConfigBuilder = (): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.SignUpSuccess.getLink();
    return {
        menuTitle: SOTT_DEFAULT_SCREENS.SIGN_UP_SUCCESS,
        name: SOTT_DEFAULT_SCREENS.SIGN_UP_SUCCESS,
        icon: '',
        nativeRenderOptions: {
            Component: SignUpSuccessScreen,
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.SignUpSuccess.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            headerShown: false,
        },
        optionsWeb: {
            showFooter: false,
            showNavBarMenu: false,
        },
        placementWeb: {
            menu: [],
            static: [],
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.OTHER_SCREENS],
        },
        accessMode: ACCESS_MODE.ANONYMOUS_ONLY,
    };
};

const buildMoreScreen: ScreenConfigBuilder = (
    parameters,
    { t, features }
): ScreenConfigBuilderResult => {
    if (parameters) {
        throw new Error(`More screen is not supposed to be constructed with parameters!`);
    }
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.More.getLink();
    const showProfilePicker = features?.accounts?.select_profiles;
    const menuTitle = t('common.more');
    return {
        menuTitle,
        name: SOTT_DEFAULT_SCREENS.MORE,
        correspondingProductScreen: PRODUCT_SCREENS.MORE,
        icon: 'bars',
        nativeRenderOptions: {
            // TODO: Type more screen
            Component: MoreScreen.Main,
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.More.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            title: menuTitle,
        },
        optionsWeb: {
            showFooter: false,
        },
        initialParams: {
            showProfilePicker,
        },
        // Handled specially by the config builder
        // You can add other places here if you wish but this screen has some hardcoded logic
        // TODO: It might be possible to move the logic here...
        placementWeb: {},
        placementNative: {},
        accessMode: ACCESS_MODE.GUEST,
    };
};

const buildAccountScreen: ScreenConfigBuilder = (
    parameters,
    { t, params }
): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.Account.getLink({
        subScreen: params.subScreen as string,
    });
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.ACCOUNT,
            title: t('more.Account'),
            correspondingProductScreen: PRODUCT_SCREENS.ACCOUNT,
        },
    });
    return {
        ...parameters,
        ...propsWithStaticFallback,
        icon: 'account',
        menuTitle: t('account.title'),
        nativeRenderOptions: {
            Component: AccountScreen.Main,
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.Account.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            title: menuTitle,
        },
        optionsWeb: {
            headerShown: true,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.OTHER_SCREENS],
        },
        accessMode: ACCESS_MODE.USER,
    };
};

const buildMyContentScreen: ScreenConfigBuilder = (
    parameters,
    { t }
): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.MyContent.getLink();
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.MY_CONTENT,
            title: t('myContent.title'),
            correspondingProductScreen: PRODUCT_SCREENS.MY_CONTENT,
        },
    });

    return {
        ...parameters,
        ...propsWithStaticFallback,
        icon: 'heart',
        menuTitle,
        nativeRenderOptions: {
            Component: MyContentScreen.Main,
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.MyContent.getLink,
        // The other sub screens are handled as standalone screens, they have their own options
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            headerShown: false,
        },
        optionsWeb: {
            headerShown: true,
        },
        initialParams: {
            title: parameters?.title || t('myContent.title'),
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
            static: [],
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.OTHER_SCREENS],
        },
        accessMode: ACCESS_MODE.USER,
    };
};

const buildLandingScreen: ScreenConfigBuilder = (parameters): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.Landing.getLink();
    const shouldHideFooterOnLanding = Dimensions.get('screen').width <= Breakpoint.MD;
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.LANDING,
            title: 'Landing',
            correspondingProductScreen: PRODUCT_SCREENS.LANDING,
        },
    });
    return {
        ...parameters,
        ...propsWithStaticFallback,
        menuTitle,
        nativeRenderOptions: {
            Component: LandingScreen.Main as ComponentType<ScreenProps>,
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.Landing.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            title: menuTitle,
            headerShown: false,
        },
        optionsWeb: {
            showFooter: !shouldHideFooterOnLanding,
            showNavBarMenu: false,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.OTHER_SCREENS],
        },
        accessMode: ACCESS_MODE.ANONYMOUS_ONLY,
    };
};

export const buildMyPurchaseScreen: ScreenConfigBuilder = (
    parameters,
    { t }
): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.MyPurchases.getLink();
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.MY_PURCHASES,
            title: t('tvod.manage.title'),
            correspondingProductScreen: PRODUCT_SCREENS.MY_PURCHASES,
        },
    });
    return {
        ...parameters,
        ...propsWithStaticFallback,
        icon: 'shopping-cart',
        menuTitle,
        nativeRenderOptions: {
            Component: MyPurchasesScreen.Main,
        },
        matchesWebRoute: matchSlug(webMenuLink),
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.MyPurchases.getLink,
        webMenuLink,
        options: {
            title: menuTitle,
            header,
            headerShown: isFactorMobile,
        },
        optionsWeb: {
            headerShown: true,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.SUB_SCREENS],
        },
        accessMode: ACCESS_MODE.USER,
    };
};

const buildUpsertProfileScreen: ScreenConfigBuilder = (
    parameters,
    context
): ScreenConfigBuilderResult => {
    const { t, user, features, params } = context;

    const webMenuLinkProfileEdit = SOTT_DEFAULT_WEB_SCREENS.ProfileEdit.getLink({
        profileId: (params?.profileId as string) || '',
    });
    const webMenuLinkProfileCreate = SOTT_DEFAULT_WEB_SCREENS.CreateProfile.getLink();
    const { onboarding } = params;
    const isVisible = user && features?.accounts?.select_profiles;

    const propsWithStaticFallback = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.UPSERT_PROFILE,
            title: t('profiles.add.addButton'),
            correspondingProductScreen: PRODUCT_SCREENS.UPSERT_PROFILE,
        },
    });

    return {
        ...parameters,
        ...propsWithStaticFallback,
        icon: 'plus',
        nativeRenderOptions: {
            Component: UpsertProfileScreen.Main,
        },
        webMenuLink: params.profileId ? webMenuLinkProfileEdit : webMenuLinkProfileCreate,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.CreateProfile.getLink,
        matchesWebRoute: ({ href }) =>
            href.startsWith(webMenuLinkProfileEdit.href) ||
            href.startsWith(webMenuLinkProfileCreate.href),
        options: {
            headerShown: false,
        },
        optionsWeb: {
            showFooter: false,
            showNavBarMenu: !onboarding,
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.OTHER_SCREENS],
        },
        placementWeb: {
            menu: isVisible ? getStandardWebMenuPlacement(parameters) : [],
            static: isVisible ? [PlacementWeb.PROFILE_DROPDOWN] : [],
        },
        accessMode: ACCESS_MODE.USER,
    } as ScreenConfigBuilderResult;
};

export const buildSignOutScreen: ScreenConfigBuilder = (
    parameters,
    { t }
): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.SignOut.getLink();
    const propsWithStaticFallback = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.SIGN_OUT,
            title: t('auth.signOut.title'),
            correspondingProductScreen: PRODUCT_SCREENS.SIGN_OUT,
        },
    });
    return {
        ...parameters,
        ...propsWithStaticFallback,
        icon: 'exit-to-app',
        nativeRenderOptions: {
            Component: SignoutScreen.Main,
        },
        renderWebLink: ({ menuItem }) => (
            <OpenModalLink menuItem={menuItem} ModalComponent={SignoutScreen.Main} />
        ),
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.SignOut.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
            static: [],
        },
        placementNative: { static: [PlacementNative.OTHER_SCREENS] },
        accessMode: ACCESS_MODE.USER,
    };
};

/**
 * This screen isn't implemented by default by the SDK. It has to be implemented
 * by the user (The developer is supposed to override the screen builder).
 *
 * The purpose of it is to provide the ability to render custom screens for dynamic menu items
 */
const buildCustomMenuScreen: ScreenConfigBuilder = (parameters): ScreenConfigBuilderResult => {
    if (!parameters) {
        // The screen cannot be built without parameters
        return DISABLE_SCREEN_RESULT;
    }

    // This might as well return null but to satisfy constraints and not complicate
    // things it is returning config with empty component and empty placement so
    // it is not included anywhere
    return {
        ...parameters,
        menuTitle: parameters.title,
        name: uuid(),
        matchesWebRoute: () => false,
        nativeRenderOptions: {
            renderComponent: () => <></>,
        },
        placementWeb: {},
        placementNative: {},
        correspondingProductScreen: PRODUCT_SCREENS.CUSTOM,
        accessMode: ACCESS_MODE.GUEST,
    } as ScreenConfigBuilderResult;
};

const buildFallbackScreen: ScreenConfigBuilder = (parameters): ScreenConfigBuilderResult => {
    if (!parameters) {
        // The screen cannot be built without parameters
        return DISABLE_SCREEN_RESULT;
    }

    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.FallbackPage.getLink();
    const menuTitle = parameters.title;

    return {
        ...parameters,
        name: parameters.title,
        correspondingProductScreen: PRODUCT_SCREENS.FALLBACK,
        menuTitle,
        nativeRenderOptions: {
            Component: FallbackScreen.Main,
        },
        webMenuLink,
        optionsWeb: {
            headerShown: false,
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.OTHER_SCREENS],
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.FallbackPage.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        accessMode: ACCESS_MODE.USER,
    };
};

const buildWelcomeScreen: ScreenConfigBuilder = (menuItem): ScreenConfigBuilderResult => {
    const shouldHideFooterOnLanding = Dimensions.get('screen').width <= Breakpoint.MD;
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.Welcome.getLink();
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters: menuItem,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.WELCOME,
            title: 'Welcome',
            correspondingProductScreen: PRODUCT_SCREENS.WELCOME,
        },
    });
    return {
        ...menuItem,
        ...propsWithStaticFallback,
        menuTitle,
        nativeRenderOptions: {
            Component: WelcomeScreen.Main as ComponentType<ScreenProps>,
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.Welcome.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            title: menuTitle,
            headerShown: false,
        },
        optionsWeb: {
            showFooter: !shouldHideFooterOnLanding,
            showNavBarMenu: false,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(menuItem),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.OTHER_SCREENS],
        },
        accessMode: ACCESS_MODE.ANONYMOUS_ONLY,
    };
};

const buildAdditionalInfoScreen: ScreenConfigBuilder = (parameters): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.AdditionalInfo.getLink();
    const menuTitle = 'Additional info';

    return {
        ...parameters,
        name: SOTT_DEFAULT_SCREENS.ADDITIONAL_INFO,
        correspondingProductScreen: PRODUCT_SCREENS.ADDITIONAL_INFO,
        menuTitle,
        nativeRenderOptions: {
            Component: AdditionalInfoScreen.Main,
        },
        webMenuLink,
        options: {
            title: menuTitle,
            headerShown: false,
        },
        optionsWeb: {
            showFooter: false,
            showNavBarMenu: false,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.OTHER_SCREENS],
        },
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.AdditionalInfo.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        accessMode: ACCESS_MODE.GUEST,
    };
};
const buildOnboardingIntroScreen: ScreenConfigBuilder = (parameters): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.OnboardingIntro.getLink();
    const menuTitle = `Let's get started`;

    return {
        ...parameters,
        name: SOTT_DEFAULT_SCREENS.ONBOARDING_INTRO,
        correspondingProductScreen: PRODUCT_SCREENS.ONBOARDING_INTRO,
        menuTitle,
        nativeRenderOptions: {
            Component: OnboardingIntroScreen.Main,
        },
        webMenuLink,
        options: {
            title: menuTitle,
            headerShown: false,
        },
        optionsWeb: {
            showFooter: false,
            showNavBarMenu: false,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.OTHER_SCREENS],
        },
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.OnboardingIntro.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        accessMode: ACCESS_MODE.USER,
    };
};
const buildTermsAndConditionsScreen: ScreenConfigBuilder = (
    parameters
): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.TermsAndConditions.getLink();
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.TERMS_CONDITIONS,
            title: 'Terms and conditions',
            correspondingProductScreen: PRODUCT_SCREENS.TERMS_AND_CONDITIONS,
        },
    });
    return {
        ...parameters,
        ...propsWithStaticFallback,
        menuTitle,
        nativeRenderOptions: {
            Component: TermsAndConditionsScreen.Main,
        },
        webMenuLink,
        options: {
            title: menuTitle,
            headerShown: false,
        },
        optionsWeb: {
            showFooter: false,
            showNavBarMenu: false,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.OTHER_SCREENS],
        },
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.TermsAndConditions.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        accessMode: ACCESS_MODE.USER,
    };
};

const buildPlaylistScreen: ScreenConfigBuilder = (parameters): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.Playlist.getLink({ playlist: '' });

    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.PLAYLIST,
            title: '',
            correspondingProductScreen: PRODUCT_SCREENS.PLAYLIST,
        },
    });

    return {
        ...parameters,
        ...propsWithStaticFallback,
        menuTitle: SOTT_DEFAULT_SCREENS.PLAYLIST,
        name: SOTT_DEFAULT_SCREENS.PLAYLIST,
        nativeRenderOptions: {
            Component: PlaylistScreen.Main as ComponentType<ScreenProps>,
        },
        matchesWebRoute: matchSlug(webMenuLink),
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.Playlist.getLink,
        options: {
            title: menuTitle,
            header,
            headerShown: isFactorMobile,
        },
        optionsWeb: {
            showFooter: true,
            showNavBarMenu: true,
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.SUB_SCREENS],
        },
        placementWeb: { menu: getStandardWebMenuPlacement(parameters) },
        accessMode: ACCESS_MODE.GUEST,
    };
};

const buildSubMenuScreen: ScreenConfigBuilder = (parameters): ScreenConfigBuilderResult => {
    if (!parameters) {
        return DISABLE_SCREEN_RESULT;
    }

    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.SubMenu.getLink({
        slug: parameters.name as string,
    });

    return {
        ...parameters,
        menuTitle: parameters.title,
        nativeRenderOptions: {
            renderComponent: () => (
                <SubMenuScreen
                    header={header}
                    subMenuTitle={parameters.title}
                    subMenuScreens={parameters.embeddedScreens}
                />
            ),
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
            static: [],
        },
        placementNative: {
            menu: [PlacementNative.MAIN_MENU],
            static: [],
        },
        optionsWeb: {
            headerShown: true,
        },
        webMenuLink,
        matchesWebRoute: matchExact(webMenuLink),
        options: {
            // We need to hide the header for all sub menu screens
            // This way the parent navigator header is hidden and we can render our own,
            // in our nested navigator
            headerShown: false,
        },
        accessMode: ACCESS_MODE.GUEST,
    };
};

/** The home screen is handled a little bit differently that the other screens */
const buildHomeScreen: ScreenConfigBuilder = (parameters, context): ScreenConfigBuilderResult => {
    if (!parameters) {
        return DISABLE_SCREEN_RESULT;
    }

    const dynarowOptions = buildDynarowScreen(parameters, context);

    if (isDisableResult(dynarowOptions)) {
        return DISABLE_SCREEN_RESULT;
    }

    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.Home.getLink();

    return {
        ...dynarowOptions,
        nativeRenderOptions: {
            renderComponent: (props) => (
                <HomeScreen.Main reference={parameters.reference} {...props} />
            ),
        },
        webMenuLink,
        matchesWebRoute: matchExact(webMenuLink),
        placementNative: {
            ...dynarowOptions.placementNative,
            static: [PlacementNative.MAIN_MENU],
        },
        correspondingProductScreen: PRODUCT_SCREENS.HOME,
        accessMode: ACCESS_MODE.GUEST,
    } as ScreenConfigBuilderResult;
};

// This screen is added only because the index screen on web "/" and mobiles has to be present in the navigation config
const buildIndexScreen: ScreenConfigBuilder = (parameters): ScreenConfigBuilderResult => ({
    ...parameters,
    name: SOTT_DEFAULT_SCREENS.INDEX,
    menuTitle: '',
    nativeRenderOptions: {
        Component: Loader,
    },
    placementWeb: {},
    placementNative: { static: [PlacementNative.OTHER_SCREENS] },
    // We have to have a screen matching the "/" route, otherwise the application might throw and error
    webMenuLink: {
        href: '/',
    },
    matchesWebRoute: ({ href }) => href === '/',
    // Treat this as user only access page so rerouting is always triggered on the index either leading
    // to sign in or landing page depending on the settings.
    accessMode: ACCESS_MODE.USER,
});

const buildTextPageScreen: ScreenConfigBuilder = (
    parameters,
    { params }
): ScreenConfigBuilderResult => {
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.TEXT_PAGE,
            title: 'Text page',
            correspondingProductScreen: PRODUCT_SCREENS.TEXT_PAGE,
        },
    });

    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.TextPage.getLink({
        id: (parameters?.reference || params.id) as string,
    });

    return {
        ...parameters,
        ...propsWithStaticFallback,
        menuTitle,
        nativeRenderOptions: {
            Component: TextPageScreen.Main,
        },
        options: {
            title: parameters?.title,
            headerTransparent: true,
            headerShown: isFactorMobile,
            header: headerWithBack,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
            static: [],
        },
        placementNative: {
            menu: [PlacementNative.MAIN_MENU],
            static: [PlacementNative.SUB_SCREENS, PlacementNative.OTHER_SCREENS],
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.TextPage.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        initialParams: {
            reference: parameters?.reference || params.id,
        },
        accessMode: ACCESS_MODE.GUEST,
    };
};

export const buildManageDevicesScreen: ScreenConfigBuilder = (
    parameters,
    { t }
): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.ManageDevices.getLink();
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.MANAGE_DEVICES,
            title: t('manageDevices.title'),
            correspondingProductScreen: PRODUCT_SCREENS.MANAGE_DEVICES,
        },
    });
    return {
        ...parameters,
        ...propsWithStaticFallback,
        icon: 'devices',
        menuTitle,
        nativeRenderOptions: {
            Component: ManageDevicesScreen.Main,
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.ManageDevices.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            title: menuTitle,
            headerShown: false,
        },
        optionsWeb: {
            showFooter: true,
            showNavBarMenu: true,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.OTHER_SCREENS],
        },
        accessMode: ACCESS_MODE.USER,
    };
};

const buildRemoveDeviceScreen: ScreenConfigBuilder = (parameters): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.RemoveDevice.getLink();
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.REMOVE_DEVICE,
            title: SOTT_DEFAULT_SCREENS.REMOVE_DEVICE,
            correspondingProductScreen: PRODUCT_SCREENS.REMOVE_DEVICE,
        },
    });
    return {
        ...parameters,
        ...propsWithStaticFallback,
        menuTitle,
        nativeRenderOptions: {
            Component: ManageDevicesScreen.Main,
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.RemoveDevice.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            title: menuTitle,
            headerShown: false,
        },
        optionsWeb: {
            showFooter: false,
            showNavBarMenu: false,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.OTHER_SCREENS],
        },
        accessMode: ACCESS_MODE.USER,
    };
};

const buildResetPasswordScreen: ScreenConfigBuilder = (parameters): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.PasswordReset.getLink();
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.PASSWORD_RESET,
            title: SOTT_DEFAULT_SCREENS.PASSWORD_RESET,
        },
    });
    return {
        ...parameters,
        ...propsWithStaticFallback,
        menuTitle,
        nativeRenderOptions: {
            renderComponent: BlankComponent,
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.PasswordReset.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            title: menuTitle,
        },
        optionsWeb: {
            showFooter: false,
            showNavBarMenu: true,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.OTHER_SCREENS],
        },
        accessMode: ACCESS_MODE.ALL,
    };
};

const buildAddSubscriptionScreen: ScreenConfigBuilder = (parameters): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.AddSubscription.getLink();

    return {
        ...parameters,
        menuTitle: SOTT_DEFAULT_SCREENS.ADD_SUBSCRIPTION,
        name: SOTT_DEFAULT_SCREENS.ADD_SUBSCRIPTION,
        title: '',
        correspondingProductScreen: PRODUCT_SCREENS.ADD_SUBSCRIPTION,
        nativeRenderOptions: {
            Component: AddSubscriptionScreen.Main,
        },
        webMenuLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            headerShown: false,
        },
        optionsWeb: {
            showFooter: false,
            showNavBarMenu: false,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.OTHER_SCREENS],
        },
        accessMode: ACCESS_MODE.USER,
    };
};

const buildCreatePinScreen: ScreenConfigBuilder = (
    parameters,
    { t }
): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.CreatePin.getLink();
    return {
        ...parameters,
        menuTitle: SOTT_DEFAULT_SCREENS.CREATE_PIN,
        name: SOTT_DEFAULT_SCREENS.CREATE_PIN,
        title: t('pin.add.title'),
        correspondingProductScreen: PRODUCT_SCREENS.CREATE_PIN,
        nativeRenderOptions: {
            Component: CreatePinScreen.Main,
        },
        webMenuLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            title: t('pin.add.title'),
            headerShown: false,
        },
        optionsWeb: {
            showFooter: false,
            showNavBarMenu: false,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.OTHER_SCREENS],
        },
        accessMode: ACCESS_MODE.USER,
    };
};

export const buildAccountDetailsScreen: ScreenConfigBuilder = (
    parameters,
    { t }
): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.AccountDetails.getLink();
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.ACCOUNT_DETAILS,
            title: t('account.accountDetails.title'),
            correspondingProductScreen: PRODUCT_SCREENS.ACCOUNT_DETAILS,
        },
    });
    return {
        ...parameters,
        ...propsWithStaticFallback,
        icon: 'person',
        menuTitle,
        nativeRenderOptions: {
            Component: AccountDetailsScreen.Main,
        },

        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.AccountDetails.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            title: menuTitle,
            header,
            headerShown: true,
        },
        optionsWeb: {
            headerShown: true,
            showFooter: false,
            showNavBarMenu: true,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.SUB_SCREENS],
        },
        accessMode: ACCESS_MODE.USER,
    };
};

export const buildDeleteAccountScreen: ScreenConfigBuilder = (
    parameters,
    { t }
): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.DeleteAccount.getLink();
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.DELETE_ACCOUNT,
            title: t('account.delete.title'),
            correspondingProductScreen: PRODUCT_SCREENS.DELETE_ACCOUNT,
        },
    });
    return {
        ...parameters,
        ...propsWithStaticFallback,
        menuTitle,
        nativeRenderOptions: {
            Component: DeleteAccountScreen.Main,
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.DeleteAccount.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            title: menuTitle,
            header,
            headerShown: true,
        },
        optionsWeb: {
            headerShown: true,
            showNavBarMenu: true,
            showFooter: false,
        },
        placementWeb: {
            menu: [],
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.SUB_SCREENS],
        },
        accessMode: ACCESS_MODE.USER,
    };
};

export const buildDeleteAccountSuccessScreen: ScreenConfigBuilder = (
    parameters,
    { t }
): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.DeleteAccountSuccess.getLink();
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.DELETE_ACCOUNT_SUCCESS,
            title: t('account.delete.deleteSuccess.title'),
            correspondingProductScreen: PRODUCT_SCREENS.DELETE_ACCOUNT_SUCCESS,
        },
    });
    return {
        ...parameters,
        ...propsWithStaticFallback,
        menuTitle,
        nativeRenderOptions: {
            Component: DeleteAccountSuccessScreen.Main,
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.DeleteAccountSuccess.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            headerShown: false,
        },
        optionsWeb: {
            headerShown: false,
            showNavBarMenu: false,
            showFooter: false,
        },
        placementWeb: {
            menu: [],
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.SUB_SCREENS],
        },
        accessMode: ACCESS_MODE.ALL,
    };
};

export const buildAccountSubscriptionScreen: ScreenConfigBuilder = (
    parameters,
    { t }
): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.AccountSubscription.getLink();
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.ACCOUNT_SUBSCRIPTION,
            title: t('svod.add.title'),
            correspondingProductScreen: PRODUCT_SCREENS.ACCOUNT_SUBSCRIPTION,
        },
    });
    return {
        ...parameters,
        ...propsWithStaticFallback,
        icon: 'credit-card',
        menuTitle,
        nativeRenderOptions: {
            Component: AccountSubscriptionScreen.Main,
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.AccountSubscription.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            title: menuTitle,
            header,
            headerShown: true,
        },
        optionsWeb: {
            headerShown: true,
            showFooter: true,
            showNavBarMenu: false,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [],
        },
        accessMode: ACCESS_MODE.USER,
    };
};

export const buildAccountParentalControlsScreen: ScreenConfigBuilder = (
    parameters,
    { t }
): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.AccountParentalControls.getLink();
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.ACCOUNT_PARENTAL_CONTROLS,
            title: t('pin.manage.title'),
            correspondingProductScreen: PRODUCT_SCREENS.ACCOUNT_PARENTAL_CONTROLS,
        },
    });
    return {
        ...parameters,
        ...propsWithStaticFallback,
        icon: 'face',
        menuTitle,
        nativeRenderOptions: {
            Component: AccountParentalControlsScreen.Main,
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.AccountParentalControls.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            title: menuTitle,
            header,
            headerShown: true,
        },
        optionsWeb: {
            headerShown: true,
            showFooter: true,
            showNavBarMenu: true,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
        },
        accessMode: ACCESS_MODE.USER,
    };
};

export const buildMySubscriptionsScreen: ScreenConfigBuilder = (
    parameters,
    { t }
): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.MySubscriptions.getLink();
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.MY_SUBSCRIPTIONS,
            title: t('svod.manage.title'),
            correspondingProductScreen: PRODUCT_SCREENS.MY_SUBSCRIPTIONS,
        },
    });
    return {
        ...parameters,
        ...propsWithStaticFallback,
        menuTitle,
        nativeRenderOptions: {
            Component: MySubscriptionsScreen.Main,
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.MySubscriptions.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            title: menuTitle,
            header,
            headerShown: true,
        },
        optionsWeb: {
            headerShown: true,
            showFooter: true,
            showNavBarMenu: true,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
        },
        accessMode: ACCESS_MODE.USER,
    };
};

const buildMvpdSignInScreen: ScreenConfigBuilder = (
    parameters,
    { t }
): ScreenConfigBuilderResult => {
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.MVPD_SIGNIN,
            // update with translation
            title: 'Mvpd Login',
            correspondingProductScreen: PRODUCT_SCREENS.MVPD_SIGNIN,
        },
    });

    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.MvpdSignIn.getLink();

    return {
        ...parameters,
        ...propsWithStaticFallback,
        menuTitle,
        nativeRenderOptions: {
            // @ts-ignore
            Component: MvpdSignInScreen.Main,
        },
        options: {
            title: t('auth.signIn.title'),
            headerTransparent: false,
            headerShown: isFactorMobile,
            header: headerWithBack,
        },
        optionsWeb: {
            headerShown: false,
            showFooter: false,
            showNavBarMenu: false,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            static: [PlacementNative.OTHER_SCREENS],
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.MvpdSignIn.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
    };
};

const buildSubscriptionPurchaseScreen: ScreenConfigBuilder = (
    parameters,
    context
): ScreenConfigBuilderResult => {
    const { params } = context;
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.PurchaseSubscription.getLink({
        offerId: (params?.offerId as string) || '',
    });

    return {
        ...parameters,
        menuTitle: SOTT_DEFAULT_SCREENS.PURCHASE_SUBSCRIPTION,
        name: SOTT_DEFAULT_SCREENS.PURCHASE_SUBSCRIPTION,
        title: '',
        correspondingProductScreen: PRODUCT_SCREENS.PURCHASE_SUBSCRIPTION,
        nativeRenderOptions: {
            Component: PurchaseSubscriptionScreen.Main,
        },
        webMenuLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            headerShown: false,
        },
        optionsWeb: {
            showFooter: false,
            showNavBarMenu: true,
            headerShown: true,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.OTHER_SCREENS],
        },
        accessMode: ACCESS_MODE.USER,
    };
};

const buildPaymentMethodScreen: ScreenConfigBuilder = (
    parameters,
    { t }
): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.PaymentMethod.getLink();

    const propsWithStaticFallback = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.PAYMENT_METHOD,
            title: t('account.paymentMethod.title'),
            correspondingProductScreen: PRODUCT_SCREENS.PAYMENT_METHOD,
        },
    });

    return {
        ...parameters,
        ...propsWithStaticFallback,
        nativeRenderOptions: {
            Component: PaymentMethodScreen.Main,
        },
        webMenuLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            headerShown: false,
        },
        optionsWeb: {
            showFooter: false,
            showNavBarMenu: true,
            headerShown: true,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.OTHER_SCREENS],
        },
        accessMode: ACCESS_MODE.USER,
    };
};

const buildPaymentHistoryScreen: ScreenConfigBuilder = (
    parameters,
    { t }
): ScreenConfigBuilderResult => {
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.PaymentHistory.getLink();
    const propsWithStaticFallback = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.PAYMENT_HISTORY,
            title: t('account.paymentHistory.title'),
            correspondingProductScreen: PRODUCT_SCREENS.PAYMENT_HISTORY,
        },
    });

    return {
        ...parameters,
        ...propsWithStaticFallback,
        nativeRenderOptions: {
            Component: PaymentHistoryScreen.Main,
        },
        webMenuLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            headerShown: false,
        },
        optionsWeb: {
            showFooter: true,
            showNavBarMenu: true,
            headerShown: true,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.OTHER_SCREENS],
        },
        accessMode: ACCESS_MODE.USER,
    };
};

const buildPaymentResultScreen: ScreenConfigBuilder = (
    parameters,
    context
): ScreenConfigBuilderResult => {
    const { params } = context;
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.PaymentResult.getLink(params);

    return {
        ...parameters,
        menuTitle: SOTT_DEFAULT_SCREENS.PAYMENT_RESULT,
        name: SOTT_DEFAULT_SCREENS.PAYMENT_RESULT,
        title: '',
        correspondingProductScreen: PRODUCT_SCREENS.PAYMENT_RESULT,
        nativeRenderOptions: {
            Component: PaymentResultScreen.Main,
        },
        webMenuLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            headerShown: false,
        },
        optionsWeb: {
            showFooter: true,
            showNavBarMenu: true,
            headerShown: true,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.OTHER_SCREENS],
        },
        accessMode: ACCESS_MODE.USER,
    };
};

const buildSubscriptionsListScreen: ScreenConfigBuilder = (
    parameters,
    context
): ScreenConfigBuilderResult => {
    const { params } = context;
    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.SubscriptionsList.getLink(params);

    return {
        ...parameters,
        menuTitle: SOTT_DEFAULT_SCREENS.SUBSCRIPTIONS_LIST,
        name: SOTT_DEFAULT_SCREENS.SUBSCRIPTIONS_LIST,
        title: '',
        correspondingProductScreen: PRODUCT_SCREENS.SUBSCRIPTIONS_LIST,
        nativeRenderOptions: {
            Component: SubscriptionsListScreen.Main,
        },
        webMenuLink,
        matchesWebRoute: matchSlug(webMenuLink),
        options: {
            headerShown: false,
        },
        optionsWeb: {
            showFooter: true,
            showNavBarMenu: true,
            headerShown: true,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            menu: STANDARD_NATIVE_MENU_PLACEMENT,
            static: [PlacementNative.OTHER_SCREENS],
        },
        accessMode: ACCESS_MODE.USER,
    };
};

const buildSecondScreenActivationScreen: ScreenConfigBuilder = (
    parameters,
    { t }
): ScreenConfigBuilderResult => {
    const { menuTitle, ...propsWithStaticFallback } = getStaticValuesIfNotParametrized({
        parameters,
        staticValues: {
            name: SOTT_DEFAULT_SCREENS.SECOND_SCREEN_ACTIVATION,
            // update with translation
            title: 'Second Screen Activation',
            correspondingProductScreen: PRODUCT_SCREENS.SECOND_SCREEN_ACTIVATION,
        },
    });

    const webMenuLink = SOTT_DEFAULT_WEB_SCREENS.SecondScreenActivation.getLink();

    return {
        ...parameters,
        ...propsWithStaticFallback,
        menuTitle,
        nativeRenderOptions: {
            // @ts-ignore
            Component: SecondScreenActivation.Main,
        },
        options: {
            title: t('auth.signIn.title'),
            headerTransparent: false,
            headerShown: isFactorMobile,
            header: headerWithBack,
        },
        optionsWeb: {
            headerShown: false,
            showFooter: false,
            showNavBarMenu: false,
        },
        placementWeb: {
            menu: getStandardWebMenuPlacement(parameters),
        },
        placementNative: {
            static: [PlacementNative.OTHER_SCREENS],
        },
        webMenuLink,
        dangerouslyGetWebLink: SOTT_DEFAULT_WEB_SCREENS.MvpdSignIn.getLink,
        matchesWebRoute: matchSlug(webMenuLink),
    };
};

/**
 * ORDER HERE DETERMINES IN WHAT ORDER ITEMS APPEAR IN MENU.
 */
export const SOTT_DEFAULT_SCREEN_CONFIG_BUILDERS: SottScreenConfigBuilders = {
    buildUpsertProfileScreen,
    buildIndexScreen,
    buildDynarowScreen,
    buildPlaybackScreen,
    buildPlaylistScreen,
    buildDetailsScreen,
    buildGenreScreen,
    buildManageProfilesScreen,
    buildWhoIsWatchingScreen,
    buildMyListScreen,
    buildMyPurchaseScreen,
    buildRecordingsScreen,
    buildRecentlyWatchedScreen,
    buildForgotPasswordScreen,
    buildSignInScreen,
    buildMvpdSignInScreen,
    buildSignUpScreen,
    buildSignUpSuccessScreen,
    buildMoreScreen,
    buildDeleteAccountSuccessScreen,
    buildDeleteAccountScreen,
    buildAccountScreen,
    buildMyContentScreen,
    buildLandingScreen,
    buildExternalScreen,
    buildSettingsScreen,
    buildEpgScreen,
    buildSearchScreen,
    buildNotFoundScreen,
    buildPageScreen,
    buildWelcomeScreen,
    buildSignOutScreen,
    buildCustomMenuScreen,
    buildFallbackScreen,
    buildSubMenuScreen,
    buildEnterPinScreen,
    buildHomeScreen,
    buildTextPageScreen,
    buildManageDevicesScreen,
    buildRemoveDeviceScreen,
    buildResetPasswordScreen,
    buildSeeAllScreen,
    buildCreatePinScreen,
    buildTermsAndConditionsScreen,
    buildAddSubscriptionScreen,
    buildOnboardingIntroScreen,
    buildAdditionalInfoScreen,
    buildAccountDetailsScreen,
    buildAccountSubscriptionScreen,
    buildAccountParentalControlsScreen,
    buildMySubscriptionsScreen,
    buildLanguageScreen,
    buildTechnicalInfoScreen,
    buildStreamingScreen,
    buildThemeScreen,
    buildServiceScreen,
    buildSubscriptionPurchaseScreen,
    buildPaymentMethodScreen,
    buildPaymentHistoryScreen,
    buildPaymentResultScreen,
    buildSubscriptionsListScreen,
    buildSecondScreenActivationScreen,
} as const;

export type { SottScreenConfigBuilders };
