import _defineProperty from"@babel/runtime/helpers/defineProperty";function ownKeys(object,enumerableOnly){var keys=Object.keys(object);if(Object.getOwnPropertySymbols){var symbols=Object.getOwnPropertySymbols(object);enumerableOnly&&(symbols=symbols.filter(function(sym){return Object.getOwnPropertyDescriptor(object,sym).enumerable;})),keys.push.apply(keys,symbols);}return keys;}function _objectSpread(target){for(var i=1;i<arguments.length;i++){var source=null!=arguments[i]?arguments[i]:{};i%2?ownKeys(Object(source),!0).forEach(function(key){_defineProperty(target,key,source[key]);}):Object.getOwnPropertyDescriptors?Object.defineProperties(target,Object.getOwnPropertyDescriptors(source)):ownKeys(Object(source)).forEach(function(key){Object.defineProperty(target,key,Object.getOwnPropertyDescriptor(source,key));});}return target;}

import initApp,{getMergedRuntimeConfig}from'@24i/nxg-sdk-smartott/src/Application/initApp';
import{getDefaultBackstageClients}from'@24i/nxg-integration-backstage/src/clients/DefaultBackstageClients';
import{defaultRuntimeConfig,getDefaultSDKClients}from'@24i/nxg-sdk-smartott-defaults';
import{getDefaultClientStubs}from'@24i/nxg-sdk-smartott-stubs';
import{DefaultAppStartClient}from'@24i/nxg-sdk-smartott-defaults/src/clients/AppStartClient';
import{AppVersion}from'@24i/nxg-core-utils/src/globalSingletons';
import runtimeJson from"../../../platformAssets/renative.runtime.json";
import{createExperienceUserDataClient}from"./clients/ExperienceUserDataClient";
import{CUSTOM_SCREEN_BUILDERS}from"./customScreens";
import{i18nOptions}from"../locale";
import{createExperienceAppVersionClient}from"./clients/ExperienceAppVersionDataClient";
import{createExperienceEpgDataClient}from"./clients/ExperienceEpgDataClient";

import STATIC_LOGO from"../../../platformAssets/runtime/images/logo.png";

AppVersion.set(runtimeJson.appVersion+"-"+runtimeJson.timestamp);

var runtimeConfig=getMergedRuntimeConfig(runtimeJson,defaultRuntimeConfig);

var staticSplashConfig={
background:null,
logo:STATIC_LOGO
};

initApp({i18nOptions:i18nOptions});

var getClients=function getClients(serviceParams){

var defaultBackstageClients=getDefaultBackstageClients(serviceParams);


var userDataClient=createExperienceUserDataClient(serviceParams);
var appVersionClient=createExperienceAppVersionClient(serviceParams);


var defaultClientStubs=getDefaultClientStubs(defaultBackstageClients.contentDataClient);


var defaultSDKClients=getDefaultSDKClients(CUSTOM_SCREEN_BUILDERS,runtimeConfig);

var epgDataClient=createExperienceEpgDataClient(serviceParams);

var appStartClient=new DefaultAppStartClient(_objectSpread(_objectSpread(_objectSpread(_objectSpread({},
defaultSDKClients),
defaultClientStubs),
defaultBackstageClients),{},{
epgDataClient:epgDataClient,
userDataClient:userDataClient,
appVersionClient:appVersionClient})
);

return _objectSpread(_objectSpread(_objectSpread(_objectSpread({},
defaultSDKClients),
defaultClientStubs),
defaultBackstageClients),{},{
epgDataClient:epgDataClient,
userDataClient:userDataClient,
appVersionClient:appVersionClient,
appStartClient:appStartClient});

};
export{getClients};

export default{
runtimeConfig:runtimeConfig,
staticSplashConfig:staticSplashConfig
};