/*
@PRODUCT_FEATURE: H 1.2, H 1.3.0, H 1.4
*/
/* eslint-disable react/display-name */
import React from 'react';
import Loader from '@24i/nxg-sdk-gluons/src/components/ui/Loader';

import { useAssetPinControl } from '@24i/nxg-sdk-pin-protection/src/hooks/useAssetPinControl';
import View from './View';
import {
    defaultRenderEpisodePicker,
    defaultRenderPackshotGrid,
    defaultRenderDetailsTab,
} from './components/BottomPart';
import { useViewModel } from './viewModel';
import getDetailsScreenStyles from './styles';
// use when SSR is re-implemented
// import * as prerenderFunctions from '../../pages/details/[type]/[id]';
import DetailsScreenContent from './components/DetailsScreenContent';
import EpisodePicker from './components/EpisodePicker';
import { DetailsScreenProps } from './types';

const Main = (props: DetailsScreenProps) => {
    const viewModelProps = useViewModel(props);
    const { hasAgeBlocker, hasAdultBlocker } = useAssetPinControl({
        ...props,
        ...viewModelProps,
    });
    if (hasAgeBlocker || hasAdultBlocker) {
        return <Loader />;
    }
    return <View {...viewModelProps} />;
};

export default {
    Main,
    View,
    useViewModel,
    getDefaultStyles: getDetailsScreenStyles,
    // use when SSR is re-implemented
    // page: {
    //    details: {
    //        '[type]': {
    //            '[id]': prerenderFunctions,
    //        },
    //    },
    // },
    // components
    DetailsScreenContent,
    EpisodePicker,
    // default render functions
    defaultRenderEpisodePicker,
    defaultRenderPackshotGrid,
    defaultRenderDetailsTab,
};
