import { WebRouter, useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { useFirebase } from '@24i/nxg-sdk-smartott/src/context/Firebase';
import { RouteProp } from '@react-navigation/native';
import { StackParamList } from '@24i/nxg-sdk-smartott';
import { StackNavigationProp } from '@react-navigation/stack';
import { useEffect, useState } from 'react';
import { isPlatformWeb } from 'renative';
import useNavigation from '@24i/nxg-core-router/src/hooks/useNavigation';
import { log } from '@24i/nxg-core-utils/src/logger';
import useRoute from '@24i/nxg-core-router/src/hooks/useRoute';
import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';
import { onAnalytics, ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics';
import {
    SOTT_DEFAULT_ROOT_SCREENS,
    SOTT_DEFAULT_SCREENS,
    SOTT_DEFAULT_WEB_SCREENS,
} from '../../../navigation/constants';
import {
    OnSignUpContext,
    SignupModelProps,
    SignUpNavigationParamsType,
    SignupViewProps,
} from '../types';
import {
    emailValidatorReg,
    nameValidatorReg,
    passwordValidatorReg,
} from '../../../utils/regexConsts';

const useViewModel = (props: SignupModelProps): SignupViewProps => {
    const navigation = useNavigation<StackNavigationProp<SignUpNavigationParamsType>>();
    const route = useRoute<RouteProp<StackParamList, SOTT_DEFAULT_SCREENS.SIGN_UP>>();
    const { recordError, crashlyticsLog } = useFirebase();
    const userDataClient = useUserData();
    const router = useRouter();

    const [isLoading, setIsLoading] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isEmailIncorrect, setIsEmailIncorrect] = useState(false);
    const [isFirstNameIncorrect, setIsFirstNameIncorrect] = useState(false);
    const [isLastNameIncorrect, setIsLastNameIncorrect] = useState(false);
    const [isPasswordIncorrect, setIsPasswordIncorrect] = useState(false);
    const [isSamePasswords, setIsSamePasswords] = useState(true);
    const [signUpErrorMessage, setSignUpErrorMessage] = useState<string | undefined>(undefined);

    const webRouter = router as unknown as WebRouter;
    const onBack = () => (isPlatformWeb ? webRouter.back() : navigation.goBack());

    const onFirstNameChange = (input: string) => setFirstName(input);
    const onLastNameChange = (input: string) => setLastName(input);
    const onEmailChange = (input: string) => setEmail(input);
    const onPasswordChange = (input: string) => setPassword(input);
    const onConfirmPasswordChange = (input: string) => setConfirmPassword(input);

    // LEAVING THIS DEFAULT AS USD FOR NOW
    const DEFAULT_CURRENCY = 'USD';

    const clearInputsOnRegister = (): void => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setPassword('');
        setConfirmPassword('');
    };

    const register = (
        firstNameToRegister: string,
        lastNameToRegister: string,
        usernameToRegister: string,
        passwordToRegister: string,
        currencyToRegister: string
    ): void => {
        userDataClient
            .register({
                firstName: firstNameToRegister,
                lastName: lastNameToRegister,
                username: usernameToRegister,
                password: passwordToRegister,
                currency: currencyToRegister,
            })
            .then(() => {
                if (isPlatformWeb) {
                    const signupSuccess = SOTT_DEFAULT_WEB_SCREENS.SignUpSuccess.getLink();
                    webRouter.push(signupSuccess.href, signupSuccess.as);
                } else {
                    clearInputsOnRegister();
                    navigation.navigate(SOTT_DEFAULT_SCREENS.SIGN_UP_SUCCESS);
                }

                setIsLoading(false);
                onAnalytics(ANALYTICS_TRIGGERS.SIGN_UP, { email });
            })
            .catch((err) => {
                recordError(err);
                log(err);
                setIsEmailIncorrect(true);
                if (err?.message) {
                    setSignUpErrorMessage(err.message);
                } else setSignUpErrorMessage('Something went wrong... Try Again');
                setIsLoading(false);
            });
    };

    useEffect(() => {
        crashlyticsLog('Signup Screen entered');

        return crashlyticsLog('Signup Screen exited');
    }, []);

    const resetIncorrections = (): void => {
        setSignUpErrorMessage(undefined);
        setIsFirstNameIncorrect(false);
        setIsLastNameIncorrect(false);
        setIsEmailIncorrect(false);
        setIsPasswordIncorrect(false);
        setIsSamePasswords(false);
    };

    const validateFields = ({
        email: emailToValidate,
        firstName: firstNameToValidate,
        lastName: lastNameToValidate,
        password: passwordToValidate,
        confirmPassword: confirmPasswordToValidate,
    }: OnSignUpContext): boolean => {
        resetIncorrections();
        let correct = true;
        if (emailValidatorReg.test(emailToValidate) === false) {
            correct = false;
            setIsEmailIncorrect(true);
        }
        if (nameValidatorReg.test(firstNameToValidate) === false) {
            correct = false;
            setIsFirstNameIncorrect(true);
        }
        if (nameValidatorReg.test(lastNameToValidate) === false) {
            correct = false;
            setIsLastNameIncorrect(true);
        }
        if (passwordValidatorReg.test(passwordToValidate) === false) {
            correct = false;
            setIsPasswordIncorrect(true);
        }

        if (passwordToValidate === confirmPasswordToValidate) {
            setIsSamePasswords(true);
        } else {
            correct = false;
            setIsSamePasswords(false);
        }
        return correct;
    };

    const onSignUpPress = ({
        firstName: submittedFirstName,
        lastName: submittedLastName,
        email: submittedEmail,
        password: submittedPassword,
        confirmPassword: submittedConfirmPassword,
    }: OnSignUpContext): void => {
        const isCorrect = validateFields({
            email: submittedEmail,
            firstName: submittedFirstName,
            lastName: submittedLastName,
            password: submittedPassword,
            confirmPassword: submittedConfirmPassword,
        });
        if (isCorrect) {
            setIsLoading(true);
            register(
                submittedFirstName,
                submittedLastName,
                submittedEmail,
                submittedPassword,
                DEFAULT_CURRENCY
            );
        }
    };

    const onSignInPress = (): void => {
        if (!isPlatformWeb) {
            navigation.navigate(SOTT_DEFAULT_ROOT_SCREENS.OTHER_SCREENS_STACK_SCREEN, {
                screen: SOTT_DEFAULT_SCREENS.SIGN_IN,
            });
            return;
        }

        const signInLink = SOTT_DEFAULT_WEB_SCREENS.SignIn.getLink();
        router.push(signInLink.href, signInLink.as);
    };

    return {
        ...props,
        isLoading,
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
        isEmailIncorrect,
        isFirstNameIncorrect,
        isLastNameIncorrect,
        isPasswordIncorrect,
        isSamePasswords,
        customEmailErrorMessage: signUpErrorMessage,
        navigation,
        route,
        onBack,
        onFirstNameChange,
        onLastNameChange,
        onEmailChange,
        onPasswordChange,
        onConfirmPasswordChange,
        onSignUpPress,
        onSignInPress,
    };
};

export { useViewModel };
