import { createProfile, createUser, Profile, User } from '@24i/nxg-sdk-photon';
import { FetchProfileResponse, FetchProfilesResponse, FetchUserResponse } from './types';

export const mapProfileResponse = (response: FetchProfileResponse): Profile | never => {
    return createProfile({
        id: String(response.id),
        userId: response.userId,
        name: response.name,
        age: response.age,
        isPinProtected: response.isPinRequired,
        defaultProfile: response.defaultProfile,
        image: response.image?.images?.avatar?.[0].url,
        selected: response.selected,
    });
};

export const mapProfilesResponse = (response: FetchProfilesResponse): Profile[] | never => {
    return response.map(mapProfileResponse);
};

export const mapUser = (response: FetchUserResponse): User | never => {
    return createUser({ ...response.user, id: String(response.user.id) });
};
