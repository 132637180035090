import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { log } from '@24i/nxg-core-utils/src/logger';
import { useNavigationConfigQuery } from '../query/useNavigationConfigQuery';
import { CurrentPageConfig } from './types';

export const useCurrentWebPageConfig = (): CurrentPageConfig => {
    const { navigationConfig, currentPageConfig } = useNavigationConfigQuery();
    const { route } = useRouter();

    if (!navigationConfig) {
        return {
            currentPageConfig: null,
            configStatus: 'config_not_yet_available',
        };
    }

    if (!currentPageConfig) {
        log(
            `The current page: ${route} was not found in the navigation config.
            The app expects all pages to be added to the allNavigationItems array in the navigation config.
            Have you forgot to add the page to the array?`
        );
        return {
            configStatus: 'config_not_found',
            currentPageConfig: null,
        };
    }
    if (route !== currentPageConfig.webMenuLink?.href) {
        return {
            currentPageConfig: null,
            configStatus: 'config_not_yet_available',
        };
    }

    return {
        currentPageConfig,
        configStatus: 'valid_config_found',
    };
};
