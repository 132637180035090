import _slicedToArray from"@babel/runtime/helpers/slicedToArray";import _extends from"@babel/runtime/helpers/extends";import _objectWithoutProperties from"@babel/runtime/helpers/objectWithoutProperties";var _excluded=["clients"];import _regeneratorRuntime from"@babel/runtime/regenerator";

import React,{useEffect,useRef,useState}from'react';import AppState from"react-native-web/dist/exports/AppState";import ActivityIndicator from"react-native-web/dist/exports/ActivityIndicator";

import{
Application,
useStore,
FirebaseProvider}from
'@24i/nxg-sdk-smartott';
import{useAppStart}from'@24i/nxg-sdk-smartott/src/context/AppStartContext';
import{DefaultDataProviders}from'@24i/nxg-sdk-smartott-shared/src/context/DefaultDataProviders';
import{DefaultSDKProviders}from'@24i/nxg-sdk-smartott/src/context/DefaultSDKProviders';
import{DefaultClientStubsProvider}from'@24i/nxg-sdk-smartott/src/context/DefaultClientStubsProvider';
import{useStorageLanguageSync}from'@24i/nxg-sdk-smartott/src/hooks/cleeng/useStorageLanguageSync';
import{
useUserData}from
'@24i/nxg-sdk-smartott-shared/src/context/UserData';
import{QueryProvider}from'@24i/nxg-sdk-smartott-shared/src/context/Query';

import{log}from'@24i/nxg-core-utils/src/logger';
import{Storage}from'@24i/nxg-sdk-quantum';
import{ASYNC_STORAGE_KEY_USER_TOKEN}from'@24i/nxg-core-utils/src/constants';
import{isPlatformWeb}from'renative';
import{AdobePrimetimeProvider}from'@24i/adobe-primetime/src';
import{ApplicationStoreProvider}from"./context/Store";
import{ASYNC_STORAGE_KEY_SERVICE,getInitialApiService}from"./utils";
import config,{getClients}from"./config";


import defaultTheme from"../../platformAssets/runtime/defaultTheme.json";

var runtimeConfig=config.runtimeConfig,staticSplashConfig=config.staticSplashConfig;

var AppModule=function AppModule(_ref){var clients=_ref.clients,props=_objectWithoutProperties(_ref,_excluded);
var userDataClient=useUserData();
var _useStore=useStore(),setBackstageUserData=_useStore.setBackstageUserData;
var currentAppState=useRef(AppState.currentState);

var setAppState=function setAppState(value){
currentAppState.current=value;
};

var analyticsDataClient=clients.analyticsDataClient;

var _useAppStart=useAppStart(),initApplication=_useAppStart.initApplication;


var backstageAnalyticsLog=function backstageAnalyticsLog(eventName,token){
try{
analyticsDataClient.logAnalyticsEventToBackstage(
eventName,
{timestamp:Date.now()},
{key:token}
);
}catch(err){
log(err);
}
};

var handleAppStateChange=function handleAppStateChange(nextAppState){
if(currentAppState.current==='active'&&nextAppState.match(/inactive|background/)){
Storage.getItem(ASYNC_STORAGE_KEY_USER_TOKEN).then(function(token){
backstageAnalyticsLog('shutdown',token);
});
}
setAppState(nextAppState);
};

useEffect(function(){
var setBackstageUser=function setBackstageUser(){var backstageUser;return _regeneratorRuntime.async(function setBackstageUser$(_context){while(1)switch(_context.prev=_context.next){case 0:_context.prev=0;_context.next=3;return _regeneratorRuntime.awrap(


userDataClient.fetchBackstageUser());case 3:backstageUser=_context.sent;
setBackstageUserData(backstageUser);_context.next=10;break;case 7:_context.prev=7;_context.t0=_context["catch"](0);

log(_context.t0);case 10:case"end":return _context.stop();}},null,null,[[0,7]],Promise);};



setBackstageUser();

var appStateListener=AppState.addEventListener('change',handleAppStateChange);
return function(){
appStateListener==null?void 0:appStateListener.remove();
};
},[]);

useEffect(function(){
initApplication();
},[]);


return(
React.createElement(Application.Main,_extends({},
props,{
staticSplashConfig:

staticSplashConfig})

));

};

var App=function App(props){
var _useState=useState(),_useState2=_slicedToArray(_useState,2),service=_useState2[0],setService=_useState2[1];
var _useState3=useState(),_useState4=_slicedToArray(_useState3,2),clients=_useState4[0],setClients=_useState4[1];

useEffect(function(){
if(service&&service.id){
var serviceParam={
serviceId:service==null?void 0:service.id,
applicationId:service==null?void 0:service.appId
};
var appClients=getClients(serviceParam);
setClients(appClients);
}
},[service]);

var setSelectedService=function setSelectedService(){var selectedService;return _regeneratorRuntime.async(function setSelectedService$(_context2){while(1)switch(_context2.prev=_context2.next){case 0:_context2.prev=0;_context2.next=3;return _regeneratorRuntime.awrap(

Storage.getItem(ASYNC_STORAGE_KEY_SERVICE));case 3:selectedService=_context2.sent;if(!
selectedService){_context2.next=9;break;}_context2.next=7;return _regeneratorRuntime.awrap(
setService(JSON.parse(selectedService)));case 7:_context2.next=10;break;case 9:

setService(getInitialApiService());case 10:_context2.next=15;break;case 12:_context2.prev=12;_context2.t0=_context2["catch"](0);


log(_context2.t0);case 15:case"end":return _context2.stop();}},null,null,[[0,12]],Promise);};



useEffect(function(){
setSelectedService();
},[]);


useStorageLanguageSync();

var loadingIndicator=isPlatformWeb?null:React.createElement(ActivityIndicator,null);

return clients?
React.createElement(QueryProvider,null,
React.createElement(ApplicationStoreProvider,{
defaultService:service,
runtimeConfig:runtimeConfig,
setService:setService},

React.createElement(DefaultDataProviders,{clients:clients,options:{defaultTheme:defaultTheme}},
React.createElement(DefaultSDKProviders,{clients:clients},
React.createElement(DefaultClientStubsProvider,{clients:clients},
React.createElement(FirebaseProvider,null,
React.createElement(AdobePrimetimeProvider,null,
React.createElement(AppModule,_extends({},props,{clients:clients}))
)
)
)
)
)
)
):

loadingIndicator;

};

export default App;