import React from 'react';
import { ImageServiceProvider } from '@24i/nxg-sdk-gluons/src/context/ImageService';
import { LayoutProvider } from '@24i/nxg-sdk-gluons/src/context/Layout';
import { MonitoringDataProvider } from '@24i/nxg-sdk-smartott-shared/src/context/MonitoringData';
import { AnalyticsProvider } from '@24i/nxg-sdk-smartott-shared/src/context/Analytics';
import { AppNavigationProvider } from '../AppNavigation';
import { CastProvider } from '../Cast';
import { AppStartProvider } from '../AppStartContext';
import { DefaultSDKProvidersProps } from './types';
import { PlayerEngineProvider } from '../PlayerEngine';
import { SubscriptionsHandlerProvider } from '../SubscriptionsHandlerProvider';

export const DefaultSDKProviders = (props: DefaultSDKProvidersProps) => {
    const { clients, children } = props;
    const {
        appNavigationClient,
        imageServiceClient,
        sentryMonitoringDataClient,
        // dataPlatformClient,
        googleAnalyticsClient,
        twentyFourIQClient,
        layoutClient,
        appStartClient,
    } = clients;

    return (
        <AppNavigationProvider client={appNavigationClient}>
            <ImageServiceProvider client={imageServiceClient}>
                <MonitoringDataProvider
                    clients={[sentryMonitoringDataClient /* , dataPlatformClient */]}
                >
                    <AnalyticsProvider clients={[googleAnalyticsClient, twentyFourIQClient]}>
                        <LayoutProvider client={layoutClient}>
                            <CastProvider>
                                <PlayerEngineProvider>
                                    <AppStartProvider client={appStartClient}>
                                        <SubscriptionsHandlerProvider>
                                            {children}
                                        </SubscriptionsHandlerProvider>
                                    </AppStartProvider>
                                </PlayerEngineProvider>
                            </CastProvider>
                        </LayoutProvider>
                    </AnalyticsProvider>
                </MonitoringDataProvider>
            </ImageServiceProvider>
        </AppNavigationProvider>
    );
};
