import moment from 'moment';
import { Payload, BaseResult } from '../types';

export const mapBase = (payload: Payload): BaseResult => {
    let sign = '+';
    if (payload.deviceTimeZone < 0) sign = '-';
    const deviceTimeZone = `${sign}${`0${Math.abs(payload.deviceTimeZone).toString()}`.slice(
        -2
    )}:00`;

    return {
        session_id: payload.payload.sessionId,
        timestamp_initiated: moment
            .utc(payload.payload.timestamp)
            .format('YYYY-MM-DDTHH:mm:ss+00:00'),
        device_id: payload.deviceId,
        device_type: payload.deviceType,
        device_platform: payload.platform,
        device_timezone: deviceTimeZone,
        service_id: payload.serviceId,
        user_id: payload.userId,
        user_profile_id: payload.userProfileId,
        ...(!payload.userId && { user_anon_id: payload.deviceId }),
    };
};
