import { createGuard, createEnum, nullable } from '@24i/nxg-core-utils/src/guards';
import { EditionGuard, ProductGuard, SubscriptionGuard } from '@24i/nxg-sdk-photon';
import * as t from 'io-ts';

export enum SubscriptionStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    CANCELLED = 'cancelled',
}

export const DeprecatedSubscriptionGuard = createGuard(
    'DeprecatedBackstageSubscription',
    {
        id: t.string,
        name: t.string,
        price: t.number,
        currency: t.string,
        period: t.string,
        applicableTaxRate: t.number,
        tags: t.array(t.string),
        status: createEnum<SubscriptionStatus>(SubscriptionStatus, 'SubscriptionStatus'),
        description: nullable(t.string),
        createdAt: t.string,
        updatedAt: t.string,
    },
    {
        customerDetails: createGuard('CustomerDetails', {
            expiresAt: t.number,
            status: createEnum<SubscriptionStatus>(SubscriptionStatus, 'SubscriptionStatus'),
            pending: t.boolean,
            nextPaymentPrice: t.number,
            paymentGateway: t.string,
            paymentMethod: t.string,
        }),
    }
);

export const DeprecatedSubscriptionResponseGuard = createGuard('DeprecatedSubscriptionsResponse', {
    subscriptions: t.array(DeprecatedSubscriptionGuard),
});
export const ProductsResponseGuard = createGuard('ProductsResponse', t.array(ProductGuard));

export const SubscriptionProductResponseGuard = t.array(
    SubscriptionGuard,
    'SubscriptionProductsResponse'
);

export const ExternalCheckoutResponseGuard = createGuard('ExternalCheckoutResponse', {
    url: t.string,
});
export const EditionsResponseGuard = createGuard('EditionsResponse', {
    editions: t.array(EditionGuard),
});

export const ProcessReceiptResponseGuard = createGuard('ProcessReceiptResponse', {});
