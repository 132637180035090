import { createGuard, decodeModelWith, nullable } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';

export const BlurColorGuard = createGuard('BlurColor', {
    color: t.string,
    type: t.string,
    blur: t.number,
});

export type BlurColor = t.TypeOf<typeof BlurColorGuard>;

export const GradientColorGuard = createGuard('GradientColor', {
    color: t.string,
    colors: t.array(t.string),
    percentages: t.array(t.number),
    type: t.string,
});

export type GradientColor = t.TypeOf<typeof GradientColorGuard>;

export const ColorsGuard = createGuard('Colors', {
    background1: t.string,
    primary1: t.string,
    primaryBlur1: BlurColorGuard,
    primaryBlur1Fallback: t.string,
    primaryBlur2: BlurColorGuard,
    primaryBlur2Fallback: t.string,
    primary2: t.string,
    primary3: t.string,
    primary4: t.string,
    lighter1: t.string,
    lighterBlur1: BlurColorGuard,
    lighterBlur1Fallback: t.string,
    lighterBlur2: BlurColorGuard,
    lighterBlur2Fallback: t.string,
    lighter2: t.string,
    lighter3: t.string,
    lighter4: t.string,
    darker1: t.string,
    darkerBlur1: BlurColorGuard,
    darkerBlur1Fallback: t.string,
    darkerBlur2: BlurColorGuard,
    darkerBlur2Fallback: t.string,
    darker2: t.string,
    darker3: t.string,
    darker4: t.string,
    contrast1: t.string,
    contrastBlur1: BlurColorGuard,
    contrastBlur1Fallback: t.string,
    contrastBlur2: BlurColorGuard,
    contrastBlur2Fallback: t.string,
    contrast2: t.string,
    contrast3: t.string,
    contrast4: t.string,
    contrast5: t.string,
    gradient1: GradientColorGuard,
    gradient2: GradientColorGuard,
    gradient3: GradientColorGuard,
    error: t.string,
    warning: t.string,
    success: t.string,
    textPrimary: t.string,
    textSecondary: t.string,
    textButtonPrimary: t.string,
    textButtonSecondary: t.string,
    textButtonTertiary: t.string,
    buttonPrimary: t.string,
    buttonSecondary: t.string,
    buttonTertiary: t.string,
    labelPrimary: t.string,
    labelSecondary: t.string,
    progressBarAccent: t.string,
    progressBarPreAccent: t.string,
    progressBarLive: t.string,
    progressBarBackground: t.string,
    menuFocus: t.string,
    menuItemActive: t.string,
    menuItemInactive: t.string,
    epgRowActive: t.string,
    epgRowInactive: t.string,
    epgRowActiveWhole: t.string,
    epgRowActivePassed: t.string,
    epgTop: t.string,
    epgSide: t.string,
    epgBackground: t.string,
    tileMainButton: t.string,
    statusBarTheme: t.string,
});

export type Colors = t.TypeOf<typeof ColorsGuard>;

const FontGuard = createGuard('Font', undefined, {
    family: t.string,
    weight: t.string,
    style: t.string,
    size: t.number,
    character: t.number,
    line: t.number,
    transform: t.string,
});

export type Font = t.TypeOf<typeof FontGuard>;

const FontVariantGuard = createGuard('FontVariant', undefined, {
    hero: FontGuard,
    h1: FontGuard,
    h2: FontGuard,
    h3: FontGuard,
    h4: FontGuard,
    h5: FontGuard,
    h6: FontGuard,
    body1: FontGuard,
    body2: FontGuard,
    error: FontGuard,
    'menuItem-active': FontGuard,
    'menuItem-inactive': FontGuard,
    primaryButton: FontGuard,
    secondaryButton: FontGuard,
    tertiaryButton: FontGuard,
});

export type FontVariant = t.TypeOf<typeof FontVariantGuard>;

export const FontsGuard = createGuard('FontsGuard', undefined, {
    S: FontVariantGuard,
    M: FontVariantGuard,
    L: FontVariantGuard,
    XL: FontVariantGuard,
});

export type Fonts = t.TypeOf<typeof FontsGuard>;

const ElementGuard = createGuard('Element', undefined, {
    type: t.string,
    value: nullable(t.union([t.string, t.number])),
    label: t.string,
});

export type Element = t.TypeOf<typeof ElementGuard>;

export const ElementsGuard = createGuard('Elements', undefined, {
    'buttonPrimary-cornerRadius': ElementGuard,
    'buttonSecondary-cornerRadius': ElementGuard,
    'buttonTertiary-cornerRadius': ElementGuard,
    'generalTile-cornerRadius': ElementGuard,
    'headerTile-cornerRadius': ElementGuard,
    'textField-cornerRadius': ElementGuard,
    'searchField-cornerRadius': ElementGuard,
    'pinField-cornerRadius': ElementGuard,
    'epgItem-cornerRadius': ElementGuard,
    'label-cornerRadius': ElementGuard,
    'progressBar-cornerRadius': ElementGuard,
    clientLogo: ElementGuard,
    backgroundImage: ElementGuard,
    landingImageLandscape: ElementGuard,
    landingImagePortrait: ElementGuard,
});

export type Elements = t.TypeOf<typeof ElementsGuard>;

const IconPropsGuard = createGuard(
    'IconPropsGuard',
    {
        iconFont: t.string,
        iconName: t.string,
    },
    {
        width: t.number,
        height: t.number,
        iconImage: t.string,
    }
);

export type IconProps = t.TypeOf<typeof IconPropsGuard>;

const IconMapIconGuard = createGuard('IconMapIcon', {
    name: t.string,
    font: t.string,
});

const IconMapImageGuard = createGuard(
    'IconMapImage',
    {
        uri: t.string,
    },
    {
        color: t.string,
        activeUri: t.string,
        size: t.type({
            width: t.number,
            height: t.number,
        }),
    }
);

export const isIconMapIcon = (data: Record<string, unknown>): data is IconMapIcon => {
    try {
        return !!decodeModelWith(IconMapIconGuard, data, 'IconMapIcon', { disableErrorLog: true });
    } catch {
        return false;
    }
};

export const isIconMapImage = (data: Record<string, unknown>): data is IconMapImage => {
    try {
        return !!decodeModelWith(IconMapImageGuard, data, 'IconMapImage', {
            disableErrorLog: true,
        });
    } catch {
        return false;
    }
};

export type IconMapIcon = t.TypeOf<typeof IconMapIconGuard>;

export type IconMapImage = t.TypeOf<typeof IconMapImageGuard>;

const IconMapItemGuard = t.union([IconMapIconGuard, IconMapImageGuard]);

export type IconMapItem = t.TypeOf<typeof IconMapItemGuard>;

const EpgIconMapItemGuard = createGuard('EpgIconMapItem', {
    iconImage: t.union([t.number, t.string]),
});

export const MergedDefaultIconsGuard = createGuard('', {
    menu: createGuard('Menu', {
        navbarFont: t.string,
        iconsMap: createGuard('IconsMap', t.record(t.string, IconMapItemGuard)),
        more: IconPropsGuard,
        forward: IconPropsGuard,
        back: IconPropsGuard,
    }),
    player: createGuard('Player', {
        play: IconPropsGuard,
        pause: IconPropsGuard,
        cast: IconPropsGuard,
        settings: IconPropsGuard,
        seekForward: IconPropsGuard,
        seekBackward: IconPropsGuard,
        pictureInPicture: IconPropsGuard,
        fullSchedule: IconPropsGuard,
        fullscreen: IconPropsGuard,
        audioAndSubs: IconPropsGuard,
        moreEpisodes: IconPropsGuard,
        previousEpisode: IconPropsGuard,
        nextEpisode: IconPropsGuard,
        otherChannels: IconPropsGuard,
        record: IconPropsGuard,
        startOver: IconPropsGuard,
        volumeUp: IconPropsGuard,
        volumeDown: IconPropsGuard,
        volumeOff: IconPropsGuard,
    }),
    settings: createGuard('Settings', {
        back: IconPropsGuard,
        checkmark: IconPropsGuard,
        chevronRight: IconPropsGuard,
        check: IconPropsGuard,
        close: IconPropsGuard,
        iphone: IconPropsGuard,
        ipad: IconPropsGuard,
        laptop: IconPropsGuard,
        language: IconPropsGuard,
        info: IconPropsGuard,
        appTheme: IconPropsGuard,
        service: IconPropsGuard,
    }),
    search: createGuard('Search', {
        dropdownItem: IconPropsGuard,
        input: IconPropsGuard,
        clear: IconPropsGuard,
        cancel: IconPropsGuard,
    }),
    details: createGuard('Details', {
        addToList: IconPropsGuard,
        removeFromList: IconPropsGuard,
        record: IconPropsGuard,
        share: IconPropsGuard,
        play: IconPropsGuard,
        remind: IconPropsGuard,
        unrated: IconPropsGuard,
        rated: IconPropsGuard,
        startOver: IconPropsGuard,
        purchase: IconPropsGuard,
        rent: IconPropsGuard,
        geoBlocked: IconPropsGuard,
        cancelRecording: IconPropsGuard,
        deleteRecording: IconPropsGuard,
        setReminder: IconPropsGuard,
        reminderIsSet: IconPropsGuard,
    }),
    myContent: createGuard('MyContent', {
        myList: IconPropsGuard,
        downloads: IconPropsGuard,
        recordings: IconPropsGuard,
        purchases: IconPropsGuard,
        reminders: IconPropsGuard,
        recentlyWatched: IconPropsGuard,
        pencil: IconPropsGuard,
        trash: IconPropsGuard,
    }),
    auth: createGuard('Auth', {
        incorrect: IconPropsGuard,
    }),
    packshot: createGuard('Packshot', {
        moreInfo: IconPropsGuard,
        play: IconPropsGuard,
        check: IconPropsGuard,
        delete: IconPropsGuard,
        cancel: IconPropsGuard,
    }),
    epg: createGuard('Epg', {
        chevronRight: IconPropsGuard,
        portraitChannelView: EpgIconMapItemGuard,
        portraitTimeView: EpgIconMapItemGuard,
        filter: IconPropsGuard,
        calendar: IconPropsGuard,
    }),
    common: createGuard('Common', {
        modalClose: IconPropsGuard,
        dropdownExpand: IconPropsGuard,
        arrowUp: IconPropsGuard,
        arrowDown: IconPropsGuard,
    }),
    about: createGuard('About', {
        buttonIcon: IconPropsGuard,
    }),
    pinControl: createGuard('PinControl', {
        checkmark: IconPropsGuard,
        backspace: IconPropsGuard,
    }),
    account: createGuard('Account', {
        user: IconPropsGuard,
        subscriptions: IconPropsGuard,
        parentalControl: IconPropsGuard,
        signin: IconPropsGuard,
    }),
    chromecast: createGuard('Chromecast', {
        tv: IconPropsGuard,
        volumeUp: IconPropsGuard,
        volumeOff: IconPropsGuard,
        volumeDown: IconPropsGuard,
        castConnected: IconPropsGuard,
        skipPrev: IconPropsGuard,
        related: IconPropsGuard,
        subtitles: IconPropsGuard,
        seekForward: IconPropsGuard,
        seekBackward: IconPropsGuard,
    }),

    'onButtonPrimary-locked': IconPropsGuard,
    'onButtonPrimary-play': IconPropsGuard,
    'onButtonPrimary-purchase': IconPropsGuard,
    'onButtonPrimary-purchase_multipleOptions': IconPropsGuard,
    'onButtonPrimary-record': IconPropsGuard,
    'onButtonPrimary-recordMultiple': IconPropsGuard,
    'onButtonPrimary-recordMultiple_cancel': IconPropsGuard,
    'onButtonPrimary-record_cancel': IconPropsGuard,
    'onButtonPrimary-rent': IconPropsGuard,
    'onButtonPrimary-upsell': IconPropsGuard,
    'onButtonSecondary-download': IconPropsGuard,
    'onButtonSecondary-info': IconPropsGuard,
    'onButtonSecondary-record': IconPropsGuard,
    'onButtonSecondary-recordMultiple': IconPropsGuard,
    'onButtonSecondary-recordMultiple_cancel': IconPropsGuard,
    'onButtonSecondary-record_cancel': IconPropsGuard,
    'onButtonSecondary-reminder_add': IconPropsGuard,
    'onButtonSecondary-reminder_added': IconPropsGuard,
    'onButtonSecondary-share': IconPropsGuard,
    'onButtonSecondary-startOver': IconPropsGuard,
    'onButtonSecondary-watchlist_add': IconPropsGuard,
    'onButtonSecondary-watchlist_added': IconPropsGuard,
    'onButtonTertiary-download': IconPropsGuard,
    'onButtonTertiary-info': IconPropsGuard,
    'onButtonTertiary-record': IconPropsGuard,
    'onButtonTertiary-recordMultiple': IconPropsGuard,
    'onButtonTertiary-recordMultiple_cancel': IconPropsGuard,
    'onButtonTertiary-record_cancel': IconPropsGuard,
    'onButtonTertiary-reminder_add': IconPropsGuard,
    'onButtonTertiary-reminder_added': IconPropsGuard,
    'onButtonTertiary-share': IconPropsGuard,
    'onButtonTertiary-startOver': IconPropsGuard,
    'onButtonTertiary-watchlist_add': IconPropsGuard,
    'onButtonTertiary-watchlist_added': IconPropsGuard,
    'onDark-airplay': IconPropsGuard,
    'onDark-allChannels': IconPropsGuard,
    'onDark-allEpisodes': IconPropsGuard,
    'onDark-back': IconPropsGuard,
    'onDark-backToLive': IconPropsGuard,
    'onDark-back_rtl': IconPropsGuard,
    'onDark-catchup': IconPropsGuard,
    'onDark-chromecast': IconPropsGuard,
    'onDark-chromecast_connected': IconPropsGuard,
    'onDark-close': IconPropsGuard,
    'onDark-confirm': IconPropsGuard,
    'onDark-control_fastForward': IconPropsGuard,
    'onDark-control_forward10': IconPropsGuard,
    'onDark-control_info': IconPropsGuard,
    'onDark-control_pause': IconPropsGuard,
    'onDark-control_play': IconPropsGuard,
    'onDark-control_replay10': IconPropsGuard,
    'onDark-control_rewind': IconPropsGuard,
    'onDark-control_skipNext': IconPropsGuard,
    'onDark-control_skipPrevious': IconPropsGuard,
    'onDark-control_stop': IconPropsGuard,
    'onDark-control_upsell': IconPropsGuard,
    'onDark-control_volume_down': IconPropsGuard,
    'onDark-control_volume_mute': IconPropsGuard,
    'onDark-control_volume_off': IconPropsGuard,
    'onDark-control_volume_up': IconPropsGuard,
    'onDark-filter': IconPropsGuard,
    'onDark-fullscreen': IconPropsGuard,
    'onDark-fullscreen_exit': IconPropsGuard,
    'onDark-overflow': IconPropsGuard,
    'onDark-pictureInPicture': IconPropsGuard,
    'onDark-rating': IconPropsGuard,
    'onDark-record': IconPropsGuard,
    'onDark-recordMultiple': IconPropsGuard,
    'onDark-recordMultiple_cancel': IconPropsGuard,
    'onDark-record_cancel': IconPropsGuard,
    'onDark-select': IconPropsGuard,
    'onDark-settings': IconPropsGuard,
    'onDark-share': IconPropsGuard,
    'onDark-subtitles': IconPropsGuard,
    'onDark-tvGuide': IconPropsGuard,
    'onLabel-catchup': IconPropsGuard,
    'onLabel-locked': IconPropsGuard,
    'onLabel-record': IconPropsGuard,
    'onLabel-recordMultiple': IconPropsGuard,
    'onLabel-reminder': IconPropsGuard,
    'onPrimary-airplay': IconPropsGuard,
    'onPrimary-back': IconPropsGuard,
    'onPrimary-back_rtl': IconPropsGuard,
    'onPrimary-chromecast': IconPropsGuard,
    'onPrimary-chromecast_connected': IconPropsGuard,
    'onPrimary-clear': IconPropsGuard,
    'onPrimary-close': IconPropsGuard,
    'onPrimary-confirm': IconPropsGuard,
    'onPrimary-delete': IconPropsGuard,
    'onPrimary-device_family': IconPropsGuard,
    'onPrimary-device_gameConsole': IconPropsGuard,
    'onPrimary-device_laptop': IconPropsGuard,
    'onPrimary-device_smartphone': IconPropsGuard,
    'onPrimary-device_smartwatch': IconPropsGuard,
    'onPrimary-device_tablet': IconPropsGuard,
    'onPrimary-device_tv': IconPropsGuard,
    'onPrimary-error': IconPropsGuard,
    'onPrimary-expiration': IconPropsGuard,
    'onPrimary-field_clear': IconPropsGuard,
    'onPrimary-field_search': IconPropsGuard,
    'onPrimary-filter': IconPropsGuard,
    'onPrimary-overflow': IconPropsGuard,
    'onPrimary-rating': IconPropsGuard,
    'onPrimary-scroll_down': IconPropsGuard,
    'onPrimary-scroll_up': IconPropsGuard,
    'onPrimary-seeAll': IconPropsGuard,
    'onPrimary-select': IconPropsGuard,
    'onPrimary-share': IconPropsGuard,
    'onPrimary-signOut': IconPropsGuard,
    'onPrimary-spinner': IconPropsGuard,
    'onPrimary-subpage': IconPropsGuard,
    'onPrimary-subpage_rtl': IconPropsGuard,
    'onTileMainButton-edit': IconPropsGuard,
    'onTileMainButton-play': IconPropsGuard,
});

export type MergedDefaultIcons = t.TypeOf<typeof MergedDefaultIconsGuard>;

export const IconGuard = createGuard(
    'Icon',
    {
        iconFont: t.string,
        iconName: t.string,
    },
    { iconImage: t.string, height: t.number, width: t.number }
);

export type Icon = t.TypeOf<typeof IconGuard>;

export const OtherIconsGuard = createGuard('OtherIcons', t.record(t.string, IconGuard));

export const IconsGuard = createGuard('Icons', t.union([MergedDefaultIconsGuard, OtherIconsGuard]));

export type Icons = t.TypeOf<typeof IconsGuard>;

export const ThemeGuard = createGuard('Theme', {
    id: t.string,
    name: t.string,
    color: ColorsGuard,
    fonts: FontsGuard,
    icons: MergedDefaultIconsGuard,
    elements: ElementsGuard,
    logo: nullable(t.union([t.string, t.number])),
    backgroundImage: nullable(t.string),
    splashBackgroundImage: nullable(t.string),
});

// const IconsGuardIntersection = t.intersection([DefaultIconsGuard, OtherIconsGuard]);
export type Theme = t.TypeOf<typeof ThemeGuard> & {
    icons: t.TypeOf<typeof OtherIconsGuard> & t.TypeOf<typeof MergedDefaultIconsGuard>;
};

export const isTheme = (data: Record<string, unknown>): data is Theme => {
    try {
        return !!decodeModelWith(ThemeGuard, data, 'Theme', { disableErrorLog: true });
    } catch {
        return false;
    }
};

export const createTheme = (data: Record<string, unknown>): Theme | never => {
    return decodeModelWith(ThemeGuard, data, 'Theme') as Theme;
};

export const isThemeMenuIcon = (
    iconName: string | undefined | null,
    theme: Theme
): iconName is keyof typeof theme.icons.menu.iconsMap => {
    if (!iconName) return false;

    return Object.keys(theme.icons.menu.iconsMap).includes(iconName);
};

export const isThemeMenuImage = (
    data: Record<string, unknown>
): data is t.TypeOf<typeof IconMapImageGuard> => {
    try {
        return !!decodeModelWith(IconMapImageGuard, data, 'IconMapImage', {
            disableErrorLog: true,
        });
    } catch {
        return false;
    }
};
