import _defineProperty from"@babel/runtime/helpers/defineProperty";function ownKeys(object,enumerableOnly){var keys=Object.keys(object);if(Object.getOwnPropertySymbols){var symbols=Object.getOwnPropertySymbols(object);enumerableOnly&&(symbols=symbols.filter(function(sym){return Object.getOwnPropertyDescriptor(object,sym).enumerable;})),keys.push.apply(keys,symbols);}return keys;}function _objectSpread(target){for(var i=1;i<arguments.length;i++){var source=null!=arguments[i]?arguments[i]:{};i%2?ownKeys(Object(source),!0).forEach(function(key){_defineProperty(target,key,source[key]);}):Object.getOwnPropertyDescriptors?Object.defineProperties(target,Object.getOwnPropertyDescriptors(source)):ownKeys(Object(source)).forEach(function(key){Object.defineProperty(target,key,Object.getOwnPropertyDescriptor(source,key));});}return target;}



import{OpenModalLink}from'@24i/nxg-sdk-smartott/src/navigation/components/TopBarMenu/components/OpenModalLink/index.web';
import{SOTT_DEFAULT_SCREEN_CONFIG_BUILDERS}from'@24i/nxg-sdk-smartott/src/navigation/navigationConfig/screenConfigBuilders';
import React from'react';




import TopBarPrimary from'@24i/nxg-sdk-gluons/src/components/navigation/TopBarPrimary';
import{isFactorMobile}from'renative';
import SigninScreen from"../screens/SigninScreen";
import SettingsScreen from"../screens/SettingsScreen";
import ServiceScreen from"../components/Settings/SettingsService";
import MvpdSignInScreen from"../screens/MvpdSignInScreen";

var header=function header(headerProps){return React.createElement(TopBarPrimary,headerProps);};

export var CUSTOM_SCREEN_BUILDERS=_objectSpread(_objectSpread({},
SOTT_DEFAULT_SCREEN_CONFIG_BUILDERS),{},{
buildSignInScreen:function buildSignInScreen(menuItem,context){
var originalBuild=SOTT_DEFAULT_SCREEN_CONFIG_BUILDERS.buildSignInScreen(
menuItem,
context
);

var _context$features=context==null?void 0:context.features,adobePrimetime=_context$features.adobePrimetime;
var adobePrimetimeEnabled=adobePrimetime==null?void 0:adobePrimetime.enabled;

return _objectSpread(_objectSpread({},
originalBuild),{},{
nativeRenderOptions:{
Component:SigninScreen
},
renderWebLink:function renderWebLink(_ref){var linkMenuItem=_ref.menuItem;return(
React.createElement(OpenModalLink,{
menuItem:linkMenuItem,

ModalComponent:
adobePrimetimeEnabled?
MvpdSignInScreen:
SigninScreen}

));}});


},
buildSettingsScreen:function buildSettingsScreen(menuItem,context){
var originalBuild=SOTT_DEFAULT_SCREEN_CONFIG_BUILDERS.buildSettingsScreen(
menuItem,
context
);

return _objectSpread(_objectSpread({},
originalBuild),{},{
nativeRenderOptions:{
Component:SettingsScreen
}});

},
buildServiceScreen:function buildServiceScreen(menuItem,context){
var originalBuild=SOTT_DEFAULT_SCREEN_CONFIG_BUILDERS.buildServiceScreen(
menuItem,
context
);

return _objectSpread(_objectSpread({},
originalBuild),{},{
nativeRenderOptions:{
Component:ServiceScreen
},
options:{
header:header,
headerShown:isFactorMobile
}});

}});