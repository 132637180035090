/* eslint-disable max-classes-per-file */
import { isRight } from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import { PathReporter } from 'io-ts/lib/PathReporter';

export class ModelTypeGuardError extends Error {
    errors: string[] = [];

    constructor(pathErrors: string[], name: string, modelName: string) {
        super();

        this.message = `Connot create '${modelName}'. Data are in wrong shape. Responsible guard is '${name}Guard'. \n\n Details: \n ${pathErrors.join(
            '\n\n'
        )}`;
        this.name = 'ModelTypeGuardError';
        this.errors = pathErrors;
    }
}

export const ApiGuardTypeErrorName = 'ApiTypeGuardError';

export class ApiTypeGuardError<T> extends Error {
    errors: string[] = [];

    rejectedPayload: T;

    constructor(pathErrors: string[], name: string, path: string, payload: T) {
        super();

        this.message = `Api response from '${path}' has a wrong shape. Responsible guard is '${name}Guard'. \n\n Details: \n ${pathErrors.join(
            '\n\n'
        )}`;
        this.name = ApiGuardTypeErrorName;
        this.errors = pathErrors;
        this.rejectedPayload = payload;
    }
}

export const decodeModelWith = <DecodeFrom, EncodeTo>(
    codec: t.Type<EncodeTo, EncodeTo, DecodeFrom>,
    data: DecodeFrom,
    modelname: string,
    options?: { disableErrorLog: boolean }
): t.TypeOf<typeof codec> => {
    const guardResult = codec.decode(data);

    if (isRight(guardResult)) {
        return guardResult.right;
    }

    if (__DEV__ && !options?.disableErrorLog) {
        console.error('Model errors: ', data, modelname, PathReporter.report(guardResult));
    }

    throw new ModelTypeGuardError(PathReporter.report(guardResult), codec.name, modelname);
};

export const decodeApiResponseWith = <DecodeFrom, EncodeTo>(
    codec: t.Type<EncodeTo, EncodeTo, DecodeFrom>,
    data: DecodeFrom,
    path: string
): t.TypeOf<typeof codec> => {
    const guardResult = codec.decode(data);

    if (isRight(guardResult)) {
        return guardResult.right;
    }

    throw new ApiTypeGuardError(PathReporter.report(guardResult), codec.name, path, data);
};

export const nullable = <T extends t.Mixed>(type: T) => t.union([type, t.null, t.undefined]);
export const optional = <T extends t.Mixed>(type: T) => t.union([type, t.undefined]);
export const withEmptyArray = <T extends t.Mixed>(type: T) => t.union([type, t.array(t.void)]);

// eslint-disable-next-line @typescript-eslint/ban-types
export const createEnum = <E>(obj: object, name: string): t.Type<E> => {
    const keys = {};

    Object.keys(obj).forEach((k) => {
        keys[obj[k]] = null;
    });

    return t.keyof(keys, name) as any;
};

/**
 * Use if you want to create a "dummy" type guard only to force TS to put the proper type in its place.
 *
 * !!! Careful, use very scarcely
 */
export const createTypeWithNoValidation = <TTypeToRepresent, TViableInput = unknown>(
    name: string
) => {
    return new t.Type<TTypeToRepresent, TViableInput>(
        name,
        (toCheck): toCheck is TTypeToRepresent => true,
        (toDecode) => t.success(toDecode as TTypeToRepresent),
        (toEncode) => toEncode as unknown as TViableInput
    );
};

export function createGuard<R extends t.Props, P extends t.Props>(
    name: string,
    required: R,
    partial: P
): t.IntersectionC<[t.TypeC<R>, t.PartialC<P>]>;

export function createGuard<R extends t.Props>(name: string, required: R): t.TypeC<R>;

export function createGuard<R extends t.Mixed>(name: string, mixed: R): R;

export function createGuard<R extends null | undefined, P extends t.Props>(
    name: string,
    required: R,
    partial: P
): t.PartialC<P>;

export function createGuard<R extends t.Props | t.Mixed, P extends t.Props | t.Mixed>(
    name: string,
    required?: R,
    partial?: P
) {
    let requiredImpl: t.Mixed | t.TypeC<t.Props> | undefined;
    if (required?.decode && typeof required.decode === 'function') {
        requiredImpl = required as t.Mixed;
    } else if (required) {
        requiredImpl = t.type(required as t.Props, name);
    }

    if (!partial && requiredImpl) {
        return requiredImpl;
    }

    let partialImpl: t.Mixed | t.PartialC<t.Props> | undefined;
    if (partial?.decode && typeof partial.decode === 'function') {
        partialImpl = partial as t.Mixed;
    } else if (partial) {
        partialImpl = t.partial(partial as t.Props, name);
    }

    if (!required && partialImpl) {
        return partialImpl;
    }

    if (requiredImpl && partialImpl) {
        return t.intersection([requiredImpl, partialImpl], name);
    }

    throw new Error('Invalid guard configuration');
}

export const extendGuard = <Guard extends t.Mixed, R extends t.Props, P extends t.Props>(
    name: string,
    guard: Guard,
    required: R,
    partial: P
) => t.intersection([guard, t.type<R>(required), t.partial<P>(partial)], name);
