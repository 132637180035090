import { BaseApiParams } from '@24i/nxg-core-utils/src/api';
import { Theme, ThemeDataClient } from '@24i/nxg-sdk-photon';
import { Storage } from '@24i/nxg-sdk-quantum';
import { BackstageApiBase } from '../../base';
import { replaceEmptyArraysWithEmptyObjects } from '../../utils';
import { CustomIconsResponseGuard } from './customIconsResponseGuard';
import { mapThemeResponse } from './mappers';
import { ThemeResponseGuard } from './themeResponseGuard';
import { CustomIcons } from './types';
import backstageDefaultTheme, { DefaultTheme } from './backstageDefaultTheme';

const SERVICE_THEME_ID = 'SERVICE_THEME_ID';

export class BackstageThemeDataClient extends BackstageApiBase implements ThemeDataClient {
    defaultTheme: DefaultTheme;

    constructor(params: BaseApiParams) {
        super(params);
        this.defaultTheme = backstageDefaultTheme;
    }

    fetchTheme = async (): Promise<Theme> => {
        const themeResponse = await this.request({
            path: '/themes',
            method: 'GET',
            guard: ThemeResponseGuard,
        });

        const customIcons = await this.request({
            path: '/icons/custom',
            method: 'GET',
            guard: CustomIconsResponseGuard,
        }).then(replaceEmptyArraysWithEmptyObjects<CustomIcons>(['icons']));

        const themeId = await Storage.getItem(SERVICE_THEME_ID);

        const selectedTheme =
            themeResponse.items?.find((item) => item.id === themeId) || themeResponse.items?.[0];
        return mapThemeResponse(selectedTheme, this.defaultTheme, customIcons);
    };

    fetchAllThemes = async () => {
        const themeResponse = await this.request({
            path: '/themes',
            method: 'GET',
            guard: ThemeResponseGuard,
        });

        const customIcons = await this.request({
            path: '/icons/custom',
            method: 'GET',
            guard: CustomIconsResponseGuard,
        }).then(replaceEmptyArraysWithEmptyObjects<CustomIcons>(['icons']));

        const allThemes = themeResponse.items.map((theme) =>
            mapThemeResponse(theme, this.defaultTheme, customIcons)
        );

        return allThemes;
    };

    selectTheme = (selectedThemeId: string) => Storage.setItem(SERVICE_THEME_ID, selectedThemeId);

    resetSelectedTheme = () => Storage.removeItem(SERVICE_THEME_ID);
}

export const createBackstageThemeDataClient = (params: BaseApiParams) => {
    return new BackstageThemeDataClient(params);
};
