/* eslint-disable react/no-danger */
import React from 'react';
import Document, { Html, Head, Main, NextScript } from 'next/document';
import getConfig from 'next/config';
// import flush from 'styled-jsx/server'; Not possible to use in V5 https://github.com/vercel/styled-jsx/blob/main/Changelog.md#apis
import { flush as rnmq } from 'react-native-media-query';
import { AppRegistry } from 'react-native-web';
import { globalStyles } from '@24i/nxg-sdk-smartott/src/theme/styles/global';

const toFontFace = ({ name }) => `@font-face {
    font-family: '${name.split('.')[0]}';
    src: url('/fonts/${name}');
    font-display: swap;
}`;

const toFontLinks = (acc, { name, shouldPreload }) => {
    if (!shouldPreload) return acc;
    return [
        ...acc,
        <link key={name} rel="preload" href={`/fonts/${name}`} as="font" crossOrigin="" />,
    ];
};

const { publicRuntimeConfig } = getConfig();
const { customScripts = [] } = publicRuntimeConfig;

export const getInitialProps = ({ renderPage }) => {
    AppRegistry.registerComponent('Main', () => Main);
    const { getStyleElement } = AppRegistry.getApplication('Main');
    const { html, head } = renderPage();

    const styles = [getStyleElement(), /* flush(), */ rnmq()];
    return { html, head, styles: React.Children.toArray(styles) };
};

class MyDocument extends Document {
    render() {
        const description =
            this.props.metaDescription ||
            'Watch movies and TV shows online or stream right to your smart TV, PC, Mac, mobile, tablet and more.';

        return (
            <Html lang="en">
                <Head>
                    <meta name="description" content={description} />
                    {Boolean(customScripts.map) &&
                        customScripts.map((url, index) => (
                            <script src={url} key={`script-key-${index.toString()}`} async />
                        ))}
                    <link rel="shortcut icon" href="/icons/favicon.ico" />
                    {this.props.fonts.reduce(toFontLinks, [])}
                    <style
                        dangerouslySetInnerHTML={{
                            __html: `${this.props.fonts.map(toFontFace).join('\n')}`,
                        }}
                    />
                    <style dangerouslySetInnerHTML={{ __html: globalStyles }} />
                </Head>
                <body>
                    <Main />
                    <NextScript />
                </body>
            </Html>
        );
    }
}

export default MyDocument;
