import { createEnum, decodeModelWith, extendGuard, nullable } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';
import { ProductGuard } from './product';

export enum SubscriptionStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    CANCELLED = 'cancelled',
}

export const SubscriptionGuard = extendGuard(
    'Subscription',
    ProductGuard,
    {
        type: t.literal('subscription'),
    },
    {
        period: nullable(t.string),
        status: nullable(createEnum<SubscriptionStatus>(SubscriptionStatus, 'SubscriptionStatus')),
        nextBillingDate: nullable(t.number),
        wasCancelled: nullable(t.boolean),
        isWebPayment: nullable(t.boolean),
        paymentMethod: nullable(t.string),
    }
);

export type Subscription = t.TypeOf<typeof SubscriptionGuard>;

export const isSubscription = (data: unknown): data is Subscription => {
    try {
        return !!decodeModelWith(SubscriptionGuard, data, 'Subscription', {
            disableErrorLog: true,
        });
    } catch {
        return false;
    }
};

export const createSubscription = (data: unknown): Subscription | never => {
    return decodeModelWith(SubscriptionGuard, data, 'Subscription');
};
