import { isPlatformWeb } from 'renative';
import defaultRuntimeConfig from './configs/runtime';
import {
    i18nSottDefault as defaultLocale,
    sottTranslations as defaultTranslations,
} from './configs/locale';

import fallbackLogo from '../assets/fallback_logo.png';
import fallbackViewChannel from '../assets/fallback_view_channel.png';
import fallbackViewTime from '../assets/fallback_view_time.png';

const logo = isPlatformWeb ? '/images/logo_full.png' : fallbackLogo;

export { initFullApp } from './initApp';
export { ImageServiceClient } from './clients/ImageServiceClient';
export { LayoutClient } from './clients/LayoutClient';
export { createSottNavigationClient } from './clients/AppNavigationClient';
export { createSentryMonitoringDataClient } from './clients/SentryMonitoringDataClient';
export { createDataPlatformClient } from './clients/DataPlatformClient';
export { createGoogleAnalyticsClient } from './clients/GoogleAnalyticsClient';
export { createTwentyFourIQClient } from './clients/TwentyFourIQClient';
export * from './clients/DefaultSDKClients';
export * from './clients/DefaultSDKClients/types';
export * from './clients/DefaultTvSDKClient';

export {
    defaultRuntimeConfig,
    defaultLocale,
    defaultTranslations,
    logo,
    fallbackViewChannel,
    fallbackViewTime,
};
