import _createClass from"@babel/runtime/helpers/createClass";import _classCallCheck from"@babel/runtime/helpers/classCallCheck";import _inherits from"@babel/runtime/helpers/inherits";import _possibleConstructorReturn from"@babel/runtime/helpers/possibleConstructorReturn";import _getPrototypeOf from"@babel/runtime/helpers/getPrototypeOf";function _createSuper(Derived){var hasNativeReflectConstruct=_isNativeReflectConstruct();return function _createSuperInternal(){var Super=_getPrototypeOf(Derived),result;if(hasNativeReflectConstruct){var NewTarget=_getPrototypeOf(this).constructor;result=Reflect.construct(Super,arguments,NewTarget);}else{result=Super.apply(this,arguments);}return _possibleConstructorReturn(this,result);};}function _isNativeReflectConstruct(){if(typeof Reflect==="undefined"||!Reflect.construct)return false;if(Reflect.construct.sham)return false;if(typeof Proxy==="function")return true;try{Boolean.prototype.valueOf.call(Reflect.construct(Boolean,[],function(){}));return true;}catch(e){return false;}}import _regeneratorRuntime from"@babel/runtime/regenerator";import{DeviceInfo,Storage}from'@24i/nxg-sdk-quantum';

import{BackstageUserDataClient,BASE_BACKSTAGE_URI}from'@24i/nxg-integration-backstage';
import{ASYNC_STORAGE_KEY_API_USER_TOKEN}from'@24i/nxg-core-utils/src/constants';

import{LoginResponseGuard}from"./guards";

var DeviceType=DeviceInfo.getDeviceType().toString();

var getApiUserToken=function getApiUserToken(){return _regeneratorRuntime.async(function getApiUserToken$(_context){while(1)switch(_context.prev=_context.next){case 0:return _context.abrupt("return",Storage.getItem(ASYNC_STORAGE_KEY_API_USER_TOKEN));case 1:case"end":return _context.stop();}},null,null,null,Promise);};
var setApiUserToken=function setApiUserToken(token){return _regeneratorRuntime.async(function setApiUserToken$(_context2){while(1)switch(_context2.prev=_context2.next){case 0:return _context2.abrupt("return",
Storage.setItem(ASYNC_STORAGE_KEY_API_USER_TOKEN,token));case 1:case"end":return _context2.stop();}},null,null,null,Promise);};
var removeApiUserToken=function removeApiUserToken(){return _regeneratorRuntime.async(function removeApiUserToken$(_context3){while(1)switch(_context3.prev=_context3.next){case 0:return _context3.abrupt("return",Storage.removeItem(ASYNC_STORAGE_KEY_API_USER_TOKEN));case 1:case"end":return _context3.stop();}},null,null,null,Promise);};

export var ExperienceUserDataClient=function(_BackstageUserDataCli){_inherits(ExperienceUserDataClient,_BackstageUserDataCli);var _super=_createSuper(ExperienceUserDataClient);function ExperienceUserDataClient(){var _this;_classCallCheck(this,ExperienceUserDataClient);for(var _len=arguments.length,args=new Array(_len),_key=0;_key<_len;_key++){args[_key]=arguments[_key];}_this=_super.call.apply(_super,[this].concat(args));_this.
loginWithBackstage=function _callee(username,password){var tokenData;return _regeneratorRuntime.async(function _callee$(_context4){while(1)switch(_context4.prev=_context4.next){case 0:_context4.next=2;return _regeneratorRuntime.awrap(
_this.request({
method:'POST',
baseUri:BASE_BACKSTAGE_URI,
path:'/login/token',
body:{
username:username,
password:password,
device_name:DeviceType
},
guard:LoginResponseGuard
}));case 2:tokenData=_context4.sent;_context4.next=5;return _regeneratorRuntime.awrap(

setApiUserToken(tokenData.token));case 5:return _context4.abrupt("return",

tokenData);case 6:case"end":return _context4.stop();}},null,null,null,Promise);};_this.


logoutWithBackstage=function(){return removeApiUserToken();};_this.

fetchBackstageUser=function _callee2(){var token;return _regeneratorRuntime.async(function _callee2$(_context5){while(1)switch(_context5.prev=_context5.next){case 0:_context5.next=2;return _regeneratorRuntime.awrap(
getApiUserToken());case 2:token=_context5.sent;if(

token){_context5.next=5;break;}return _context5.abrupt("return",
null);case 5:return _context5.abrupt("return",


_this.request({
method:'GET',
baseUri:BASE_BACKSTAGE_URI,
path:'/user/me',
token:token,
headers:{
'X-Service-ID':null
}
}));case 6:case"end":return _context5.stop();}},null,null,null,Promise);};_this.


getBackstageOrganisationListForUser=function _callee3(){var token;return _regeneratorRuntime.async(function _callee3$(_context6){while(1)switch(_context6.prev=_context6.next){case 0:_context6.next=2;return _regeneratorRuntime.awrap(
getApiUserToken());case 2:token=_context6.sent;if(

token){_context6.next=5;break;}return _context6.abrupt("return",
null);case 5:return _context6.abrupt("return",


_this.request({
method:'GET',
baseUri:BASE_BACKSTAGE_URI,
path:'/organisations',
token:token,
headers:{
'X-Service-ID':null
}
}));case 6:case"end":return _context6.stop();}},null,null,null,Promise);};_this.


getBackstageServicesForUser=function _callee4(organisationId){return _regeneratorRuntime.async(function _callee4$(_context7){while(1)switch(_context7.prev=_context7.next){case 0:_context7.t0=_this;_context7.t1=


BASE_BACKSTAGE_URI;_context7.next=4;return _regeneratorRuntime.awrap(

getApiUserToken());case 4:_context7.t2=_context7.sent;_context7.t3=
{
'X-Organisation-ID':organisationId,
'X-Service-ID':null
};_context7.t4={method:'GET',baseUri:_context7.t1,path:'/service',token:_context7.t2,headers:_context7.t3};return _context7.abrupt("return",_context7.t0.request.call(_context7.t0,_context7.t4));case 8:case"end":return _context7.stop();}},null,null,null,Promise);};_this.



getBackstageApplicationsForService=function _callee5(
organisationId,
serviceId){return _regeneratorRuntime.async(function _callee5$(_context8){while(1)switch(_context8.prev=_context8.next){case 0:_context8.t0=_this;_context8.t1=



BASE_BACKSTAGE_URI;_context8.t2="/service/"+
serviceId+"/application";_context8.next=5;return _regeneratorRuntime.awrap(
getApiUserToken());case 5:_context8.t3=_context8.sent;_context8.t4=
{
'X-Organisation-ID':organisationId,
'X-Service-ID':null
};_context8.t5={method:'GET',baseUri:_context8.t1,path:_context8.t2,token:_context8.t3,headers:_context8.t4};return _context8.abrupt("return",_context8.t0.request.call(_context8.t0,_context8.t5));case 9:case"end":return _context8.stop();}},null,null,null,Promise);};return _this;}return _createClass(ExperienceUserDataClient);}(BackstageUserDataClient);




export var createExperienceUserDataClient=function createExperienceUserDataClient(params){
return new ExperienceUserDataClient(params);
};