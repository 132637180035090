import { RecordingsClient } from '@24i/nxg-sdk-photon/src/clients/RecordingsClient';
import { ContentDataClient } from '@24i/nxg-sdk-photon/src/clients/ContentDataClient';
import { Storage } from '@24i/nxg-sdk-quantum';
import {
    Asset,
    Broadcast,
    createRecording,
    createRecordingsFolder,
    Recording,
    RecordingsFolder,
    RECORDING_STATUS,
    StorageInfo,
    ASSET_TYPE,
    Channel,
    DecodedToken,
} from '@24i/nxg-sdk-photon';
import { delay, decodedToken } from '@24i/nxg-core-utils';
import { ASYNC_STORAGE_KEY_USER_TOKEN } from '@24i/nxg-core-utils/src/constants';
import { EpgDataClientStub } from '../EpgDataClient';
import { chance, getRandomInt } from '../../utils';
import { RecordingsMap, FolderMap, RecordingsStorage } from './types';

const STORAGE_KEY = 'recordings-stub-data';
let currentProfileId: string | undefined;
let storage: RecordingsStorage = {};

const getInitialRecordingStatus = (startTime, endTime): RECORDING_STATUS => {
    if (endTime < Date.now()) return RECORDING_STATUS.COMPLETED;
    if (startTime <= Date.now()) return RECORDING_STATUS.PARTIAL;
    return RECORDING_STATUS.SCHEDULED;
};

const updateRecordingStatus = (recording: Recording): Recording => {
    return {
        ...recording,
        status: getInitialRecordingStatus(recording.startTime, recording.endTime),
    };
};

const createNewRecording = (broadcast: Broadcast) => {
    // recordings usually are planned with some offset to prevent start and end
    // of the program to be cutoff. This should be normaly handled by the backend.
    const recordingStartMargin = 60 * 1000;
    const recordingEndMargin = 90 * 1000;
    const expirationTime = 2 * 60 * 60 * 1000;

    const mockRecordingModel: Recording = createRecording({
        status: getInitialRecordingStatus(broadcast.start, broadcast.end),
        id: getRandomInt(0, 10000000000).toString(),
        startTime: Math.max((broadcast.start || 0) - recordingStartMargin, 0),
        endTime: (broadcast.end || 0) + recordingEndMargin,
        assetId: broadcast.id,
        channelId: broadcast.channelId,
        expirationTime: Date.now() + expirationTime,
        profileId: currentProfileId,
    });
    return mockRecordingModel;
};

const createNewRecordingFromEpgData = ({
    start,
    end,
    id,
    channelId,
    seriesId,
    series,
}: Broadcast) => {
    const recordingStartMargin = 60 * 1000;
    const recordingEndMargin = 90 * 1000;
    const expirationTime = 2 * 60 * 60 * 1000;
    const mockRecordingModel: Recording = createRecording({
        status: getInitialRecordingStatus(start, end),
        id: getRandomInt(0, 10000000000).toString(),
        startTime: Math.max((start || 0) - recordingStartMargin, 0),
        endTime: (end || 0) + recordingEndMargin,
        assetId: id,
        channelId,
        seriesId: seriesId || series,
        expirationTime: Date.now() + expirationTime,
        profileId: currentProfileId,
    });
    return mockRecordingModel;
};

const loadUserDataFromStorage = async (): Promise<DecodedToken | undefined> => {
    try {
        const userToken = await Storage.getItem(ASYNC_STORAGE_KEY_USER_TOKEN);
        return userToken && decodedToken(userToken);
    } catch (e) {
        return undefined;
    }
};

const loadDataFromStorage = async () => {
    try {
        const result = await Storage.getItem(STORAGE_KEY);
        const userData = await loadUserDataFromStorage();

        if (result && result.accountId === userData?.id) storage = JSON.parse(result);
        // eslint-disable-next-line no-empty
    } catch (e) {}
};

loadDataFromStorage();

export class StubRecordingClient implements RecordingsClient {
    recordingsMap: RecordingsMap = { ...storage?.recordingsMap } || {};

    folderMap: FolderMap = { ...storage?.folderMap } || {};

    storageInfo: StorageInfo = {
        totalTimeAvailable: 12 * 60 * 60 * 1000,
        plannedTime: 0,
        usedTime: 0,
    };

    contentDataClient: ContentDataClient | undefined;

    shouldThrowErrors: boolean;

    shouldFillTestData = true;

    constructor({ contentDataClient, shouldThrowErrors = __DEV__ }) {
        this.shouldThrowErrors = shouldThrowErrors;
        this.contentDataClient = contentDataClient;
    }

    saveStorage = async () => {
        if (!__DEV__) return;
        const accountId = await this.getAccountId();
        await Storage.setItem(
            STORAGE_KEY,
            JSON.stringify({
                accountId,
                recordingsMap: this.recordingsMap,
                folderMap: this.folderMap,
            })
        );
    };

    fillWithInitialData = async () => {
        const epgClientStub = new EpgDataClientStub();
        const data: Channel[] = await epgClientStub.getEpgData(0, Infinity);
        this.addEpgDataToRecordings(data[2].programs?.[7]);
        this.addEpgDataToRecordings(data[3].programs?.[9]);
        this.addEpgDataToRecordings(data[2].programs?.[2]);
        this.addEpgDataToRecordings(data[2].programs?.[14]);
        if (data[0].programs?.[2])
            await this.createSeriesRecordingForBroadcast(data[0].programs?.[2], false, true);
    };

    addEpgDataToRecordings = (program?: Broadcast) => {
        if (!program) return 0;
        const rec = createNewRecordingFromEpgData(program);
        this.recordingsMap[rec.id] = rec;
        return rec.id;
    };

    fakeBackend = async () => {
        await delay(getRandomInt(500, 1000));
        if (chance(10) && this.shouldThrowErrors) throw new Error('A00');
    };

    fakeRecordingDelay = async () => {
        await delay(getRandomInt(0, 20000));
    };

    getProfileId = async (): Promise<string | undefined> => {
        const userData = await loadUserDataFromStorage();
        return userData?.profileId;
    };

    getAccountId = async (): Promise<string | number | undefined> => {
        const userData = await loadUserDataFromStorage();
        return userData?.id;
    };

    possiblyFillTestData = async (): Promise<void> => {
        currentProfileId = await this.getProfileId();
        if (!Object.keys(this.recordingsMap).length && this.shouldFillTestData) {
            await this.fillWithInitialData();
        }
        this.shouldFillTestData = false;
    };

    fetchAllRecordings = async (): Promise<(Recording | RecordingsFolder)[]> => {
        await this.fakeBackend();
        await this.possiblyFillTestData();
        const filteredByProfile = Object.values(this.recordingsMap).filter(
            (rec) => rec.profileId === currentProfileId
        );
        const recordingsMap: RecordingsMap = filteredByProfile.reduce(
            // eslint-disable-next-line no-return-assign, no-param-reassign, no-sequences
            (obj, item) => ((obj[item.id] = item), obj),
            {}
        );
        const folders = Object.values(this.folderMap);
        const splitFolders = folders.reduce(
            (acc, folder) => [...acc, ...this.splitFolder(folder, recordingsMap)],
            [] as RecordingsFolder[]
        );
        folders.forEach((folder) => folder.recordingIds.forEach((id) => delete recordingsMap[id]));
        return [
            ...splitFolders.filter((folder) => folder.recordingIds),
            ...Object.values(recordingsMap),
        ].filter(Boolean);
    };

    splitFolder = (folder: RecordingsFolder, recordingsMap: Record<string, Recording>) => {
        const recordingsInFolder = folder.recordingIds
            .map((id) => recordingsMap[id])
            .filter(Boolean);
        const completedRecordings = recordingsInFolder.filter(
            (r) => getInitialRecordingStatus(r.startTime, r.endTime) === RECORDING_STATUS.COMPLETED
        );
        const plannedRecordings = recordingsInFolder.filter(
            (r) => getInitialRecordingStatus(r.startTime, r.endTime) !== RECORDING_STATUS.COMPLETED
        );
        const completedFolder: RecordingsFolder = {
            ...folder,
            id: `${folder.id}_completed`,
            status: RECORDING_STATUS.COMPLETED,
            recordingIds: completedRecordings.map((recording) => recording.id),
            totalDuration: completedRecordings.reduce(
                (total, currentItem) => total + currentItem.endTime - currentItem.startTime,
                0
            ),
        };
        const plannedFolder: RecordingsFolder = {
            ...folder,
            id: `${folder.id}_planned`,
            status: RECORDING_STATUS.SCHEDULED,
            recordingIds: plannedRecordings.map((recording) => recording.id),
            totalDuration: plannedRecordings.reduce(
                (total, currentItem) => total + currentItem.endTime - currentItem.startTime,
                0
            ),
        };

        return [plannedFolder, completedFolder].filter(
            (splitFolder) => splitFolder.recordingIds.length
        );
    };

    fetchRecordingForBroadcast = async (broadcast: Broadcast): Promise<Recording | undefined> => {
        await this.fakeBackend();
        await this.possiblyFillTestData();
        const result = Object.values(this.recordingsMap).find(
            (recording) =>
                recording.assetId === broadcast.id && recording.profileId === currentProfileId
        );
        return result && updateRecordingStatus(result);
    };

    fetchRecordingById = async (id: string) => {
        return updateRecordingStatus(this.recordingsMap[id]);
    };

    createRecordingForBroadcast = async (broadcast: Broadcast): Promise<void> => {
        await this.possiblyFillTestData();
        // there is sometines a delay between recording request
        // returning and actual recording being added to recording queue
        // this is here to simulate that delay
        const mockRecordingModel = createNewRecording(broadcast);
        const queueInsert = async () => {
            // turning the delay off for now as it might be an overkill
            // await this.fakeRecordingDelay();
            this.recordingsMap[mockRecordingModel.id] = mockRecordingModel;
            await this.saveStorage();
        };
        await this.fakeBackend();
        this.checkCapacity([mockRecordingModel]);
        queueInsert();
    };

    deleteRecordingForBroadcast = async (asset: Broadcast): Promise<void> => {
        await this.fakeBackend();
        const recording = Object.values(this.recordingsMap).find((r) => r.assetId === asset.id);
        if (recording) {
            delete this.recordingsMap[recording.id];
            await this.saveStorage();
        } else {
            throw new Error('F07');
        }
    };

    createSeriesRecordingForBroadcast = async (
        broadcast: Broadcast,
        fakeBackend = true,
        save = true
    ): Promise<void> => {
        if (fakeBackend) {
            await this.fakeBackend();
        }
        await this.possiblyFillTestData();
        const epgClientStub = new EpgDataClientStub();
        const data: Channel[] = await epgClientStub.getEpgData(
            0,
            Infinity,
            new Date(broadcast.start)
        );

        const array = data.reduce(
            (acc, item) => [...acc, ...(item.programs || [])],
            [] as Broadcast[]
        );
        const series = array.filter((program) => {
            const serieId = broadcast.series || broadcast.seriesId;
            return serieId && (program.series === serieId || program.seriesId === serieId);
        });

        const allAfterAndIncludingThis = series.filter((program) => {
            const broadcastStart = broadcast.start;
            return program.start >= broadcastStart;
        });

        const recordings = allAfterAndIncludingThis.map(createNewRecordingFromEpgData);
        this.checkCapacity(recordings);

        const recordingIds = recordings.map((rec) => {
            this.recordingsMap[rec.id] = rec;
            return rec.id;
        });
        const folderId = getRandomInt(0, 10000000000).toString();

        const seriesAsset = (await (fakeBackend
            ? this.contentDataClient
                  ?.fetchAsset({
                      id: broadcast.series || broadcast.seriesId || '',
                      type: ASSET_TYPE.SERIES,
                  })
                  .catch((e) => console.log(e))
            : undefined)) as Asset;

        // this would be handled on backend with real service
        this.folderMap[folderId] = createRecordingsFolder({
            recordingIds,
            id: folderId,
            status: RECORDING_STATUS.COMPLETED,
            title: seriesAsset?.title || 'Sherlock',
            still: broadcast.images?.still?.[0].url || broadcast.still,
            profileId: currentProfileId,
            channelLogo: allAfterAndIncludingThis[0]?.channelLogo,
        });
        if (save) await this.saveStorage();
    };

    cancelRecordingsForSeries = async (asset: Broadcast) => {
        await this.fakeBackend();
        Object.values(this.recordingsMap).forEach((recording) => {
            if (
                recording.seriesId === asset.series &&
                recording.status !== RECORDING_STATUS.COMPLETED
            ) {
                delete this.recordingsMap[recording.id];
            }
        });
        await this.saveStorage();
    };

    deleteRecordingsForSeries = async (seriesId: string) => {
        await this.fakeBackend();
        Object.values(this.recordingsMap).forEach((recording) => {
            if (recording.seriesId === seriesId) {
                delete this.recordingsMap[recording.id];
            }
        });
        await this.saveStorage();
    };

    deleteRecordingsInFolder = async (folder: RecordingsFolder) => {
        await this.fakeBackend();

        if (folder) {
            folder.recordingIds.forEach((id) => delete this.recordingsMap[id]);
            delete this.folderMap[folder.id];
            await this.saveStorage();
        } else {
            throw new Error('F07');
        }
    };

    getStoragetInfo = (): StorageInfo => {
        const getDurationForRecording = (recording: Recording) =>
            recording.endTime - recording.startTime;
        const calculateTotalTimeForRecordings = (recordings: Recording[]) => {
            return recordings.reduce((acc, item) => acc + getDurationForRecording(item), 0);
        };
        const plannedRecordings = Object.values(this.recordingsMap).filter(
            (recording) => recording.status === RECORDING_STATUS.SCHEDULED
        );
        const completedRecordings = Object.values(this.recordingsMap).filter(
            (recording) => recording.status !== RECORDING_STATUS.SCHEDULED
        );
        // 40h of total time
        const totalTimeAvailable = 40 * 60 * 60 * 1000;

        return {
            totalTimeAvailable,
            usedTime: calculateTotalTimeForRecordings(completedRecordings),
            plannedTime: calculateTotalTimeForRecordings(plannedRecordings),
        };
    };

    checkCapacity = (recordings: Recording[]) => {
        if (!this.isFreeCapacity(recordings)) {
            throw new Error('F03');
        }
    };

    isFreeCapacity = (recordings: Recording[]): boolean => {
        const { usedTime, plannedTime, totalTimeAvailable } = this.getStoragetInfo();
        const recordingsDuration = recordings
            .map((recording) => recording.endTime - recording.startTime)
            .reduce((sum, value) => sum + value, 0);
        const totalRecordedTime = (usedTime || 0) + (plannedTime || 0) + recordingsDuration;
        return totalRecordedTime < totalTimeAvailable;
    };

    fetchStorageInfo = async (): Promise<StorageInfo> => {
        await this.fakeBackend();
        return this.getStoragetInfo();
    };
}

let recordingsClient: StubRecordingClient | undefined;

export const createStubRecordingClient = (params) => {
    if (!recordingsClient) {
        recordingsClient = new StubRecordingClient(params);
    }
    return recordingsClient;
};
