import { useMutation, useQueryClient } from 'react-query';
import { QUERY_KEYS, Subscription } from '@24i/nxg-sdk-photon/src';
import { usePurchaseData } from '@24i/nxg-sdk-smartott-shared/src/context/PurchaseData';
import { log } from '@24i/nxg-core-utils/src/logger';
import { useFirebase } from '../../../context/Firebase';

const useCancelSubscriptions = () => {
    const { cancelSubscription } = usePurchaseData();

    const queryClient = useQueryClient();

    const { recordError } = useFirebase();

    const { mutate, isLoading } = useMutation(
        QUERY_KEYS.availableSubscriptions,
        (subscription: Subscription) => cancelSubscription(subscription),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(QUERY_KEYS.entitledSubscriptions);
                queryClient.invalidateQueries(QUERY_KEYS.availableSubscriptions);
                queryClient.invalidateQueries(QUERY_KEYS.mySubscriptions);
            },
            onError: (error) => {
                log(error);
                recordError?.(error);
            },
        }
    );
    return {
        mutate,
        isLoading,
    };
};

export default useCancelSubscriptions;
