import { isPlatformWeb } from 'renative';
import { useTranslation } from 'react-i18next';
import { StackNavigationProp } from '@react-navigation/stack';
import { ParamListBase } from '@react-navigation/native';
import useNavigation from '@24i/nxg-core-router/src/hooks/useNavigation';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import useEntitledSubscriptionsQuery from '@24i/nxg-sdk-smartott/src/hooks/query/subscriptions/useEntitledSubscriptionsQuery';
import {
    SOTT_DEFAULT_SCREENS,
    SOTT_DEFAULT_WEB_SCREENS,
} from '../../../../../navigation/constants';
import { DeleteAccountModalProps } from '../types';
import { useDeleteUser } from '../../../../../hooks/query/user';

const useViewModel = ({
    onCloseModal,
    openErrorModal,
    ...props
}: DeleteAccountModalProps): DeleteAccountModalProps => {
    const navigation = useNavigation<StackNavigationProp<ParamListBase>>();
    const router = useRouter();
    const { t } = useTranslation();
    const { mutate: deleteUser, isLoading } = useDeleteUser();
    const { data: currentSubscriptions } = useEntitledSubscriptionsQuery();

    const unableStrings = {
        title: t('account.delete.unable.title'),
        description: `${t('account.delete.unable.description1')}\n\n${t(
            'account.delete.unable.description2'
        )}`,
    };

    const navigateToSuccesScreen = (): void => {
        if (isPlatformWeb) {
            const deleteAccountSuccessLink =
                SOTT_DEFAULT_WEB_SCREENS.DeleteAccountSuccess.getLink();
            router.replace(deleteAccountSuccessLink.href, deleteAccountSuccessLink.as);
            return;
        }

        navigation.reset({
            index: 0,
            routes: [
                {
                    name: SOTT_DEFAULT_SCREENS.DELETE_ACCOUNT_SUCCESS,
                },
            ],
        });
    };

    const onConfirmDeleteAccount = () => {
        const hasSubscriptions =
            currentSubscriptions?.subscriptions && currentSubscriptions.subscriptions.length > 0;

        if (hasSubscriptions) {
            onCloseModal();
            openErrorModal(
                unableStrings.title,
                unableStrings.description,
                false,
                t('common.close') as string
            );

            return;
        }

        deleteUser(undefined, {
            onSuccess: () => {
                onCloseModal();
                navigateToSuccesScreen();
            },
            onError: () => {
                onCloseModal();
                openErrorModal(t('error.A00.title'), t('error.A00.body'));
            },
        });
    };

    return {
        onConfirmDeleteAccount,
        onCloseModal,
        openErrorModal,
        isLoading,
        ...props,
    };
};

export default useViewModel;
