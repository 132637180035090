import _defineProperty from"@babel/runtime/helpers/defineProperty";function ownKeys(object,enumerableOnly){var keys=Object.keys(object);if(Object.getOwnPropertySymbols){var symbols=Object.getOwnPropertySymbols(object);enumerableOnly&&(symbols=symbols.filter(function(sym){return Object.getOwnPropertyDescriptor(object,sym).enumerable;})),keys.push.apply(keys,symbols);}return keys;}function _objectSpread(target){for(var i=1;i<arguments.length;i++){var source=null!=arguments[i]?arguments[i]:{};i%2?ownKeys(Object(source),!0).forEach(function(key){_defineProperty(target,key,source[key]);}):Object.getOwnPropertyDescriptors?Object.defineProperties(target,Object.getOwnPropertyDescriptors(source)):ownKeys(Object(source)).forEach(function(key){Object.defineProperty(target,key,Object.getOwnPropertyDescriptor(source,key));});}return target;}import{getFont}from'@24i/nxg-sdk-higgs';



export var getStyles=function getStyles(theme){return{
container:{
paddingTop:24,
paddingHorizontal:20
},
screenDescription:_objectSpread(_objectSpread({
textAlign:'left',
opacity:0.6},
getFont(theme,'body2')),{},{
color:theme.color.textPrimary}),

selectorContainer:{
marginTop:40
},
backstageButtonStyles:{
marginVertical:40
},
itemContainer:{
flexDirection:'row',
alignItems:'flex-start',
flex:1,
paddingHorizontal:8,
marginVertical:12,
width:'100%',
zIndex:9999
},
itemText:_objectSpread({
color:""+theme.color.menuItemActive},
getFont(theme,'menuItem-inactive'))

};};