import { SectionList, View } from '@24i/nxg-sdk-quarks';
import { onAnalytics, ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics';
import React, { useState, useRef, useEffect } from 'react';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Animated } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useSessionId } from '@24i/nxg-sdk-smartott-shared/src/analytics/hooks/useSessionId';
import PurchasesItem from '../../PurchasesItem';
import getStyles, { PADDING_VERTICAL } from './styles';
import { PurchaseSelectionProps } from './types';
import { PURCHASE_ITEM_HEIGHT } from '../components/PurchaseItem/styles';
import LoadingWrapper from '../../LoadingWrapper';

const PurchaseSelection = ({ sections, isLoading, onPurchaseSelect }: PurchaseSelectionProps) => {
    const { theme } = useTheme();
    const { t } = useTranslation();
    const styles = getStyles(theme);
    const { sessionId } = useSessionId();

    const completeScrollBarHeight = useRef(PURCHASE_ITEM_HEIGHT + PADDING_VERTICAL);
    const [visibleScrollBarHeight, setVisibleScrollBarHeight] = useState(0);

    const showScrollIndicator = completeScrollBarHeight.current > visibleScrollBarHeight;

    const scrollIndicatorSize =
        completeScrollBarHeight.current > visibleScrollBarHeight
            ? (visibleScrollBarHeight * visibleScrollBarHeight) / completeScrollBarHeight.current
            : visibleScrollBarHeight;

    const scrollIndicator = useRef(new Animated.Value(0)).current;

    const difference = visibleScrollBarHeight - scrollIndicatorSize;

    const scrollIndicatorPosition = Animated.multiply(
        scrollIndicator,
        visibleScrollBarHeight / completeScrollBarHeight.current
    ).interpolate({
        inputRange: [0, difference],
        outputRange: [0, difference],
        extrapolate: 'clamp',
    });

    useEffect(() => {
        onAnalytics(ANALYTICS_TRIGGERS.SCENE_VIEW, {
            screen: t('analytics.documentTitle.purchase.step1'),
            sessionId,
        });
        return () => {
            completeScrollBarHeight.current = PURCHASE_ITEM_HEIGHT + 24;
        };
    }, []);

    // call function every time a section item is rendered
    const calculateScrollBarHeight = (sectionHeight: number) => {
        completeScrollBarHeight.current += sectionHeight;
    };

    const getPurchaseItem = ({ item }) => (
        <View
            style={styles.purchaseItem}
            onLayout={({
                nativeEvent: {
                    layout: { height },
                },
            }) => {
                calculateScrollBarHeight(height);
            }}
        >
            <PurchasesItem item={item} onPress={onPurchaseSelect} />
        </View>
    );

    const getSectionFooter = ({ section }) => {
        const sectionIndex = sections.indexOf(section);
        const isLast = sectionIndex === sections.length - 1;
        return !isLast ? (
            <View
                onLayout={({
                    nativeEvent: {
                        layout: { height },
                    },
                }) => {
                    calculateScrollBarHeight(height);
                }}
                style={styles.sectionSeparator}
            />
        ) : null;
    };
    const handleOnLayOut = (e) => {
        setVisibleScrollBarHeight(e.nativeEvent.layout.height);
    };

    const getItemSeparator = () => (
        <View
            onLayout={({
                nativeEvent: {
                    layout: { height },
                },
            }) => {
                calculateScrollBarHeight(height);
            }}
            style={styles.itemSeparator}
        />
    );

    return (
        <LoadingWrapper
            isLoading={isLoading}
            wrapperStyle={styles.modalContainer}
            loaderProps={{ holderStyles: styles.loader }}
        >
            <SectionList
                onLayout={handleOnLayOut}
                onScroll={Animated.event(
                    [{ nativeEvent: { contentOffset: { y: scrollIndicator } } }],
                    { useNativeDriver: false }
                )}
                scrollEventThrottle={16}
                showsVerticalScrollIndicator={false}
                contentContainerStyle={styles.wrapper}
                sections={sections}
                ItemSeparatorComponent={getItemSeparator}
                renderSectionFooter={getSectionFooter}
                renderItem={getPurchaseItem}
                keyExtractor={(item) => item.id}
            />
            {(showScrollIndicator && (
                <View style={styles.scrollContainer}>
                    <Animated.View
                        style={[
                            styles.scrollIndicator,
                            {
                                height: scrollIndicatorSize,
                                transform: [{ translateY: scrollIndicatorPosition }],
                            },
                        ]}
                    />
                </View>
            )) || <></>}
        </LoadingWrapper>
    );
};

export default PurchaseSelection;
