import { ASSET_TYPE, Asset } from '@24i/nxg-sdk-photon';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { SOTT_DEFAULT_WEB_SCREENS } from '../../navigation/constants';
import useShared from './shared';

const useAssetActions = () => {
    const shared = useShared();
    const router = useRouter();

    const navigateToPlayer = (asset: Asset, isStartOver: boolean): void => {
        const { editionId, isTrailer, id, type, sectionLabel } = asset;
        const assetLink = SOTT_DEFAULT_WEB_SCREENS.Playback.getLink({
            type,
            id,
        });
        const channelId = asset.channelId || asset?.channel?.id;
        router.push(
            {
                pathname: assetLink.href,
                query: {
                    type,
                    id,
                    ...(asset.type === ASSET_TYPE.BROADCAST && { channelId }),
                    ...(isStartOver && { isStartOver }),
                    ...(editionId && { editionId }),
                    ...(isTrailer && { isTrailer }),
                    ...(sectionLabel && { sectionLabel }),
                },
            },
            assetLink.as
        );
    };

    const startPlayback = async (asset: Asset, isStartOver = false) => {
        if (asset) {
            let episodeToReproduce: Asset | undefined;
            if (asset.type === ASSET_TYPE.PODCAST_SERIES && !asset.isTrailer) {
                episodeToReproduce = await shared.fetchEpisodeToReproduce(asset, 'latest');
                if (episodeToReproduce) {
                    // FIXME: placeholder
                    console.log('Will reproduce latest episode');
                    return;
                }
            }
            if (asset.type === ASSET_TYPE.SERIES && !asset.isTrailer) {
                episodeToReproduce = await shared.fetchEpisodeToReproduce(asset);
                if (episodeToReproduce) episodeToReproduce.sectionLabel = asset.sectionLabel;
            }
            navigateToPlayer(episodeToReproduce || asset, isStartOver);
        }
    };

    return {
        ...shared,
        startPlayback,
    };
};

export default useAssetActions;
