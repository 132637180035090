import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import { useMemo, useState } from 'react';
import { MvpdSignInContentProps, MvpdSignInContentViewModelReturnType } from '../types';

export const useViewModel = (
    props: MvpdSignInContentProps
): MvpdSignInContentViewModelReturnType => {
    const [seeAllProviders, setSeeAllProviders] = useState(false);
    const [searchString, setSearchString] = useState('');
    const landingScreenEnabled = useFeature('landingScreen')?.enabled;

    const {
        selectProvider,
        mvpds: { tierOneMvpds, allMvpds },
        displayWebView,
    } = props;

    const filteredMvpds = useMemo(() => {
        if (searchString.length) {
            return allMvpds.filter((mvpd) =>
                mvpd.title.toLowerCase().includes(searchString.toLowerCase())
            );
        }
        return allMvpds;
    }, [allMvpds, searchString]);

    const handleSelectProvider = (mvpdId: string): void => {
        selectProvider(mvpdId);
    };

    const handleSeeAllProviders = (): void => {
        setSeeAllProviders((prevState) => !prevState);
    };

    const handleSearchStringChange = (text: string): void => {
        setSearchString(text);
    };

    return {
        handleSelectProvider,
        handleSeeAllProviders,
        handleSearchStringChange,
        searchString,
        seeAllProviders,
        ...props,
        mvpds: { tierOneMvpds, allMvpds: filteredMvpds },
        displayWebView,
        landingScreenEnabled,
    };
};
