import {
    createBackstageUserDataClient,
    createBackstageEntitlementsClient,
    createBackstageThemeDataClient,
    createBackstageAppStructureDataClient,
    ContentDataClient,
    createBackstagePurchaseDataClient,
    createBackstageAnalyticsDataClient,
    getParamsFromRuntimeConfig,
    createBackstagePlayerDataClient,
    createBackstageAppSettingDataClient,
    createBackstagePrimetimeDataClient,
} from '@24i/nxg-integration-backstage';
import {
    defaultRuntimeConfig,
    ImageServiceClient,
    LayoutClient,
} from '@24i/nxg-sdk-smartott-defaults';

import { EpgDataClient } from '@24i/nxg-sdk-smartott-stubs';

export const initFullApp = ({ runtimeJson, initApp, getMergedRuntimeConfig, i18nOptions }) => {
    initApp({ i18nOptions });

    const runtimeConfig = getMergedRuntimeConfig(runtimeJson, defaultRuntimeConfig);
    const params = getParamsFromRuntimeConfig(runtimeConfig);
    const userDataClient = createBackstageUserDataClient(params);
    const themeDataClient = createBackstageThemeDataClient({
        ...params,
    });
    const appStructureDataClient = createBackstageAppStructureDataClient({
        ...params,
    });
    const contentDataClient = new ContentDataClient(params);
    const entitlementsClient = createBackstageEntitlementsClient({ contentDataClient });
    const purchaseDataClient = createBackstagePurchaseDataClient(params);
    const appSettingsDataClient = createBackstageAppSettingDataClient(params);
    const playerDataClient = createBackstagePlayerDataClient();
    const analyticsDataClient = createBackstageAnalyticsDataClient(params);
    const epgDataClient = new EpgDataClient();
    const imageServiceClient = new ImageServiceClient({});
    const layoutClient = new LayoutClient();
    const primetimeDataClient = createBackstagePrimetimeDataClient(params);

    const clients = {
        userDataClient,
        themeDataClient,
        epgDataClient,
        entitlementsClient,
        appStructureDataClient,
        contentDataClient,
        purchaseDataClient,
        appSettingsDataClient,
        imageServiceClient,
        layoutClient,
        playerDataClient,
        analyticsDataClient,
        primetimeDataClient,
    };

    return {
        clients,
        config: {
            clients,
            runtimeConfig,
            defaultRuntimeConfig,
        },
    };
};
