import _regeneratorRuntime from"@babel/runtime/regenerator";import React from'react';

import{useTranslation}from'react-i18next';

import{useModal}from'@24i/nxg-sdk-gluons/src/context/Modal';
import GenericModal from'@24i/nxg-sdk-smartott/src/components/GenericModal';
import{useRouter}from'@24i/nxg-core-router/src/NextRouter';
import{SOTT_DEFAULT_WEB_SCREENS}from'@24i/nxg-sdk-smartott/src/navigation/constants';
import{useShared}from"./shared";


var useViewModel=function useViewModel(){
var _useShared=






useShared(),handleOnSignoutOfBackstage=_useShared.handleOnSignoutOfBackstage,serviceOptions=_useShared.serviceOptions,selectedService=_useShared.selectedService,isBackstageLoginEnabled=_useShared.isBackstageLoginEnabled,signedInWithBackstage=_useShared.signedInWithBackstage,onSelectedOption=_useShared.onSelectedOption;
var _useTranslation=useTranslation(),t=_useTranslation.t;
var router=useRouter();
var _useModal=useModal(),setModalChildren=_useModal.setModalChildren,closeModal=_useModal.closeModal;

var onLoginToBackstage=function onLoginToBackstage(){
var backstageLogin=SOTT_DEFAULT_WEB_SCREENS.SignIn.getLink();
router.push(
{pathname:backstageLogin.href,query:{backstage:true}},
backstageLogin.as
);
};

var onSignOutOfBackstage=function onSignOutOfBackstage(){return(
setModalChildren(
React.createElement(GenericModal,{
requiresModalWrapper:false,
defaultHorizontalButtonLayout:true,
title:{
text:t('auth.signOut.title')+" of Backstage"
},
description:{
text:t('auth.signOut.signOutConfirm.title')
},
primaryButton:{
title:t('auth.signOut.title'),
onPress:function onPress(){return _regeneratorRuntime.async(function onPress$(_context){while(1)switch(_context.prev=_context.next){case 0:_context.next=2;return _regeneratorRuntime.awrap(
handleOnSignoutOfBackstage());case 2:
closeModal();case 3:case"end":return _context.stop();}},null,null,null,Promise);}

},
secondaryButton:{
title:t('common.cancel'),
onPress:closeModal
}}
)
));};

return{
onSignOutOfBackstage:onSignOutOfBackstage,
serviceOptions:serviceOptions,
signedInWithBackstage:signedInWithBackstage,
onLoginToBackstage:onLoginToBackstage,
selectedService:selectedService,
isBackstageLoginEnabled:isBackstageLoginEnabled,
onSelectedOption:onSelectedOption
};
};

export{useViewModel};