import {
    Asset,
    ASSET_TYPE,
    getIsPodcastEpisode,
    getIsPodcastSeries,
} from '@24i/nxg-sdk-photon/src';
import i18next from 'i18next';

const getPlayButtonTitleForAsset = (item: Asset, t: i18next.TFunction): string => {
    switch (true) {
        case item.type === ASSET_TYPE.LIVE_EVENT || item.isLive:
            return t('asset.playback.watchLiveButton');

        case getIsPodcastSeries(item) || getIsPodcastEpisode(item):
            return t('asset.playback.playPodcastButton');

        default:
            return t('asset.playback.playButton');
    }
};

export { getPlayButtonTitleForAsset };
