import { Theme } from '@24i/nxg-sdk-photon';
import { getBorderRadius, getFont, Ratio } from '@24i/nxg-sdk-higgs';
import { PackshotTitleStyles } from '../types';

export const getPackshotTitleStyles = (theme: Theme, _?: number): PackshotTitleStyles => ({
    textWrapper: {
        marginTop: 6,
        justifyContent: 'flex-start',
        height: 70,
    },
    textContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    itemText: {
        fontWeight: 'bold',
        paddingTop: 2,
        color: theme.color.textPrimary,
        lineBreak: 'anywhere',
        ...getFont(theme, 'h4'),
    },
    genreText: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'h3'),
    },
    itemTextSubs: {
        textAlign: 'left',
        paddingTop: 2,
        color: `${theme.color.textPrimary}99`,
        ...getFont(theme, 'h5'),
    },
    itemTextSecondarySubs: {
        paddingTop: 2,
        color: `${theme.color.textPrimary}99`,
        ...getFont(theme, 'h5'),
    },
    titleContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.4)',
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
    },
    itemTextFocused: {},
    seeAllIcon: {
        height: Ratio(40),
        width: Ratio(40),
        marginLeft: Ratio(9),
    },
});
