import { Platform, Dimensions, PixelRatio } from 'react-native';
import { getBrowserName, getPlatformName, getDeviceId } from '@24i/nxg-core-utils';
import { RuntimeConfig } from '@24i/nxg-core-utils/src/globalSingletons';
import i18n from 'i18next';
import { DeviceInfo as DeviceInformation } from '@24i/nxg-sdk-quantum';
import { isPlatformAndroid, isPlatformIos, isPlatformTvos, isPlatformWeb } from 'renative';
import { DeviceInfo } from './types';
import { ANALYTICS_EVENTS } from './constants';

const getOsVersion = (): string =>
    isPlatformWeb ? getBrowserName().name : (Platform.Version as string);

const getDeviceTimeZone = (): number => {
    const date = new Date();
    return (-1 * date.getTimezoneOffset()) / 60;
};

const getScreenResolution = (): string => {
    const { height, width } = Dimensions.get('window');
    const realHeight = height * PixelRatio.get();
    const realWidth = width * PixelRatio.get();
    return `${realWidth}x${realHeight}`;
};

const getPlatform = (): string => {
    let platform = 'undefined';
    if (isPlatformIos) platform = 'iOS';
    if (isPlatformTvos) platform = 'tvOS';
    if (isPlatformAndroid) platform = 'Android';
    if (isPlatformWeb) platform = 'webOS';
    return platform;
};

export const getDeviceInfo = async (): Promise<DeviceInfo> => ({
    appVersion: RuntimeConfig.get().appVersion,
    serviceId: RuntimeConfig.get().apiService.id,
    osVersion: getOsVersion(),
    platform: getPlatform(),
    deviceId: await getDeviceId(),
    deviceType: getPlatformName(),
    deviceTimeZone: getDeviceTimeZone(),
    language: i18n.language,
    deviceManufacturer: await DeviceInformation.getManufacturer(),
    screenResolution: getScreenResolution(),
});

export const isEventScheduled = (
    redundantEvents: ANALYTICS_EVENTS[],
    eventNameFromPayload: string
): boolean => !redundantEvents.includes(eventNameFromPayload as ANALYTICS_EVENTS);
