/* eslint-disable react/display-name */
import { isPlatformWeb, isFactorMobile } from 'renative';
import { log } from '@24i/nxg-core-utils/src/logger';
import { Profile } from '@24i/nxg-sdk-photon/src/models/profile';
import { ProfileImage } from '@24i/nxg-sdk-photon/src/models/profileImage';
import { SOTT_DEFAULT_SCREENS } from '@24i/nxg-sdk-smartott/src/navigation/constants';
import { RouteProp, ParamListBase } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import useNavigation from '@24i/nxg-core-router/src/hooks/useNavigation';
import useRoute from '@24i/nxg-core-router/src/hooks/useRoute';
import React, { useEffect, useState } from 'react';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { useTranslation } from 'react-i18next';
import TopBarPrimary from '@24i/nxg-sdk-gluons/src/components/navigation/TopBarPrimary';
import {
    useAppSettingsData,
    useFeature,
} from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import { showToast } from '@24i/nxg-sdk-gluons/src/components/ui/Toast';
import { useStartPageNavigation } from '@24i/nxg-sdk-smartott/src/context/AppStartContext/hooks';
import { useQueryClient } from 'react-query';
import { QUERY_KEYS } from '@24i/nxg-sdk-photon';
import { useUserQuery } from '@24i/nxg-sdk-smartott/src/hooks/query/user';
import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';
import { onAnalytics, ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics';
import { useSessionId } from '@24i/nxg-sdk-smartott-shared/src/analytics/hooks/useSessionId';
import { useFirebase } from '../../../context/Firebase';
import { DEFAULT_CONTENT_RATINGS, UPSERT_PROFILE_MODES } from '../constants';
import { useStore } from '../../../context/ApplicationStore';
import { ProfileAge, Routes, UpsertScreenViewModelProps } from '../types';
import { useDeleteProfile } from './hooks/useDeleteProfile';
import { useCreateProfile } from './hooks/useCreateProfile';
import { useProfilePictureHelpers } from './hooks/useProfilePictureHelpers';

export const useViewModel = (props: UpsertScreenViewModelProps) => {
    const userDataClient = useUserData();
    const [userProfile, setUserProfile] = useState<Profile | null>(null);
    const [profileImageId, setProfileImageId] = useState('');
    const [profileImages, setProfileImages] = useState<ProfileImage[]>([]);
    const [isLoading, setLoading] = useState(true);
    const { recordError } = useFirebase();
    const navigation = useNavigation<StackNavigationProp<ParamListBase>>();
    const router = useRouter();
    const { profiles, setSelectedUserProfile, selectedProfile } = useStore();
    const [step, setStep] = useState(1);
    const { t } = useTranslation(['sott']);
    const { deleteProfile } = useDeleteProfile({ profileImages });
    const { createProfile } = useCreateProfile();
    const { findDefaultPictureIndex, findProfilePictureIndex, swapImageToFront, findImageURL } =
        useProfilePictureHelpers();
    const [imageFocusedIndex, setImageFocusedIndex] = useState(0);
    const appSettings = useAppSettingsData();
    const [viewRestrictionToggleState, setViewRestrictionToggleState] = useState<boolean>(false);
    const { navigateToStartPage } = useStartPageNavigation();
    const { user } = useUserQuery();
    const queryClient = useQueryClient();
    const { sessionId } = useSessionId();
    const contentRatingsFeature = useFeature('contentRatings');
    // Safeguard as contentRatingsFeature array comes with values duplicated.
    const uniqueContentRatingsFeature = [...new Set(contentRatingsFeature?.thresholds)];

    const { mode: modeFromProps, contentRatings: contentRatingsFromProps } = props;

    const contentRatings =
        contentRatingsFromProps || uniqueContentRatingsFeature || DEFAULT_CONTENT_RATINGS;

    const { params } = useRoute<RouteProp<Routes, SOTT_DEFAULT_SCREENS.UPSERT_PROFILE>>();

    const { mode = modeFromProps || UPSERT_PROFILE_MODES.CREATE, onboarding } = params;

    const editMode = mode === UPSERT_PROFILE_MODES.EDIT;

    let isOnboarding = !!onboarding;

    if (isPlatformWeb) {
        const { onboarding: onboardingQueryParam } = router.query;

        isOnboarding = !!onboardingQueryParam;
    }

    const screenTitle = editMode ? t('profiles.edit.title') : t('profiles.add.createButton');
    const primaryButtonText = editMode
        ? t('profiles.edit.saveButton')
        : t('profiles.add.createButton');

    const fetchAndSetProfile = async (id: string): Promise<Profile | undefined> => {
        if (id !== 'NEW_PROFILE_HANDLER') {
            try {
                const profile = await userDataClient.fetchProfile(id);
                if (profile) {
                    setUserProfile(profile);
                    setViewRestrictionToggleState(typeof profile?.age === 'number');
                }
                return profile;
            } catch (err) {
                showToast(JSON.stringify(err));
                recordError(err);
                log(err);

                return undefined;
            }
        } else {
            return undefined;
        }
    };

    const resolveProfileId = (): string | undefined => {
        if (isPlatformWeb) {
            // We can do the cast as we are on the web
            const { profileId: profileIdFromQuery } = router.query;

            if (Array.isArray(profileIdFromQuery) || !profileIdFromQuery)
                throw new Error(
                    `The profile id was not found in the query or it was in an invalid format`
                );

            return profileIdFromQuery;
        }

        return params.profileId;
    };

    const onProfileChange = (newValues): void => {
        const newProfile = { ...userProfile, ...newValues };
        setUserProfile(newProfile);
    };

    const onPinProtectedChange = (value): void => {
        onProfileChange({ isPinProtected: value });
    };

    const onProfileNameChange = (name: string): void => {
        onProfileChange({ name });
    };

    const onViewingRestrictionChange = (age: ProfileAge): void => {
        onProfileChange({ age });
    };

    const onViewingRestrictionsToggle = (): void => {
        if (!viewRestrictionToggleState) {
            onProfileChange({ age: contentRatings?.[0] ?? 0 });
        } else {
            onProfileChange({ age: null });
        }

        setViewRestrictionToggleState(!viewRestrictionToggleState);
    };

    const onProfileImageChange = (imageId: string): void => {
        setProfileImageId(imageId);
        const imageURL = findImageURL(imageId, profileImages);

        onProfileChange({ image: imageURL });
    };

    const onFocusedImageChange = (index: number): void => {
        setImageFocusedIndex(index);
    };

    const onBack = (): void => {
        if (isPlatformWeb) {
            router.back();
            return;
        }

        navigation.goBack();
    };

    const resolveNavigation = async (): Promise<void> => {
        if (!isOnboarding) {
            onBack();
        } else {
            // to avoid user skipping step by closing and openning app again had the setter here.
            if (user?.id) await appSettings.client.setShowProfileOnboarding(user.id);

            navigateToStartPage();
        }
    };

    const onNextStep = (currentStep: number): void => {
        setStep(currentStep + 1);
    };

    const onPrevStep = (currentStep: number): void => {
        if (currentStep === 1) return;
        setStep(currentStep - 1);
    };

    const onUpdateProfile = async (): Promise<void> => {
        setLoading(true);
        if (userProfile && userProfile.userId) {
            const { id, name, age, defaultProfile, isPinProtected } = userProfile;
            const imageId = profileImageId;
            try {
                const profile = await userDataClient.updateProfile(id, {
                    name,
                    age,
                    imageId,
                    isPinProtected,
                    defaultProfile,
                });
                const isCurrentProfile = id === (selectedProfile as Profile | undefined)?.id;
                if (isCurrentProfile) {
                    setSelectedUserProfile(profile);
                }
                queryClient.invalidateQueries(QUERY_KEYS.profiles);
                resolveNavigation();
            } catch (err) {
                if (typeof err === 'object' && err) {
                    const errors = Object.values(err);
                    const joinedErrors = errors.join(' - ');
                    showToast(joinedErrors);
                }
                recordError(err);
                log(err);
            }
        }
        setLoading(false);
    };

    const onCreateProfile = async (): Promise<void> => {
        setLoading(true);
        if (userProfile) {
            const { name, age, defaultProfile = false, isPinProtected = false } = userProfile;
            const imageId = profileImageId;
            try {
                await createProfile({
                    name,
                    age,
                    imageId,
                    isPinProtected,
                    defaultProfile,
                });
                queryClient.invalidateQueries(QUERY_KEYS.profiles);
                resolveNavigation();
            } catch (err) {
                if (typeof err === 'object' && err) {
                    const errors = Object.values(err);
                    const joinedErrors = errors.join(' - ');
                    showToast(joinedErrors);
                }
                recordError(err);
                log(err);
            }
        }
        setLoading(false);
    };

    const onDeleteProfile = async (id: string): Promise<void> => {
        setLoading(true);
        try {
            await deleteProfile(id);
            await queryClient.invalidateQueries(QUERY_KEYS.profiles);
            resolveNavigation();
        } catch (err) {
            if (typeof err === 'object' && err) {
                const errors = Object.values(err);
                const joinedErrors = errors.join(' - ');
                showToast(joinedErrors);
            }
            recordError(err);
            log(err);
        }
        setLoading(false);
    };

    const fetchAndSetProfileImages = async (profile?: Profile): Promise<void> => {
        try {
            const images = await userDataClient.fetchProfileImages();
            if (images.length) {
                const profilePictureIndex = findProfilePictureIndex(images, profile);
                const defaultIndex = findDefaultPictureIndex(images);
                if (profile) {
                    swapImageToFront(images, profilePictureIndex);
                } else {
                    swapImageToFront(images, defaultIndex);
                }
                setProfileImages(images);
                setProfileImageId(images[0]?.id);
            }
        } catch (err) {
            showToast(JSON.stringify(err));
            recordError(err);
            log(err);
        }
    };

    const fetchProfileAndGetImages = async (): Promise<void> => {
        let profileIdToFetch = resolveProfileId();

        if (!profileIdToFetch && isOnboarding && profiles) {
            profileIdToFetch = profiles[0].id;
        }

        if (!profileIdToFetch) {
            throw new Error(`The profileID was not found in the params object!`);
        }

        const profile = await fetchAndSetProfile(profileIdToFetch);
        await fetchAndSetProfileImages(profile);
    };

    useEffect(() => {
        setLoading(true);
        if (editMode) {
            fetchProfileAndGetImages().then(() => setLoading(false));
            onAnalytics(ANALYTICS_TRIGGERS.SCENE_VIEW, {
                screen: t('profiles.edit.title'),
                sessionId,
            });
        } else {
            fetchAndSetProfileImages().then(() => setLoading(false));
            onAnalytics(ANALYTICS_TRIGGERS.SCENE_VIEW, {
                screen: t('profiles.add.createButton'),
                sessionId,
            });
        }
        navigation.setOptions({
            title: screenTitle,
            headerShown: isFactorMobile && !isOnboarding,
            header: (headerProps) => (
                <TopBarPrimary {...headerProps} title={screenTitle} navigation={navigation} />
            ),
        });
    }, []);

    // If there is only one profile, the delete button should not be available
    const shouldDisplayDeleteButton = (profiles?.length || -1) > 1;

    const isButtonDisabled = !(userProfile?.name && userProfile?.name?.length > 1);

    return {
        ...props,
        contentRatings,
        userProfile,
        isLoading,
        screenTitle,
        primaryButtonText,
        mode,
        profileImages,
        profileImageId,
        shouldDisplayDeleteButton,
        step,
        imageFocusedIndex,
        isButtonDisabled,
        isOnboarding,
        onBack,
        onProfileNameChange,
        onProfileImageChange,
        onPinProtectedChange,
        onDeleteProfile,
        onCreateProfile,
        onUpdateProfile,
        onNextStep,
        onPrevStep,
        onViewingRestrictionChange,
        onViewingRestrictionsToggle,
        onFocusedImageChange,
    };
};
