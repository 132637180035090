import React, { useEffect } from 'react';
import { useAdobePrimetime } from '@24i/adobe-primetime/src/shared/context/useAdobePrimetime';
import {
    RENDER_STATE,
    ACCESS_ENABLER_AUTH_CODE,
    ACCESS_ENABLER_CONSTANTS,
} from '@24i/adobe-primetime/src/shared/accessEnablerConstants';
import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import { ParamListBase } from '@react-navigation/native';
import {
    SOTT_DEFAULT_ROOT_SCREENS,
    SOTT_DEFAULT_SCREENS,
    SOTT_DEFAULT_WEB_SCREENS,
    SOTT_HOME_ROUTE,
} from '@24i/nxg-sdk-smartott/src/navigation/constants';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { StackNavigationProp } from '@react-navigation/stack';
import useNavigation from '@24i/nxg-core-router/src/hooks/useNavigation';
import { iOS } from '@24i/adobe-primetime/src/api/utils';
import TopBarPrimary from '@24i/nxg-sdk-gluons/src/components/navigation/TopBarPrimary';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { isPlatformWeb } from 'renative';
import { LandingScreenConfig } from '@24i/nxg-sdk-photon';
import { useMVPDRouteParams } from '../../../hooks/useMVPDRouteParams';
import type { MvpdSignInViewModelReturnType } from '../types';

export const useViewModel = (): MvpdSignInViewModelReturnType => {
    const {
        authState: { initialising, authenticating, initialised, authenticated, authCode },
        initialiseAdobePrimetimeSDK,
        mvpdSignInPath,
        mvpds,
        selectProvider,
        displayWebView,
        onSuccessfulAuthentication,
        signOut,
        setMvpdSignInPath,
    } = useAdobePrimetime();
    const { theme } = useTheme();
    const router = useRouter();
    const { t } = useTranslation();
    const landingScreenFeature = useFeature('landingScreen');
    const guestModeEnabled = useFeature('guestMode')?.enabled;
    const adobePrimetimeBackstageConfig = useFeature('adobePrimetime');

    const navigation = useNavigation<StackNavigationProp<ParamListBase>>();
    const {
        didInitialise,
        shouldSignOut,
        routeName: prevRouteName,
        redirectToLanding,
    } = useMVPDRouteParams();

    // if this component is rendered with a differente asPath it is rendered as a modal
    const isModal = (isPlatformWeb as boolean) && router?.asPath === SOTT_HOME_ROUTE;
    const landingScreenEnabled = landingScreenFeature?.enabled;

    const { ERROR, LOADING, SHOW_LANDING_SCREEN, SHOW_MVPD_CONTENT, AUTHENTICATED } = RENDER_STATE;

    const { GENERIC_AUTHENTICATION_ERROR } = ACCESS_ENABLER_AUTH_CODE;

    const { ADOBEPASS_REDIRECT_SCHEME, ADOBEPASS_REDIRECT_URL } = ACCESS_ENABLER_CONSTANTS;

    const shouldAuthenticate = initialised && !authenticated;
    const isMvpds = !!mvpds.tierOneMvpds.length;

    const renderState = ((): RENDER_STATE => {
        if (initialising || authenticating) return LOADING;
        if (shouldAuthenticate && isMvpds) {
            if (landingScreenEnabled) {
                if (isPlatformWeb) {
                    if (redirectToLanding || shouldSignOut) return SHOW_LANDING_SCREEN;
                    if (initialised) return SHOW_MVPD_CONTENT;
                } else {
                    if (!didInitialise || shouldSignOut) return SHOW_LANDING_SCREEN;
                    if (didInitialise) return SHOW_MVPD_CONTENT;
                }
            }
            if (!landingScreenEnabled) return SHOW_MVPD_CONTENT;
        }
        if (iOS && authenticated && shouldSignOut) return SHOW_MVPD_CONTENT;
        if (initialised && authenticated && !shouldSignOut) return AUTHENTICATED;
        // setAuthenticationStatus callback is called with GENERIC_AUTHENTICATION_ERROR
        // (on iOS only) when the user signs out so we should not return an error in this case
        if (initialised && authCode === GENERIC_AUTHENTICATION_ERROR && !shouldSignOut)
            return ERROR;
        return LOADING;
    })();

    const gotToLandingPage = () => {
        if (!isPlatformWeb) {
            navigation.navigate(SOTT_DEFAULT_ROOT_SCREENS.OTHER_SCREENS_STACK_SCREEN, {
                screen: SOTT_DEFAULT_SCREENS.LANDING,
            });
            return;
        }
        const landingLink = SOTT_DEFAULT_WEB_SCREENS.Landing.getLink();
        router.push(landingLink.href, landingLink.as);
    };

    const forwardGuest = () => {
        if (!isPlatformWeb) {
            navigation.reset({
                index: 0,
                routes: [
                    {
                        name: SOTT_DEFAULT_ROOT_SCREENS.MENU_STACK_SCREEN,
                        params: {
                            screen: SOTT_DEFAULT_SCREENS.HOME,
                        },
                    },
                ],
            });
            return;
        }
        const homeLink = SOTT_DEFAULT_WEB_SCREENS.Home.getLink();
        router.push(homeLink.href, homeLink.as);
    };

    useEffect(() => {
        if (isPlatformWeb) {
            if (!initialised) initialiseAdobePrimetimeSDK(adobePrimetimeBackstageConfig);
        } else if (!didInitialise) initialiseAdobePrimetimeSDK(adobePrimetimeBackstageConfig);
    }, []);

    const onBack = () => {
        if (displayWebView) {
            setMvpdSignInPath(null);
        } else if (prevRouteName) {
            navigation.navigate(prevRouteName);
        } else {
            navigation.goBack();
        }
    };

    useEffect(() => {
        const hideBackButton = landingScreenEnabled
            ? // hide when iOS webview is executing async sign out
              displayWebView && authenticated
            : !(displayWebView && !authenticated);

        navigation.setOptions({
            header: () => (
                <TopBarPrimary
                    previous={!hideBackButton}
                    title={t('auth.signIn.title')}
                    onBack={onBack}
                />
            ),
        });
    }, [prevRouteName, landingScreenEnabled, displayWebView, authenticated]);

    useEffect(() => {
        if (renderState === SHOW_LANDING_SCREEN) gotToLandingPage();
        if (renderState === AUTHENTICATED) forwardGuest();
    }, [renderState]);

    useEffect(() => {
        if (shouldSignOut) signOut();
    }, [shouldSignOut]);

    const iOSLogOutUrl = shouldSignOut ? ADOBEPASS_REDIRECT_SCHEME + ADOBEPASS_REDIRECT_URL : '';

    const getLandingScreenConfigFromTheme = (): LandingScreenConfig | null => {
        const {
            elements: { landingImageLandscape, landingImagePortrait },
        } = theme;

        if (!landingImageLandscape?.value && !landingImagePortrait?.value) return null;

        return {
            ...(landingScreenFeature || {}),
            backgroundImage: {
                landscape: landingImageLandscape?.value?.toString() || '',
                portrait: landingImagePortrait?.value?.toString() || '',
            },
        };
    };

    const landingScreenConfig: LandingScreenConfig | undefined =
        getLandingScreenConfigFromTheme() || landingScreenFeature || undefined;

    const handleOnBackWeb = () => (isPlatformWeb ? router.back() : undefined);

    return {
        mvpdSignInPath,
        mvpds,
        selectProvider,
        renderState,
        displayWebView,
        onSuccessfulAuthentication,
        guestModeEnabled,
        iOSLogOutUrl,
        forwardGuest,
        landingScreenConfig,
        isModal,
        handleOnBackWeb,
    };
};
