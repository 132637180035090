import { useState, useEffect } from 'react';
import { Theme } from '@24i/nxg-sdk-photon/src';
import { useMonitoring } from '@24i/nxg-sdk-smartott-shared/src/context/MonitoringData';
import { useAnalytics } from '@24i/nxg-sdk-smartott-shared/src/context/Analytics';
import { useAppConfigQuery } from '../../../hooks/query/useAppConfigQuery';
import { useUserQuery } from '../../../hooks/query/user';
import { useProfilesQuery } from '../../../hooks/query/useProfilesQuery';
import { useThemeQuery } from '../../../hooks/query/useThemeQuery';

export const useInitialQueries = () => {
    const { appSettings: serviceConfig } = useAppConfigQuery();
    const { user } = useUserQuery();
    const { profiles } = useProfilesQuery();
    const { theme: appTheme } = useThemeQuery();

    return { user, appTheme, serviceConfig, profiles };
};

export const useSharedApplicationInit = () => {
    const { initMonitors } = useMonitoring();
    const { initAnalytics } = useAnalytics();
    const { serviceConfig } = useInitialQueries();

    useEffect(() => {
        if (serviceConfig) {
            const monitors = serviceConfig?.features?.monitoring;
            initMonitors(monitors || []);
            const analytics = serviceConfig?.features?.analytics;
            initAnalytics(analytics || []);
        }
    }, [serviceConfig]);
};

export const useDeprecatedSetAppState = () => {
    const { theme: appTheme } = useThemeQuery();

    const [appModelState, setAppModelState] = useState<{
        appTheme?: Theme | null;
        customIcons: unknown;
        geoIpBlocking: boolean | null;
    }>({
        appTheme: null,
        customIcons: null,
        geoIpBlocking: null,
    });

    useEffect(() => {
        if (appTheme) {
            setAppModelState({ appTheme, geoIpBlocking: null, customIcons: null });
        }
    }, [appTheme]);

    return { appModelState };
};
