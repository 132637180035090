import { useState, useRef, useEffect, useCallback } from 'react';
import { MINUTE_IN_MS } from '@24i/nxg-core-utils/src/constants';
import { LayoutChangeEvent } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useStore } from '@24i/nxg-sdk-smartott/src/context/ApplicationStore';
import { ASSET_TYPE, PRODUCT_SCREENS } from '@24i/nxg-sdk-photon';
import { usePlatformRoute } from '@24i/nxg-sdk-smartott/src/hooks/usePlatformRoute';
import { PackshotContentProps, ViewModelReturnType } from '../types';
import { OnPressHandler } from '../../../types';

const useViewModel = (props: PackshotContentProps): ViewModelReturnType => {
    const {
        progress = 0,
        item: { startsAt = 0, endsAt = 0 },
        item,
        isLive,
        title,
        subtitle,
        isChannel,
        onLayout,
        onPress,
    } = props;

    const [dynamicProgress, setDynamicProgress] = useState(progress);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const { t } = useTranslation();
    const { userData } = useStore();
    const route = usePlatformRoute();
    /**
     * if the user is not logged in and is on details page should not show the play icon
     * if the user is not logged in and is on homepage it should show the play icon
     */
    const shouldShowIcon =
        (!!userData && route?.name !== PRODUCT_SCREENS.DETAILS) ||
        route?.name === PRODUCT_SCREENS.DYNAMIC_CONTENT;

    useEffect(() => {
        // Determine how much increase the progress bar
        // to show an update every minute
        const duration = endsAt && startsAt ? (endsAt - startsAt) / 60 : 0;
        const percentageToAdd = 1 / duration;

        if (isLive || isChannel) {
            intervalRef.current = setInterval(() => {
                setDynamicProgress((prevValue) => prevValue + percentageToAdd);
            }, MINUTE_IN_MS);
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [endsAt, startsAt]);

    const onLayoutCallback = useCallback((e: LayoutChangeEvent) => onLayout?.(e), []);
    const onPressCallback = useCallback<OnPressHandler>((e) => onPress?.(e), [onPress]);

    const getPackshotTitle = () => {
        if (item.type === ASSET_TYPE.EPISODE) return item.seriesName || title;

        return title;
    };

    const getPackshotSubtitle = () => {
        const { seasonNumber, episodeNumber } = item;

        if (item.type === ASSET_TYPE.EPISODE) {
            const seasonNumberText = seasonNumber
                ? `${t('asset.series.seasonInitial')}${seasonNumber} `
                : '';
            const episodeNumberText = episodeNumber
                ? `${t('asset.series.episode.initial')}${episodeNumber} `
                : '';
            const episodeTitleText =
                (seasonNumber || episodeNumber) && title ? `- ${title}` : title;

            return `${seasonNumberText}${episodeNumberText}${episodeTitleText}`;
        }
        return subtitle;
    };

    return {
        ...props,
        dynamicProgress,
        onLayout: onLayoutCallback,
        onPress: onPressCallback,
        shouldShowIcon,
        title: getPackshotTitle(),
        subtitle: getPackshotSubtitle(),
    };
};

export default useViewModel;
