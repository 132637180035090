import { BaseApiParams } from '@24i/nxg-core-utils/src/api';
import { Channel, EpgDataClient, Recording } from '@24i/nxg-sdk-photon';
import { BackstageApiBase } from '../../base';
import { ChannelsResponseGuard, EpgResponseGuard } from './guards';
import { mapEpgResponseToEpgData, mapChannel } from './mappers';
import { getFromAndToParam } from './utils';

export class BackstageEpgDataClient extends BackstageApiBase implements EpgDataClient {
    getEpgData = async (_start?: number, _end?: number, date?: Date, channelIds: string[] = []) => {
        const channels = await this.request({
            method: 'GET',
            path: '/media/channels',
            guard: ChannelsResponseGuard,
        });

        const channelsIdsFromReq = channels?.items?.map((i) => i.id);

        const epgData = await this.request({
            method: 'GET',
            path: '/epg',
            guard: EpgResponseGuard,
            query: {
                ...getFromAndToParam(date),
                channels:
                    channelIds.join(',') ||
                    (channelsIdsFromReq ? channelsIdsFromReq.join(',') : ''),
            },
        });

        return mapEpgResponseToEpgData(epgData, channels);
    };

    getChannels = async (): Promise<Channel[]> => {
        const response = await this.request({
            method: 'GET',
            path: '/media/channels',
            guard: ChannelsResponseGuard,
        });
        return response.items.map(mapChannel);
    };

    getEpgDataInRange = async (range: { start: number; end: number }, channels: Channel[]) => {
        const from = Math.round(range.start / 1000);
        const to = Math.round(range.end / 1000);

        const epgData = await this.request({
            method: 'GET',
            path: '/epg',
            guard: EpgResponseGuard,
            query: {
                from,
                to,
                channels: channels.map((ch) => ch.id).join(','),
            },
        });

        return mapEpgResponseToEpgData(epgData, { items: channels });
    };

    getBroadcastByRecording = async (_recording: Recording) => {
        return undefined;
    };
}

export const createBackstageEpgDataClient = (params: BaseApiParams) => {
    return new BackstageEpgDataClient(params);
};
