import _slicedToArray from"@babel/runtime/helpers/slicedToArray";import _regeneratorRuntime from"@babel/runtime/regenerator";
import React,{useEffect,useState}from'react';

import{ApplicationStoreProvider as SDKStoreProvider}from'@24i/nxg-sdk-smartott/src/context/ApplicationStore';

import{Storage}from'@24i/nxg-sdk-quantum';
import{
LastFocusedPageTags,
LayoutScrollView,
TvDrawer,
FocusedSectionId}from
'@24i/nxg-core-utils/src/globalSingletons';
import{ASYNC_STORAGE_KEY_SERVICE}from"../../utils";

var resetFocusGlobals=function resetFocusGlobals(){


LastFocusedPageTags.clear();
LayoutScrollView.clear();
TvDrawer.clear();
FocusedSectionId.set(0);
};

export var ApplicationStoreProvider=function ApplicationStoreProvider(_ref)




{var defaultService=_ref.defaultService,children=_ref.children,config=_ref.runtimeConfig,setService=_ref.setService;
var _useState=useState(),_useState2=_slicedToArray(_useState,2),backstageUserData=_useState2[0],setBackstageUserData=_useState2[1];
var _useState3=useState(config),_useState4=_slicedToArray(_useState3,1),runtimeConfig=_useState4[0];
var _useState5=useState(defaultService),_useState6=_slicedToArray(_useState5,2),serviceData=_useState6[0],setServiceData=_useState6[1];

var _setServiceData=function _setServiceData(value){return _regeneratorRuntime.async(function _setServiceData$(_context){while(1)switch(_context.prev=_context.next){case 0:_context.next=2;return _regeneratorRuntime.awrap(
Storage.setItem(ASYNC_STORAGE_KEY_SERVICE,JSON.stringify(value)));case 2:
setService(value);
setServiceData(value);case 4:case"end":return _context.stop();}},null,null,null,Promise);};


useEffect(function(){
resetFocusGlobals();
},[serviceData]);

return(
React.createElement(SDKStoreProvider,{
runtimeConfig:config,
value:{
backstageUserData:backstageUserData,
setBackstageUserData:setBackstageUserData,
serviceData:serviceData,
setServiceData:_setServiceData,
runtimeConfig:runtimeConfig
},
key:serviceData==null?void 0:serviceData.id},

children
));

};