import { RATING_SYSTEM } from '../enums';
import { Asset } from '../models/asset';
import { CleengTokenData } from '../models/cleengDecodedToken';
import { Event } from '../models/event';
import { Favorite } from '../models/favorite';
import { Profile } from '../models/profile';
import { ProfileImage } from '../models/profileImage';
import { Rating } from '../models/rating';
import { Token } from '../models/token';
import { User } from '../models/user';
import { ValidPin } from '../models/validPin';

export interface RegisterRequest {
    firstName: string;
    lastName: string;
    username: string;
    password: string;
    currency: string;
}

export interface LoginRequest {
    username: string;
    pasword: string;
    profileId?: string;
}

export interface CreateProfileRequest {
    name: string | null | undefined;
    imageId: string | null | undefined;
    age: number | null | undefined;
    isPinProtected: boolean | null | undefined;
    defaultProfile: boolean | null | undefined;
}

export interface FetchProfileRequest {
    profileId: string;
}

export interface UpdateProfileRequest {
    id?: string;
    userId?: string | null | undefined;
    name?: string | null;
    age?: number | null;
    isPinProtected?: boolean | null;
    defaultProfile?: boolean | null;
    imageId?: string | null;
    selected?: boolean | null | undefined;
}

export interface DeleteProfileRequest {
    profileId: string;
}

export interface CanDeleteProfileContext {
    profiles: Profile[];
}

export type CanDeleteProfileResponse = DeletableProfile | UndeletableProfile;

export type DeletableProfile = {
    canDelete: true;
};

export type UndeletableProfile = {
    canDelete: false;
    /** It is imperative for these steps to be added in-order so they can be resolved sequentially */
    resolutionSteps: DeleteProfileResolutionStep[];
    cause: Error;
};

export const isUndeletableProfile = (profile: unknown): profile is UndeletableProfile => {
    if (profile && typeof profile === 'object') {
        return 'canDelete' in profile && 'resolutionSteps' in profile && 'cause' in profile;
    }
    return false;
};

export type DeleteProfileResolutionStep =
    | { action: 'SELECT_NEW_DEFAULT'; profile: Profile }
    | { action: 'SWITCH_TO_PROFILE'; profile: Profile };

export interface UpdateUserEmailRequest {
    email: string;
}

export interface UpdateUserRequest {
    [key: string]: string | number;
}

export interface RecoverPasswordRequest {
    email: string;
}

export interface UpdatePasswordRequest {
    email: string;
    resetPasswordToken: string;
    newPassword: string;
}

export interface SelectUserProfileRequest {
    profileId: string;
}

export interface AddToFavoriesRequest {
    entityType: string;
    entityId: string;
}

export interface DeleteFromFavoritesRequest {
    id: string;
}

export interface EventsRequest {
    assetId: string;
    assetType: string;
    action: string;
    offset: number;
}

export interface ValidatePinRequest {
    validatedPin: string | null;
    userId: User['id'];
}

export interface UserDataClient {
    register: (requestParams: RegisterRequest) => Promise<void>;

    login: (
        username: LoginRequest['username'],
        password: LoginRequest['pasword'],
        profileId?: LoginRequest['profileId']
    ) => Promise<Token>;

    logout: () => Promise<void>;

    fetchUser: () => Promise<User | null>;

    updateUser: (userData: UpdateUserRequest) => Promise<void>;

    deleteUser: () => Promise<void>;

    fetchUserProfiles: () => Promise<Profile[]>;

    createProfile: (
        name: CreateProfileRequest['name'],
        imageId: CreateProfileRequest['imageId'],
        age: CreateProfileRequest['age'],
        isPinProtected: CreateProfileRequest['isPinProtected']
    ) => Promise<Profile>;

    fetchProfile: (profileId: FetchProfileRequest['profileId']) => Promise<Profile>;

    updateProfile: (profileId: string, newProperties: UpdateProfileRequest) => Promise<Profile>;

    deleteProfile: (profileId: DeleteProfileRequest['profileId']) => Promise<void>;

    canDeleteProfile: (
        profileId: DeleteProfileRequest['profileId'],
        context: CanDeleteProfileContext
    ) => Promise<CanDeleteProfileResponse>;

    updateUserEmail: (email: UpdateUserEmailRequest['email']) => Promise<void>;

    recoverPassword: (email: RecoverPasswordRequest['email']) => Promise<void>;

    updatePassword: (
        email: UpdatePasswordRequest['email'],
        resetPasswordToken: UpdatePasswordRequest['resetPasswordToken'],
        newPassword: UpdatePasswordRequest['newPassword']
    ) => Promise<void>;

    selectUserProfile: (profileId: SelectUserProfileRequest) => Promise<Token>;

    fetchProfileImages: () => Promise<ProfileImage[]>;

    fetchFavorites: () => Promise<Favorite[]>;

    addToFavorites: (
        entityType: AddToFavoriesRequest['entityType'],
        entityId: AddToFavoriesRequest['entityId']
    ) => Promise<Favorite | false>;

    deleteFromFavorites: (id: DeleteFromFavoritesRequest['id']) => Promise<void | false>;

    events: (
        assetId: EventsRequest['assetId'],
        assetType: EventsRequest['assetType'],
        action: EventsRequest['action'],
        offset: EventsRequest['offset']
    ) => Promise<Event>;

    validatePin: (validatedPin: ValidatePinRequest['validatedPin']) => Promise<ValidPin>;

    getAssetRatingForUser: (
        asset: Asset,
        ratingSystem: RATING_SYSTEM
    ) => Promise<Rating | undefined>;

    rateAssetForUser: (asset: Asset, rating: Rating) => Promise<void>;

    deleteAssetRatingForUser: (asset: Asset) => Promise<void>;

    updateAccountPin: (pin: string) => Promise<void>;

    getOAuthUrl: () => Promise<{ redirectUrl: string }>;

    getUserOauthToken: (code: string) => Promise<Token>;

    getDecodedCleengToken: () => Promise<CleengTokenData | null>;

    refetchBackstageToken: () => Promise<{
        token: string;
    } | null>;
}
