import { useInfiniteQuery } from 'react-query';
import { PageSection, QUERY_KEYS, Profile } from '@24i/nxg-sdk-photon/src';
import { useTranslation } from 'react-i18next';
import { useContentData } from '@24i/nxg-sdk-smartott-shared/src/context/ContentData';
import { log } from '@24i/nxg-core-utils/src/logger';
import { useFirebase } from '../../../context/Firebase';

export const getPlaylistQueryKey = (
    sectionsStructure: PageSection[],
    selectedProfile?: Profile | null,
    language?: string
) => [QUERY_KEYS.playlists, sectionsStructure?.[0]?.id, selectedProfile?.id, language];

const usePlaylistsQuery = (
    sectionsStructure: PageSection[],
    size?: number,
    selectedProfile?: Profile | null
) => {
    const { fetchPlaylist } = useContentData();
    const { i18n } = useTranslation();
    const { language } = i18n;
    const fetchSize = size || sectionsStructure.length;
    const { recordError } = useFirebase();

    return useInfiniteQuery<PageSection[]>(
        getPlaylistQueryKey(sectionsStructure, selectedProfile, language),
        ({ pageParam }) => fetchPlaylist(sectionsStructure, fetchSize, pageParam || 0),
        {
            getNextPageParam: (_lastPage, pages) => {
                if (pages.length < sectionsStructure.length / fetchSize) {
                    return pages.length;
                }
                return undefined;
            },
            enabled: !!sectionsStructure.length,
            onError: (error) => {
                log(error);
                recordError?.(error);
            },
        }
    );
};

export default usePlaylistsQuery;
