import _defineProperty from"@babel/runtime/helpers/defineProperty";function ownKeys(object,enumerableOnly){var keys=Object.keys(object);if(Object.getOwnPropertySymbols){var symbols=Object.getOwnPropertySymbols(object);enumerableOnly&&(symbols=symbols.filter(function(sym){return Object.getOwnPropertyDescriptor(object,sym).enumerable;})),keys.push.apply(keys,symbols);}return keys;}function _objectSpread(target){for(var i=1;i<arguments.length;i++){var source=null!=arguments[i]?arguments[i]:{};i%2?ownKeys(Object(source),!0).forEach(function(key){_defineProperty(target,key,source[key]);}):Object.getOwnPropertyDescriptors?Object.defineProperties(target,Object.getOwnPropertyDescriptors(source)):ownKeys(Object(source)).forEach(function(key){Object.defineProperty(target,key,Object.getOwnPropertyDescriptor(source,key));});}return target;}import{
getRuntimeConfig}from

'@24i/nxg-sdk-smartott/src/Application/initApp';

import{Api}from'renative';

var transformServiceData=function transformServiceData(serviceData){
var appId;
if(typeof serviceData.appId==='string'){
appId=serviceData.appId;
}else{

appId=serviceData.appId[Api.platform]||serviceData.appId.default;
}

return _objectSpread(_objectSpread({},
serviceData),{},{
appId:appId});

};

export var getInitialApiService=function getInitialApiService(){return(
transformServiceData(getRuntimeConfig('apiService')));};

export var getDefaultApiServices=function getDefaultApiServices(){
var defaultServices=getRuntimeConfig('defaultApiServices');
var transformedServices=defaultServices.map(function(defaultService){return(
transformServiceData(defaultService));}
);
var services=transformedServices.concat(getInitialApiService());
return services;
};

export var ASYNC_STORAGE_KEY_SERVICE='service';