import { useTranslation } from 'react-i18next';
import React from 'react';
import { BlockedItem } from '@24i/nxg-sdk-photon';
import { useModal } from '@24i/nxg-sdk-gluons/src/context/Modal';
import BlockedModal from '../..';
import { BlockModalTypes } from '../../types';

const useShared = () => {
    const { t } = useTranslation();
    const { setModalChildren, setModalProps, closeModal } = useModal();

    const getError = (type) => {
        switch (type) {
            case BlockModalTypes.GEOBLOCK:
                return 'E05';
            case BlockModalTypes.CONCURRENCY:
                return 'E08';
            case BlockModalTypes.MVPD_PROTECTION_LEVEL_FULL:
                return 'E09';
            default:
                return 'E02';
        }
    };

    const openBlockedModal = (
        type: BlockModalTypes,
        blocker?: BlockedItem | string,
        modalProps?: any,
        onAction?: (type: BlockModalTypes) => void,
        onClose: () => void = closeModal
    ) => {
        const title =
            type === BlockModalTypes.ACCOUNT
                ? (blocker as any)?.reason || blocker
                : t(`error.${getError(type)}.title`);
        setModalProps(modalProps);
        setModalChildren(
            <BlockedModal
                title={title}
                subtitle={blocker || (t(`error.${getError(type)}.body`) as string)}
                onClose={onClose}
                {...(type !== BlockModalTypes.GEOBLOCK &&
                type !== BlockModalTypes.CONCURRENCY &&
                type !== BlockModalTypes.MVPD_PROTECTION_LEVEL_FULL
                    ? { onAction: () => onAction?.(type) }
                    : {})}
            />
        );
    };

    return { openBlockedModal };
};

export default useShared;
