import{useUserData}from'@24i/nxg-sdk-smartott-shared/src/context/UserData';


var isFunction=function isFunction(obj){return function(key){
return typeof obj[key]==='function';
};};

var isExperienceUserDataClient=function isExperienceUserDataClient(obj){
return[
'loginWithBackstage',
'logoutWithBackstage',
'fetchBackstageUser',
'getBackstageOrganisationListForUser',
'getBackstageServicesForUser',
'getBackstageApplicationsForService'].
every(isFunction(obj));
};







export var useExperienceUserData=function useExperienceUserData(){
var context=useUserData();

if(!isExperienceUserDataClient(context)){
throw new Error(
'The context was not of the expected type. `ExperienceUserDataClient` expected.'
);
}

return context;
};