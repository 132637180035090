import _defineProperty from"@babel/runtime/helpers/defineProperty";import _slicedToArray from"@babel/runtime/helpers/slicedToArray";function ownKeys(object,enumerableOnly){var keys=Object.keys(object);if(Object.getOwnPropertySymbols){var symbols=Object.getOwnPropertySymbols(object);enumerableOnly&&(symbols=symbols.filter(function(sym){return Object.getOwnPropertyDescriptor(object,sym).enumerable;})),keys.push.apply(keys,symbols);}return keys;}function _objectSpread(target){for(var i=1;i<arguments.length;i++){var source=null!=arguments[i]?arguments[i]:{};i%2?ownKeys(Object(source),!0).forEach(function(key){_defineProperty(target,key,source[key]);}):Object.getOwnPropertyDescriptors?Object.defineProperties(target,Object.getOwnPropertyDescriptors(source)):ownKeys(Object(source)).forEach(function(key){Object.defineProperty(target,key,Object.getOwnPropertyDescriptor(source,key));});}return target;}import _regeneratorRuntime from"@babel/runtime/regenerator";import React,{useEffect}from'react';
import PropTypes from'prop-types';
import{Text,Interactable}from'@24i/nxg-sdk-quarks';
import{useTranslation}from'react-i18next';
import{useTheme}from'@24i/nxg-sdk-higgs';
import{useThemeData}from'@24i/nxg-sdk-smartott-shared/src/context/ThemeData';
import{firstLetterUppercase}from'@24i/nxg-core-utils';
import{SettingsOptionPage}from'@24i/nxg-sdk-smartott/src/screens/SettingsScreen';
import{log}from'@24i/nxg-core-utils/src/logger';
import{withWebHeader}from'@24i/nxg-sdk-smartott/src/navigation/components/withWebHeader';

import getSettingsThemeStyles from"./styles";



var SettingsTheme=function SettingsTheme(_ref){var title=_ref.title,getStyles=_ref.styles;
var _useTranslation=useTranslation(),t=_useTranslation.t;
var themeDataClient=useThemeData();
var _useTheme=useTheme(),theme=_useTheme.theme,setTheme=_useTheme.setTheme;

var styles=getStyles(theme);

var _React$useState=React.useState([]),_React$useState2=_slicedToArray(_React$useState,2),themes=_React$useState2[0],setThemes=_React$useState2[1];

useEffect(function(){
var getThemes=function getThemes(){var fetchedThemes;return _regeneratorRuntime.async(function getThemes$(_context){while(1)switch(_context.prev=_context.next){case 0:_context.prev=0;_context.next=3;return _regeneratorRuntime.awrap(

themeDataClient.fetchAllThemes());case 3:fetchedThemes=_context.sent;
setThemes(fetchedThemes);_context.next=10;break;case 7:_context.prev=7;_context.t0=_context["catch"](0);

log(_context.t0);case 10:case"end":return _context.stop();}},null,null,[[0,7]],Promise);};



getThemes();
},[]);

var handleSelectTheme=function handleSelectTheme(themeItem){return _regeneratorRuntime.async(function handleSelectTheme$(_context2){while(1)switch(_context2.prev=_context2.next){case 0:_context2.next=2;return _regeneratorRuntime.awrap(
themeDataClient.selectTheme(themeItem.id));case 2:
setTheme(_objectSpread({
components:theme.components},
themeItem)
);case 3:case"end":return _context2.stop();}},null,null,null,Promise);};


var renderOptions=function renderOptions(){return(
themes.map(function(themeItem,index){return(

React.createElement(Interactable,{
style:[
{
opacity:theme.id===themeItem.id?1:0.4,
':hover':{opacity:1}
},
styles.listItem],

onPress:function onPress(){return handleSelectTheme(themeItem);},
testID:"theme_"+index},

React.createElement(Text,{style:styles.listItemText},themeItem.name)
));}
));};

return(
React.createElement(SettingsOptionPage,{
title:title,
subtitle:firstLetterUppercase(theme.name),
description:t('settings.theme.title'),
renderOptions:renderOptions}
));

};

SettingsTheme.propTypes={
styles:PropTypes.func
};

SettingsTheme.defaultProps={
styles:getSettingsThemeStyles
};

export{getSettingsThemeStyles};
export default withWebHeader(SettingsTheme);