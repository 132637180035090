import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Broadcast } from '@24i/nxg-sdk-photon';
import _ from 'lodash';
import { useFirebase } from '@24i/nxg-sdk-smartott/src/context/Firebase';
import { useStore } from '@24i/nxg-sdk-smartott/src/context/ApplicationStore';
import { useRemindersDataClient } from '@24i/nxg-sdk-smartott-shared/src/context/Reminders';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { useShared } from './useShared';
import { utilsOnRemindPress } from './utils';
import { DetailsScreenProps, DetailsViewProps } from '../types';

const useViewModel = (props: DetailsScreenProps): DetailsViewProps => {
    const { t } = useTranslation();
    const { selectedProfile } = useStore();
    const { crashlyticsLog } = useFirebase();
    const router = useRouter();

    const shared = useShared(props);
    const { reminderIsSet, setReminderIsSet, logReminderError } = shared;
    const { remindersDataClient } = useRemindersDataClient();

    useEffect(() => {
        crashlyticsLog('Details screen entered');
        return crashlyticsLog('Details screen exited');
    }, []);

    async function onRemindPress(broadcast: Broadcast): Promise<void> {
        if (selectedProfile) {
            const { id: profileId } = selectedProfile;
            await utilsOnRemindPress(
                broadcast,
                profileId,
                remindersDataClient,
                reminderIsSet,
                setReminderIsSet,
                logReminderError,
                t
            );
        }
    }

    const onCloseErrorModal = () => router?.back();

    return {
        ...shared,
        ...props,
        onRemindPress,
        onCloseErrorModal,
    };
};

export { useViewModel };
