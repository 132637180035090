import _extends from"@babel/runtime/helpers/extends";import _slicedToArray from"@babel/runtime/helpers/slicedToArray";import _objectWithoutProperties from"@babel/runtime/helpers/objectWithoutProperties";var _excluded=["onSigninPress","handleEmailChange","handleEmailSubmit","handlePasswordChange","handlePasswordSubmit","handleSignInPressTv","handleForgotPasswordPress"];import _regeneratorRuntime from"@babel/runtime/regenerator";


import React,{useState}from'react';
import SignInScreen from'@24i/nxg-sdk-smartott/src/screens/SigninScreen';
import{useFirebase}from'@24i/nxg-sdk-smartott/src/context/Firebase';

import{isPlatformWeb}from'renative';
import{log}from'@24i/nxg-core-utils/src/logger';

import{useStore}from'@24i/nxg-sdk-smartott/src/context/ApplicationStore';
import{useRouter}from'@24i/nxg-core-router/src/NextRouter';
import{useExperienceUserData}from"../../hooks/useUserData";

var ExperienceOverridenSigninScreen=function ExperienceOverridenSigninScreen(props){var _route$params;
var route=props.route,navigation=props.navigation;
var _SignInScreen$useView=








SignInScreen.useViewModel(),onSigninPress=_SignInScreen$useView.onSigninPress,handleEmailChange=_SignInScreen$useView.handleEmailChange,handleEmailSubmit=_SignInScreen$useView.handleEmailSubmit,handlePasswordChange=_SignInScreen$useView.handlePasswordChange,handlePasswordSubmit=_SignInScreen$useView.handlePasswordSubmit,handleSignInPressTv=_SignInScreen$useView.handleSignInPressTv,handleForgotPasswordPress=_SignInScreen$useView.handleForgotPasswordPress,viewModelResultRest=_objectWithoutProperties(_SignInScreen$useView,_excluded);

var _useStore=useStore(),setBackstageUserData=_useStore.setBackstageUserData;
var userDataClient=useExperienceUserData();
var router=useRouter();
var _useFirebase=useFirebase(),recordError=_useFirebase.recordError,crashlyticsLog=_useFirebase.crashlyticsLog;

var _useState=useState(false),_useState2=_slicedToArray(_useState,2),isLoading=_useState2[0],setIsLoading=_useState2[1];
var backstage=isPlatformWeb?router.query.backstage:(_route$params=route.params)==null?void 0:_route$params.backstage;

var customOnSigninPress=function customOnSigninPress(
email,
password){var backstageUser;return _regeneratorRuntime.async(function customOnSigninPress$(_context){while(1)switch(_context.prev=_context.next){case 0:if(!

backstage){_context.next=23;break;}
setIsLoading(true);_context.prev=2;


crashlyticsLog('Attempt to login with backstage');_context.next=6;return _regeneratorRuntime.awrap(
userDataClient.loginWithBackstage(email,password));case 6:_context.next=8;return _regeneratorRuntime.awrap(
userDataClient.fetchBackstageUser());case 8:backstageUser=_context.sent;
setBackstageUserData==null?void 0:setBackstageUserData(backstageUser);
setIsLoading(false);

if(isPlatformWeb){
router.back();
}else{
navigation.goBack();
}return _context.abrupt("return",
{login:true});case 15:_context.prev=15;_context.t0=_context["catch"](2);

recordError(_context.t0);
log(_context.t0);
setIsLoading(false);if(!(
_context.t0 instanceof Error)){_context.next=22;break;}return _context.abrupt("return",
{login:false,message:_context.t0.message});case 22:return _context.abrupt("return",

{login:false,message:JSON.stringify(_context.t0)});case 23:return _context.abrupt("return",


onSigninPress(email,password));case 24:case"end":return _context.stop();}},null,null,[[2,15]],Promise);};


return(
React.createElement(SignInScreen.View,_extends({},
props,{
navigation:navigation,
route:route},
viewModelResultRest,{
onEmailChange:handleEmailChange,
onEmailSubmit:handleEmailSubmit,
onPasswordChange:handlePasswordChange,
onPasswordSubmit:handlePasswordSubmit,
onSignInPressTv:handleSignInPressTv,
onForgotPasswordPress:handleForgotPasswordPress,
onSigninPress:customOnSigninPress,
isLoading:isLoading||viewModelResultRest.isLoading,
signInToBackstage:backstage})
));

};

export default ExperienceOverridenSigninScreen;