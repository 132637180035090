import { QUERY_KEYS } from '@24i/nxg-sdk-photon/src';
import { useQuery } from 'react-query';
import { useThemeData } from '@24i/nxg-sdk-smartott-shared/src/context/ThemeData';

export const useThemeQuery = () => {
    const themeDataClient = useThemeData();

    const { isLoading, data, refetch } = useQuery(QUERY_KEYS.appTheme, themeDataClient.fetchTheme);

    return {
        isLoading,
        theme: data,
        refetch,
    };
};
