import { decode } from 'base-64';
import i18n from 'i18next';
import moment from 'moment';
import 'moment/min/locales';
import { I18nManager } from 'react-native';
import { logWarn } from './logger';
import { allExceptNumbersRegex } from './constants';

interface GradientColors {
    colors: (string | number)[];
    percentages?: number[];
}

export const firstLetterUppercase = (string: string) =>
    string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export const groupWith = <Item, Key extends keyof Item>(
    items: Item[],
    getKeyToGroupBy: (item: Item) => string | number
): Item[][] => {
    const itemsMap = items.reduce((acc, item) => {
        const key = getKeyToGroupBy(item);
        const currentValue = acc[key];

        return {
            ...acc,
            [key]: currentValue ? [...currentValue, item] : [item],
        };
    }, {} as Record<Key, Item[]>);

    return Object.values(itemsMap);
};

export const rgbaStringToRgb = (str: string) => {
    const result = str.replace(/[^\d,.]/g, '').split(',');
    return result
        ? {
              r: parseInt(result[0], 10),
              g: parseInt(result[1], 10),
              b: parseInt(result[2], 10),
          }
        : null;
};

// hex to cmyk converter http://www.javascripter.net/faq/hex2cmyk.htm
export const hexToCMYK = (hex: string): [number, number, number, number] | undefined => {
    let computedC = 0;
    let computedM = 0;
    let computedY = 0;
    let computedK = 0;

    const colorHex = hex.charAt(0) === '#' ? hex.substring(1, 7) : hex;

    if (colorHex.length !== 6) {
        logWarn('hexToCMYK: Invalid length of the input hex value!');
        return undefined;
    }
    if (/[0-9a-f]{6}/i.test(colorHex) !== true) {
        logWarn('hexToCMYK: Invalid digits in the input hex value!');
        return undefined;
    }

    const r = parseInt(colorHex.substring(0, 2), 16);
    const g = parseInt(colorHex.substring(2, 4), 16);
    const b = parseInt(colorHex.substring(4, 6), 16);

    // BLACK
    if (r === 0 && g === 0 && b === 0) {
        computedK = 1;
        return [0, 0, 0, 1];
    }

    computedC = 1 - r / 255;
    computedM = 1 - g / 255;
    computedY = 1 - b / 255;

    const minCMY = Math.min(computedC, Math.min(computedM, computedY));

    computedC = (computedC - minCMY) / (1 - minCMY);
    computedM = (computedM - minCMY) / (1 - minCMY);
    computedY = (computedY - minCMY) / (1 - minCMY);
    computedK = minCMY;

    return [computedC, computedM, computedY, computedK];
};

export function isLightColor(colorValue: string, lessSensitive = false): boolean | undefined {
    // Variables for red, green, blue values
    let r: number;
    let g: number;
    let b: number;

    if (!colorValue) return undefined;
    // Check the format of the color, HEX or RGB?
    if (colorValue.match(/^rgb/)) {
        // If HEX --> store the red, green, blue values in separate variables
        const match = colorValue.match(
            /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );
        if (!match) return undefined;

        [r, g, b] = match.map(Number);
    } else {
        // If RGB --> Convert it to HEX: http://gist.github.com/983661
        const hex = +`0x${colorValue.slice(1).replace(colorValue.length < 5 ? /./g : /./, '$&$&')}`;

        [r, g, b] = [hex >> 16, (hex >> 8) & 255, hex & 255]; // eslint-disable-line no-bitwise
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    /*
     * Using the HSP value, determine whether the color is light or dark
     * EDIT: upped it from 127.5 to make it more prone to display white color
     */
    if (lessSensitive) {
        return hsp > 200;
    }

    return hsp > 127.5;
}

export const preventDenseYellow = (hex: string) => {
    const cmyk = hexToCMYK(hex);

    if (!cmyk) return hex;

    if (cmyk[2] > 0.95 && cmyk[0] < 0.05 && cmyk[1] < 0.05 && cmyk[3] < 0.05) {
        return '#5e5215';
    }

    return hex;
};

export const rgbaToHexCore = (orig: string, android: boolean) => {
    if (orig.charAt(0) === '#') return orig;
    let a: number | string;

    const rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i);
    if (!rgb) return orig;

    const alpha = ((rgb && rgb[4]) || '').trim();

    const [_, r, g, b] = rgb.map(Number);

    /* eslint-disable no-bitwise */
    let hex = rgb
        ? (r | (1 << 8)).toString(16).slice(1) +
          (g | (1 << 8)).toString(16).slice(1) +
          (b | (1 << 8)).toString(16).slice(1)
        : orig;
    if (alpha !== '') {
        a = Number(alpha);
    } else {
        a = 1;
    }
    // Don't apply opacity if opacity's already at 1
    if (a !== 1) {
        // multiply before convert to HEX
        a = ((a * 255) | (1 << 8)).toString(16).slice(1);
        hex = android ? a + hex : hex + a;
    }
    return `#${hex}`;
    /* eslint-enable no-bitwise */
};

// RN needs hex color in #RRGGBBAA format
export const rgbaToHex = (orig: string) => rgbaToHexCore(orig, false);

// Android needs hex color in #AARRGGBB format
export const rgbaToAndroidHex = (orig: string) => rgbaToHexCore(orig, true);

export const hexToArgb = (str: string) => {
    if (str.length > 7) return str.slice(0, 1) + str.slice(-2) + str.slice(1, -2);
    return str;
};

export const hexToRgb = (hex: string) => {
    if (hex && hex.startsWith('rgba')) return rgbaStringToRgb(hex);
    const hexColor = preventDenseYellow(hex);

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null;
};

export const gradientColors = (color: string, opacity = 1) => {
    const rgb = hexToRgb(color);

    if (!rgb) return [];

    const { r, g, b } = rgb;
    return [`rgba(${r},${g},${b}, 0)`, `rgba(${r},${g},${b}, ${opacity})`];
};

export const getGradientColors = (string?: string): GradientColors => {
    const rgbaRegex = /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)/g;
    const percentageRegex = /[\d?.\d]+%/g;

    const colors = string?.match(rgbaRegex);
    const percentages = string?.match(percentageRegex);

    const transformedPercentages = percentages?.map((percentage) => {
        const percentageWithoutSign = percentage.replace('%', '');
        const percentageInteger = parseInt(percentageWithoutSign, 10);
        const percentageDouble = percentageInteger / 100;
        return percentageDouble;
    });

    if (!colors || !transformedPercentages) return { colors: gradientColors('#000000') };
    return { colors, percentages: transformedPercentages };
};

export const transformColorToArgb = (color: string) => {
    let hex = color;
    if (color.match(/^rgb/)) {
        hex = rgbaToHex(color);
    }
    return hexToArgb(hex);
};

export enum CurrencyName {
    USD = 'USD',
    EUR = 'EUR',
    CRC = 'CRC',
    GBP = 'GBP',
    ILS = 'ILS',
    INR = 'INR',
    JPY = 'JPY',
    KRW = 'KRW',
    NGN = 'NGN',
    PHP = 'PHP',
    PLN = 'PLN',
    PYG = 'PYG',
    THB = 'THB',
    UAH = 'UAH',
    VND = 'VND',
    CAD = 'CAD',
    AUD = 'AUD',
}

const currencySymbols: Record<CurrencyName, string> = {
    USD: '$', // US Dollar
    EUR: '€', // Euro
    CRC: '₡', // Costa Rican Colón
    GBP: '£', // British Pound Sterling
    ILS: '₪', // Israeli New Sheqel
    INR: '₹', // Indian Rupee
    JPY: '¥', // Japanese Yen
    KRW: '₩', // South Korean Won
    NGN: '₦', // Nigerian Naira
    PHP: '₱', // Philippine Peso
    PLN: 'zł', // Polish Zloty
    PYG: '₲', // Paraguayan Guarani
    THB: '฿', // Thai Baht
    UAH: '₴', // Ukrainian Hryvnia
    VND: '₫', // Vietnamese Dong};
    CAD: 'CA$', // Canadian Dollar;
    AUD: 'AU$', // Australian Dollar;
};

export const getCurrencySymbol = (currencyName: CurrencyName) => currencySymbols?.[currencyName];

const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

const months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
];

type TimeFormatter = (startDate: Date, endDate: Date, is12HourClock: boolean) => string;

let timeFormatter: TimeFormatter;

export const setCustomTimeFormatter = (formatter: TimeFormatter) => {
    timeFormatter = formatter;
};

export const timeText = (
    startDate: Date,
    endDate: Date,
    is12HourClock: boolean,
    isLiveEvent?: boolean,
    isLive?: boolean,
    isSingleRow?: boolean
) => {
    if (timeFormatter) {
        return timeFormatter(startDate, endDate, is12HourClock);
    }
    let startHours = startDate.getHours();
    const startMinutes = startDate.getMinutes();
    let endHours = endDate.getHours();
    const endMinutes = endDate.getMinutes();
    const startampm = startHours >= 12 ? ' PM' : ' AM';
    const endampm = endHours >= 12 ? 'PM' : 'AM';

    if (is12HourClock) {
        startHours %= 12;
        endHours %= 12;
    }

    const [startHoursString, endHoursString, startMinutesString, endMinutesString] = [
        startHours,
        endHours,
        startMinutes,
        endMinutes,
    ].map((value) => (value < 10 ? `0${value}` : value.toString()));
    const startTime = `${startHoursString}:${startMinutesString}${is12HourClock ? startampm : ''}`;
    const endTime = `${endHoursString}:${endMinutesString} ${is12HourClock ? endampm : ''}`;

    if (isLive && isSingleRow) {
        const ltrText = `${startTime} - ${endTime}`;
        const rtlText = `${endTime} - ${startTime}`;
        return I18nManager.isRTL ? rtlText : ltrText;
    }

    if (isLiveEvent) {
        return startTime;
    }
    return `${startTime} - ${endTime}`;
};

export const timeDateText = (
    startDate: Date,
    endDate: Date,
    is12HourClock: boolean,
    isLiveEvent?: boolean,
    isLive?: boolean,
    isSingleRow?: boolean,
    showOnlyHours?: boolean
) => {
    const date = new Date();
    const isNow = startDate.getTime() <= date.getTime() && endDate.getTime() >= date.getTime();
    const timeAsText = timeText(
        startDate,
        endDate,
        is12HourClock,
        isLiveEvent,
        isLive,
        isSingleRow
    );
    const month = months[startDate.getMonth()].toUpperCase().substring(0, 3);
    const day = startDate.getDate();
    const weekDay = days[startDate.getDay()].toUpperCase().substring(0, 3);

    if (isLive && isSingleRow) {
        return `${i18n.t('date.now')} · ${timeAsText}`;
    }

    if ((isNow && !isLiveEvent) || showOnlyHours) {
        return timeAsText;
    }

    return `${weekDay}, ${month} ${day} · ${timeAsText}`;
};

export const dateTimeText = (
    startDate: Date,
    endDate: Date,
    is12HourClock: boolean,
    displayTimeOnly = false,
    isLiveEvent?: boolean
) => {
    const timeAsText = timeText(startDate, endDate, is12HourClock, isLiveEvent);

    if (displayTimeOnly) return timeAsText;

    const month = months[startDate.getMonth()].toUpperCase().substring(0, 3);
    const day = startDate.getDate();
    const year = startDate.getFullYear();
    return `${month} ${day}, ${year} · ${timeAsText}`;
};

export const dayOfWeek = (date: Date, day: number) =>
    days[new Date(+date + day * (1000 * 60 * 60 * 24)).getDay()];

export const monthName = (date: Date, day: number) =>
    months[new Date(+date + day * (1000 * 60 * 60 * 24)).getMonth()];

export const dayText = (day: number): string | string[] => {
    const now = new Date();
    /* eslint-disable no-nested-ternary */
    return day === 0
        ? 'today'
        : day === -1
        ? 'yesterday'
        : day === 1
        ? 'tomorrow'
        : [
              dayOfWeek(now, day),
              monthName(now, day),
              new Date(now.getTime() + day * 24 * 60 * 60000).getDate().toString(),
          ];
    /* eslint-enable no-nested-ternary */
};

export const dateDiffInDays = (a: Date, b: Date) => {
    const MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / MS_PER_DAY);
};

export const formatDurationText = (ms: number): string => {
    let m: number;
    let s: number;

    s = Math.floor(ms / 1000);
    m = Math.floor(s / 60);
    s %= 60;
    const h = Math.floor(m / 60);
    m %= 60;
    if (h === 0) {
        return `${m}m`;
    }

    if (m === 0 || h > 99 || m < 1) {
        return `${h}h`;
    }
    return `${h}h ${m}m`;
};

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const isLessThenXDays = (numberOfDays: number, start: number) => {
    const daysInMs = numberOfDays * 24 * 60 * 60 * 1000;
    return Date.now() - daysInMs < start;
};

export const isNumberValue = (value: string) => !allExceptNumbersRegex.test(value);

export const validatePin = (inputPin: string, pinLength = 4) =>
    inputPin.length < pinLength || !isNumberValue(inputPin);

export const checkCorrectPin = (e: any) => {
    const value = e.key;
    if (!isNumberValue(value) && value !== 'Backspace' && value !== 'Delete') {
        e.preventDefault();
    }
};

export const getDurationString = (
    durationSeconds: number,
    t: (text: string) => string
): string | null => {
    if (!durationSeconds) return null;
    const minString = t('date.min');
    const totalMins = Math.round(durationSeconds / 60);
    const hrs = Math.floor(totalMins / 60);
    const mins = totalMins % 60;

    if (!hrs) return `${mins || 1} ${t(minString)}`;
    return `${hrs} ${t('date.h')} ${mins} ${minString}`;
};

// Function to decode JWT tokens (both Backstage token and the Cleeng token that is wrapped inside of it).
// The function code has been extracted from the decodedToken function (packages/core@utils/src/decodedToken.ts)
export const decodeBase64Token = (token: string) => {
    const base64Url = token?.split('.')[1];
    const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload =
        base64 &&
        decodeURIComponent(
            decode(base64)
                .split('')
                .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
                .join('')
        );
    const result = jsonPayload && JSON.parse(jsonPayload);

    return result;
};

export const formatFullReleaseDate = (fullReleaseDate: string, currentLocale: string) => {
    const date = new Date(fullReleaseDate);
    moment.locale(currentLocale);

    return moment(date).format('ll');
};
