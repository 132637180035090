import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import {
    NavConfig,
    NavConfigWeb,
    NavScreenConfigWeb,
} from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import {
    findPageInNavConfigByMatchingRoute,
    isWebNavConfig,
} from '@24i/nxg-sdk-gluons/src/lib/navigation';
import { QUERY_KEYS } from '@24i/nxg-sdk-photon/src';
import { getRuntimeConfig } from '@24i/nxg-sdk-smartott/src/Application/initApp';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { isPlatformWeb } from 'renative';
import { useAppNavigation } from '../../context/AppNavigation';
import { useAppConfigQuery } from './useAppConfigQuery';
import { useMenuConfigQuery } from './useMenuConfigQuery';
import { useProfilesQuery } from './useProfilesQuery';
import { useUserQuery } from './user/useUserQuery';

const emptyParams = {};

export const useNavigationConfigQuery = () => {
    const { appSettings } = useAppConfigQuery();
    const { client } = useAppNavigation();
    const { menuConfig } = useMenuConfigQuery();
    const { profiles, selectedProfile } = useProfilesQuery();
    const { t } = useTranslation();
    const { user } = useUserQuery();
    const runtimeConfigMenu = getRuntimeConfig('menu');
    const router = useRouter();

    const routeParams = isPlatformWeb ? router.query : emptyParams;
    const { isLoading, data, refetch } = useQuery<
        { config: NavConfig; currentPageConfig: NavScreenConfigWeb | undefined } | undefined
    >(
        QUERY_KEYS.navigationConfig,
        async () => {
            if (!menuConfig || !appSettings) {
                return undefined;
            }

            const config = await client.getNavigationConfig(menuConfig, runtimeConfigMenu, {
                t,
                user,
                profiles,
                selectedProfile,
                features: appSettings.features,
                params: routeParams,
            });

            let currentPageConfig;

            if (isWebNavConfig(config)) {
                currentPageConfig = findPageInNavConfigByMatchingRoute(
                    { href: router.route, asPath: router.asPath },
                    {
                        navigationConfig: config as NavConfigWeb,
                    }
                );
            }

            return {
                config,
                currentPageConfig,
            };
        },
        {
            enabled: Boolean(menuConfig && appSettings),
        }
    );

    useEffect(() => {
        if (menuConfig && appSettings) {
            refetch();
        }
    }, [user?.id, profiles, selectedProfile, menuConfig, routeParams, appSettings]);

    return {
        isLoading,
        navigationConfig: data?.config,
        currentPageConfig: data?.currentPageConfig,
        refetch,
    };
};
