import _extends from"@babel/runtime/helpers/extends";import React from'react';
import{log}from'@24i/nxg-core-utils/src/logger';
import{useTranslation}from'react-i18next';
import'@adyen/adyen-web/dist/adyen.css';
import'react-loading-skeleton/dist/skeleton.css';

import App from"../app";

var vitals=[];
export function reportWebVitals(metric){
vitals.push(metric);
if(process.browser){
log("reportWebVitals: '"+JSON.stringify(metric)+"'");
}
}

var footerIcons=[
{
font:'fontAwesome',
name:'twitter',
href:'https://twitter.com/24i_media'
},
{
font:'fontAwesome',
name:'instagram',
href:'https://www.instagram.com/24imedia/'
},
{
font:'fontAwesome',
name:'facebook',
href:'https://www.facebook.com/twentyfouri/'
},
{
font:'fontAwesome',
name:'home',
href:'https://www.24i.com/'
}];


var WebApp=function WebApp(props){
var _useTranslation=useTranslation(['sott']),t=_useTranslation.t;

var footerLinks=[
{title:t('footer.contactUs'),href:'https://www.24i.com/contact/'},
{title:t('footer.news'),href:'https://www.24i.com/articles/'}];


return React.createElement(App,_extends({footerLinks:footerLinks,footerIcons:footerIcons},props));
};

export default WebApp;