import { ASSET_TYPE, Channel, PinProtectionScopes } from '@24i/nxg-sdk-photon';
import CellModel from '@24i/nxg-sdk-epg/models/Cell';
import { useEffect, useRef, useState } from 'react';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { usePinProtection } from '@24i/nxg-sdk-pin-protection/src/context/PinProtection';
import { useDebounceFn } from 'ahooks';
import { LandscapeEpgScroll } from '@24i/nxg-sdk-epg/src/types';
import { EPGProps } from '@24i/nxg-sdk-epg/src/web/types';
import { useEpgCache } from '../../../hooks/query/epg/useEpgCache';
import { WebEpgViewProps } from '../View/types';
import { SOTT_DEFAULT_WEB_SCREENS } from '../../../navigation/constants';
import useChannelsQuery from '../../../hooks/query/epg/useChannelsQuery';
import { useSelectedDate } from '../hooks/useSelectedDate';

const useViewModel = (props: WebEpgViewProps): WebEpgViewProps => {
    const router = useRouter();
    const { query } = router;
    const { data: allChannels } = useChannelsQuery();
    const { selectedDate, changeSelectedDate, pickerDates, resetDateToCurrentDate } =
        useSelectedDate({
            ...props,
            initialDate: query.date ? new Date(Number(query.date)) : undefined,
        });
    const [channelIndex, setChannelIndex] = useState({});

    const getInitialScrollValue = () => {
        if (query.scrollTop && query.scrollLeft) {
            return { scrollTop: Number(query.scrollTop), scrollLeft: Number(query.scrollLeft) };
        }
        return undefined;
    };

    const scrollValue = useRef<LandscapeEpgScroll | undefined>(getInitialScrollValue());

    // when clicking the top menu tv guide item again it should
    // reset to live position
    useEffect(() => {
        if (
            query.scrollTop !== undefined ||
            query.scrollLeft !== undefined ||
            query.date !== undefined
        )
            return;

        scrollValue.current = undefined;
        resetDateToCurrentDate();
    }, [query]);

    const { data, isFetching, isLoading } = useEpgCache({
        channels: allChannels?.filter(
            (channel) => (channelIndex[selectedDate.date.toString()] ?? {})[channel.id]
        ),
        startTime: new Date(selectedDate.date).setHours(0, 0, 0, 0),
        endTime: new Date(selectedDate.date).setHours(24, 0, 0, 0),
    });

    const mergeChannels = (channels: Channel[], newChannels: Channel[]) => {
        return channels.map((channel) => {
            const newChannel = newChannels.find((ch) => ch.id === channel.id);
            if (newChannel) return newChannel;
            return channel;
        });
    };

    const mergedData = mergeChannels(allChannels ?? [], data ?? []);

    const setData = () => {};
    const { arePinScopesValidated } = usePinProtection();

    const getQuery = () => {
        let newQuery = query;
        if (scrollValue.current) {
            newQuery = {
                ...newQuery,
                scrollLeft: scrollValue.current.scrollLeft.toString(),
                scrollTop: scrollValue.current.scrollTop.toString(),
            };
        }

        if (selectedDate) {
            newQuery = {
                ...newQuery,
                date: selectedDate.date.getTime().toString(),
            };
        }

        return newQuery;
    };

    const routerReplace = async () =>
        router.replace({
            pathname: SOTT_DEFAULT_WEB_SCREENS.TvGuide.getLink().href,
            query: getQuery(),
        });

    const onCellPress = async (cell: CellModel): Promise<boolean> => {
        const detailsLink = SOTT_DEFAULT_WEB_SCREENS.Details.getLink({
            type: ASSET_TYPE.BROADCAST,
            id: cell.data.id || '',
        });

        await routerReplace();

        return router.push({
            pathname: detailsLink.href,
            query: {
                type: ASSET_TYPE.BROADCAST,
                id: cell.data.id,
                channelId: cell.data.channelId,
            },
        });
    };

    const onScroll = (value) => {
        scrollValue.current = value;
    };

    const onWebViewportScroll: EPGProps['onWebViewportScroll'] = (scroll) => {
        const {
            channels: { start, end },
        } = scroll;
        const result = {
            ...channelIndex,
        };
        if (!result[selectedDate.date.toString()]) result[selectedDate.date.toString()] = {};
        allChannels?.slice(start, end + 1).forEach((ch) => {
            result[selectedDate.date.toString()][ch.id] = true;
        });
        setChannelIndex(result);
    };

    const { run: debouncedScroll } = useDebounceFn(onWebViewportScroll, { wait: 100 });

    const getScrollLeft = () => {
        if (
            scrollValue.current?.scrollLeft !== undefined &&
            !Number.isNaN(scrollValue.current?.scrollLeft)
        ) {
            return scrollValue.current?.scrollLeft;
        }
        return undefined;
    };

    return {
        ...props,
        data: mergedData,
        setData,
        selectedDate,
        isLoading,
        isFetching,
        onScroll,
        onSelectionPress: changeSelectedDate,
        pickerDates,
        scrollLeft: getScrollLeft(),
        scrollTop: scrollValue.current?.scrollTop || 0,
        shouldBeLocked: !arePinScopesValidated?.([PinProtectionScopes.adultControl]),
        onWebViewportScroll: debouncedScroll,
        onCellPress,
    };
};

export { useViewModel };
