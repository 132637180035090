import React from 'react';
import { useTranslation } from 'react-i18next';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { View, FlatList, Text } from '@24i/nxg-sdk-quarks';
import { SOTT_DEFAULT_SCREENS } from '@24i/nxg-sdk-smartott/src/navigation/constants';
import { useTriggerOnScroll } from '@24i/nxg-sdk-smartott-shared/src/analytics/navigation/useTriggerOnScroll';
import { useTrackBuffering } from '@24i/nxg-sdk-smartott-shared/src/analytics/hooks/useTrackBuffering';
import { PRODUCT_SCREENS } from '@24i/nxg-sdk-photon';
import ErrorMessage from '../../../components/ErrorMessage';
import getDynaRowScreenStyles from '../styles';
import LoadingWrapper from '../../../components/LoadingWrapper/View/index';
import { DynaRowViewProps } from '../types';
import DynaRowSection from '../components/DynaRowSection';
import { ErrorMessageProps } from '../../../components/ErrorMessage/types';
import {
    withPurchaseModal,
    WithPurchasesModalProps,
} from '../../../components/PurchasesModal/utils/withPurchasesModal';

export const defaultRenderErrorMessage = (props: ErrorMessageProps) => <ErrorMessage {...props} />;

const DynaRowScreen = (props: DynaRowViewProps & WithPurchasesModalProps) => {
    const {
        styles: getStyles = getDynaRowScreenStyles,
        sections,
        hasError,
        isLoading,
        renderErrorMessage = defaultRenderErrorMessage,
        handlePackshotPress,
        renderBannerCarousel,
        renderSingleRow,
        renderSlider,
        renderPackshotGrid,
        handleSeeAllItemPress,
        openPurchaseModal,
        pageName,
        pageTitle,
        hideTitle,
    } = props;
    useTrackBuffering({ sections, screen: PRODUCT_SCREENS.HOME });
    useTriggerOnScroll();

    const { theme } = useTheme();
    const styles = getStyles(theme);
    const { t } = useTranslation(['sott']);

    const renderBrowseTitle = () => {
        // @ts-expect-error
        if (!SOTT_DEFAULT_SCREENS.HOME.includes(pageName) && !hideTitle) {
            if (pageTitle) {
                return (
                    <View style={styles.browseTitleContainer}>
                        <Text style={styles.browseTitle}>{pageTitle}</Text>
                    </View>
                );
            }
        }
        return <></>;
    };

    if (hasError) return renderErrorMessage({ message: t('error.A00.title') });

    return (
        <View style={styles.app}>
            <LoadingWrapper
                isLoading={!!isLoading}
                loaderProps={{
                    size: 'large',
                    holderStyles: styles.activityIndicator,
                }}
                wrapperStyle={styles.wrapper}
            >
                {renderBrowseTitle()}
                <FlatList
                    data={sections}
                    renderItem={({ item: section, index }) => (
                        <DynaRowSection
                            index={index}
                            section={section}
                            handlePackshotPress={(params) =>
                                handlePackshotPress?.({
                                    ...params,
                                    onPurchaseAsset: openPurchaseModal,
                                })
                            }
                            handleSeeAllItemPress={handleSeeAllItemPress}
                            renderBannerCarousel={renderBannerCarousel}
                            renderSingleRow={renderSingleRow}
                            renderSlider={renderSlider}
                            renderPackshotGrid={renderPackshotGrid}
                        />
                    )}
                    keyExtractor={(section) => section.id}
                    contentContainerStyle={styles.holder}
                />
            </LoadingWrapper>
        </View>
    );
};

export { getDynaRowScreenStyles };
export default overridable(withPurchaseModal(DynaRowScreen), 'DynaRowScreen');
