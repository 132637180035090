/* eslint-disable @typescript-eslint/naming-convention */
import { ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics/constants';
import { isPlatformTvos } from 'renative';
import { Payload, ScrollResult } from '../types';
import { EVENTS } from '../constants';
import { mapTagsParams } from './mapTagsParams';

export const mapScroll = (triggerName: ANALYTICS_TRIGGERS, payload: Payload): ScrollResult => {
    const percent_scrolled = triggerName.split('_')[1];
    return {
        ...(isPlatformTvos ? { ...mapTagsParams(payload) } : {}),
        language: payload.language,
        session_id: payload.payload.sessionId,
        event: EVENTS.SCROLL,
        event_trigger: triggerName,
        percent_scrolled,
    };
};
