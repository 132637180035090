import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useAppConfigQuery } from '@24i/nxg-sdk-smartott/src/hooks/query/useAppConfigQuery';
import { useThemeData } from '@24i/nxg-sdk-smartott-shared/src/context/ThemeData';
import { log } from '@24i/nxg-core-utils/src/logger';
import { ThemeContext } from './context/Theme';

const Theme = ({ theme, children }) => {
    const { appSettings } = useAppConfigQuery();
    const [selectedTheme, setSelectedTheme] = useState(theme);
    const [isFinalTheme, setIsFinalTheme] = useState(false);
    const themeDataClient = useThemeData();
    useEffect(() => {
        const getThemes = async () => {
            try {
                const fetchedThemes = await themeDataClient.fetchAllThemes();
                const { defaultThemeId } = appSettings?.features?.themes || {};
                const currentTheme = fetchedThemes.find((t) => t.id === defaultThemeId);
                if (!_.isEmpty(currentTheme)) {
                    setSelectedTheme(currentTheme);
                    setIsFinalTheme(true);
                }
            } catch (err) {
                log(err);
            }
        };
        getThemes();
    }, []);

    const themeContextValue = {
        theme: selectedTheme,
        finalTheme: isFinalTheme,
        setTheme: setSelectedTheme,
    };
    return <ThemeContext.Provider value={themeContextValue}>{children}</ThemeContext.Provider>;
};

export default Theme;
