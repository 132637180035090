import React, { createContext, useContext } from 'react';

import { UserDataClient } from '@24i/nxg-sdk-photon';
import { getDefaultUserDataClient } from './defaultClient';

const dummyDataClient = getDefaultUserDataClient();

export const UserDataContext = createContext<UserDataClient>(dummyDataClient);

export const UserDataProvider = ({ client = dummyDataClient, children }) => (
    <UserDataContext.Provider value={client}>{children}</UserDataContext.Provider>
);

export const useUserData = () => useContext(UserDataContext);
