import { createEnum, nullable, withEmptyArray } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';

enum IconStates {
    ACTIVE = 'active',
}

const PngGuard = t.type(
    {
        url: t.string,
        size: t.type(
            {
                width: t.number,
                height: t.number,
            },
            'PngSize'
        ),
    },
    'Png'
);

const StateGuard = t.type(
    {
        svg: t.string,
        png: t.array(PngGuard),
    },
    'State'
);

export const StatesGuard = t.record(
    createEnum<IconStates>(IconStates, 'IconStates'),
    StateGuard,
    'States'
);

const Icon = t.type(
    {
        id: t.string,
        name: t.string,
        svg: t.string,
        states: t.union([StatesGuard, t.UnknownArray]), // in some cases API returns empty array
        createdAt: nullable(t.union([t.string, t.number])),
        updatedAt: nullable(t.string),
        png: t.array(PngGuard),
    },
    'Icon'
);

export const IconsGuard = t.record(t.string, Icon, 'Icons');

export const CustomIconsResponseGuard = t.type(
    {
        icons: withEmptyArray(IconsGuard),
    },
    'CustomIconResponse'
);
