import _defineProperty from"@babel/runtime/helpers/defineProperty";function ownKeys(object,enumerableOnly){var keys=Object.keys(object);if(Object.getOwnPropertySymbols){var symbols=Object.getOwnPropertySymbols(object);enumerableOnly&&(symbols=symbols.filter(function(sym){return Object.getOwnPropertyDescriptor(object,sym).enumerable;})),keys.push.apply(keys,symbols);}return keys;}function _objectSpread(target){for(var i=1;i<arguments.length;i++){var source=null!=arguments[i]?arguments[i]:{};i%2?ownKeys(Object(source),!0).forEach(function(key){_defineProperty(target,key,source[key]);}):Object.getOwnPropertyDescriptors?Object.defineProperties(target,Object.getOwnPropertyDescriptors(source)):ownKeys(Object(source)).forEach(function(key){Object.defineProperty(target,key,Object.getOwnPropertyDescriptor(source,key));});}return target;}import{getFont}from'@24i/nxg-sdk-higgs';

var getSettingsThemeStyles=function getSettingsThemeStyles(theme){return{
listItem:{
flexDirection:'row',
alignItems:'center',
flex:1,
marginVertical:12,
width:'100%'
},
listItemText:_objectSpread({
color:""+theme.color.menuItemActive},
getFont(theme,'menuItem-inactive'))

};};

export default getSettingsThemeStyles;