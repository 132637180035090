import { ASYNC_STORAGE_KEY_USER_LANGUAGE } from '@24i/nxg-core-utils/src/constants';
import {
    FocusedSectionId,
    IsTablet,
    LastFocusedPageTags,
    LayoutScrollView,
    RuntimeConfig,
    TvDrawer,
} from '@24i/nxg-core-utils/src/globalSingletons';
import { omitUndefinedOrNull } from '@24i/nxg-core-utils/src/object';
import { DeviceInfo, Storage } from '@24i/nxg-sdk-quantum';
import { languageDetector } from '@24i/nxg-sdk-quarks/src/i18n/languageDetector';
import { i18nSottDefault } from '@24i/nxg-sdk-smartott-defaults/src/configs/locale';
import deepmerge from 'deepmerge';
import i18n, { InitOptions } from 'i18next';
import ICU from 'i18next-icu';
import { RuntimeConfigType } from '@24i/nxg-sdk-photon/src';
import { initReactI18next } from 'react-i18next';
import { isPlatformWeb, isPlatformAndroid } from 'renative';
import registerIconFonts from './registerIconFonts';

type config = Partial<Record<string, any>>;
type appConfigProps = {
    i18nOptions: Partial<InitOptions>;
};
type appConfig = (props: appConfigProps) => void;

const removeInvalidResources = (i18nConfig: InitOptions): InitOptions => {
    return {
        ...i18nConfig,
        resources: omitUndefinedOrNull(i18nConfig.resources || {}),
    };
};

/**
 * We are using i18next-icu to work with ICU format and this library uses intl-messageformat to get the message and form the appropriate string.
 * But intl-messageformat needs that our device has runtime built-in intl support, which Android JSC dependecy from react-native doesn't have.
 * This was causing issues, like plural labels in ICU format were not recognized in android devices in PROD or when Debugger is turned off.
 * For this reason, we can use polyfills that will contain scripts fullfill this need as recommended in formatJS (intl-messageformat) documentation.
 * Polyfills should be loaded 1st BEFORE loading data.
 * https://github.com/formatjs/formatjs/issues/2926
 */
export const importLocalePolyfills = async (): Promise<void> => {
    await import('@formatjs/intl-getcanonicallocales/polyfill');
    await import('@formatjs/intl-locale/polyfill');
    await import('@formatjs/intl-pluralrules/polyfill');

    /** Should add manually support for each new language since can't use dynamic imports due to being resolved at compilation time and not running time.
     *  Sugestions to handle this are welcome!
     */
    await import('@formatjs/intl-pluralrules/locale-data/en');
    await import('@formatjs/intl-pluralrules/locale-data/de');
    await import('@formatjs/intl-pluralrules/locale-data/es');
    await import('@formatjs/intl-pluralrules/locale-data/fr');
};

// initialize locale for translation
export const initLocale = (i18nConfig: InitOptions) => {
    if (isPlatformAndroid) {
        importLocalePolyfills();
    }

    const cfg = removeInvalidResources(i18nConfig);

    i18n.use(languageDetector(cfg)).use(ICU).use(initReactI18next).init(cfg);
    i18n.on('languageChanged', (language) => {
        Storage.setItem(ASYNC_STORAGE_KEY_USER_LANGUAGE, language);
    });
};

const DISABLE_LOGS = !__DEV__; // eslint-disable-line no-undef

const initApp: appConfig = ({ i18nOptions }) => {
    // focus related globals
    LastFocusedPageTags.clear();
    IsTablet.set(DeviceInfo.isTablet());
    LayoutScrollView.clear();
    TvDrawer.clear();
    FocusedSectionId.set(0);

    registerIconFonts();

    if (!i18n.isInitialized) {
        if (i18nOptions) {
            const mergedOptions = deepmerge<InitOptions>(i18nSottDefault, i18nOptions);
            initLocale(mergedOptions);
        } else {
            initLocale(i18nSottDefault);
        }
    }

    if (DISABLE_LOGS && !isPlatformWeb) {
        const console = (function (_a) {
            return {
                log() {},
                info() {},
                warn() {},
                error() {},
                debug() {},
                assert() {},
            };
        })(window.console);
        // @ts-ignore mocking console signature is complex
        window.console = console;
    }

    console.disableYellowBox = true; // eslint-disable-line
};

export type RuntimeConfigTypeReturnType<T extends keyof RuntimeConfigType | undefined> =
    T extends keyof RuntimeConfigType ? RuntimeConfigType[T] : RuntimeConfigType;

export function getRuntimeConfig<T extends keyof RuntimeConfigType>(
    key?: T
): RuntimeConfigTypeReturnType<T> | never {
    const config = RuntimeConfig.get();

    if (key) {
        if (!(key in config)) {
            throw new Error(
                `Missing ${key} in runtime config. Ensure it is provided in renative configuration file.`
            );
        }

        return config[key] as RuntimeConfigTypeReturnType<T>;
    }

    return config as RuntimeConfigTypeReturnType<T>;
}

export const getMergedRuntimeConfig = (runtimeConfig: config, defaultRuntimeConfig: config) => {
    RuntimeConfig.set(deepmerge(defaultRuntimeConfig, runtimeConfig, { arrayMerge: (d, s) => s }));
    return RuntimeConfig.get();
};

export default initApp;
