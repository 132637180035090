import _slicedToArray from"@babel/runtime/helpers/slicedToArray";import _createClass from"@babel/runtime/helpers/createClass";import _classCallCheck from"@babel/runtime/helpers/classCallCheck";import _toConsumableArray from"@babel/runtime/helpers/toConsumableArray";import _defineProperty from"@babel/runtime/helpers/defineProperty";import _regeneratorRuntime from"@babel/runtime/regenerator";function ownKeys(object,enumerableOnly){var keys=Object.keys(object);if(Object.getOwnPropertySymbols){var symbols=Object.getOwnPropertySymbols(object);enumerableOnly&&(symbols=symbols.filter(function(sym){return Object.getOwnPropertyDescriptor(object,sym).enumerable;})),keys.push.apply(keys,symbols);}return keys;}function _objectSpread(target){for(var i=1;i<arguments.length;i++){var source=null!=arguments[i]?arguments[i]:{};i%2?ownKeys(Object(source),!0).forEach(function(key){_defineProperty(target,key,source[key]);}):Object.getOwnPropertyDescriptors?Object.defineProperties(target,Object.getOwnPropertyDescriptors(source)):ownKeys(Object(source)).forEach(function(key){Object.defineProperty(target,key,Object.getOwnPropertyDescriptor(source,key));});}return target;}import{isPlatformWeb}from'renative';

import{

createBackstageEpgDataClient}from
'@24i/nxg-integration-backstage/src/clients/EpgDataClient';

import{getRuntimeConfig}from'@24i/nxg-sdk-smartott/src/Application/initApp';
import{
EpgDataClientStub,
STUB_CHANNEL_EPG_SEPARATOR}from
'@24i/nxg-sdk-smartott-stubs/src/clients/EpgDataClient';

var DEFAULT_CHANNEL_MULTIPLIER=1;

var stubCatch=function stubCatch(e){

console.error('Error in stub epg request',e);
return[];
};

var backstageCatch=function backstageCatch(e){

console.error('Error in backstage epg request',e);
return[];
};

var getChannelId=function getChannelId(id){var _getRuntimeConfig;
if(!((_getRuntimeConfig=getRuntimeConfig('features'))!=null&&_getRuntimeConfig.loadEpgFakeData))return id;
return id.split(STUB_CHANNEL_EPG_SEPARATOR)[0];
};

var addIndexToId=function addIndexToId(originalChannels,result){
var processedChannellIndex={};
return result.
map(function(ch){var _ch$programs;
var channel=originalChannels.find(
function(c){return c.id.includes(ch.id)&&!processedChannellIndex[c.id];}
);
if(!channel)return null;
processedChannellIndex[channel.id]=true;
var id=""+ch.id+STUB_CHANNEL_EPG_SEPARATOR+
channel.id.split(STUB_CHANNEL_EPG_SEPARATOR)[1];

return _objectSpread(_objectSpread({},
ch),{},{
programs:(ch==null?void 0:(_ch$programs=ch.programs)==null?void 0:_ch$programs.map(function(p){return _objectSpread(_objectSpread({},p),{},{channelId:id});}))||[],
id:id});

}).
filter(Boolean);
};

var multiplyChannels=function multiplyChannels(
channels)

{var times=arguments.length>1&&arguments[1]!==undefined?arguments[1]:DEFAULT_CHANNEL_MULTIPLIER;
var result=Array.from({length:times}).reduce(
function(acc,_,index){var _channels$map;return[].concat(_toConsumableArray(
acc),_toConsumableArray((_channels$map=
channels==null?void 0:channels.map(function(ch){return _objectSpread(_objectSpread({},
ch),{},{
number:Number(ch.number)*(index+1),
id:""+ch.id+STUB_CHANNEL_EPG_SEPARATOR+index});}
))!=null?_channels$map:[]));},

[]
);
return result;
};



export var ExperienceEpgDataClient=_createClass(




function ExperienceEpgDataClient(params){var _this=this;_classCallCheck(this,ExperienceEpgDataClient);this.




getEpgData=function _callee(){var _getRuntimeConfig2,_this$backstageEpgCli,_getRuntimeConfig3,_this$backstageEpgCli2,_this$stubEpgClient2;var _getRuntimeConfig4,_this$stubEpgClient,basicEpg,_await$Promise$all,_await$Promise$all2,_backstage,_stub,_await$Promise$all3,_await$Promise$all4,backstage,stub,_args=arguments;return _regeneratorRuntime.async(function _callee$(_context){while(1)switch(_context.prev=_context.next){case 0:if((_getRuntimeConfig2=
getRuntimeConfig('features'))!=null&&_getRuntimeConfig2.loadEpgFakeData){_context.next=2;break;}return _context.abrupt("return",
(_this$backstageEpgCli=_this.backstageEpgClient).getEpgData.apply(_this$backstageEpgCli,_args));case 2:if(!((_getRuntimeConfig3=
getRuntimeConfig('features'))!=null&&_getRuntimeConfig3.basicEpg)){_context.next=11;break;}
basicEpg=(_getRuntimeConfig4=getRuntimeConfig('features'))==null?void 0:_getRuntimeConfig4.basicEpg;_context.next=6;return _regeneratorRuntime.awrap(
Promise.all([
_this.backstageEpgClient.
getEpgData(_args.length<=0?undefined:_args[0],basicEpg==null?void 0:basicEpg.daysToRequest,_args.length<=2?undefined:_args[2]).
catch(backstageCatch),
(_this$stubEpgClient=_this.stubEpgClient).getEpgData.apply(_this$stubEpgClient,_args).catch(stubCatch)]
));case 6:_await$Promise$all=_context.sent;_await$Promise$all2=_slicedToArray(_await$Promise$all,2);_backstage=_await$Promise$all2[0];_stub=_await$Promise$all2[1];return _context.abrupt("return",[].concat(_toConsumableArray(

_stub),_toConsumableArray(_backstage)));case 11:_context.next=13;return _regeneratorRuntime.awrap(


Promise.all([
(_this$backstageEpgCli2=_this.backstageEpgClient).getEpgData.apply(_this$backstageEpgCli2,_args).catch(backstageCatch),
(_this$stubEpgClient2=_this.stubEpgClient).getEpgData.apply(_this$stubEpgClient2,_args).catch(stubCatch)]
));case 13:_await$Promise$all3=_context.sent;_await$Promise$all4=_slicedToArray(_await$Promise$all3,2);backstage=_await$Promise$all4[0];stub=_await$Promise$all4[1];return _context.abrupt("return",[].concat(_toConsumableArray(
stub),_toConsumableArray(backstage)));case 18:case"end":return _context.stop();}},null,null,null,Promise);};this.


getEpgDataInRange=function _callee2(range,channels){var _getRuntimeConfig5;var newChannels,_await$Promise$all5,_await$Promise$all6,backstage,stub,result;return _regeneratorRuntime.async(function _callee2$(_context2){while(1)switch(_context2.prev=_context2.next){case 0:
newChannels=channels.map(function(channel){return _objectSpread(_objectSpread({},
channel),{},{
id:getChannelId(channel.id)});}
);if((_getRuntimeConfig5=
getRuntimeConfig('features'))!=null&&_getRuntimeConfig5.loadEpgFakeData){_context2.next=3;break;}return _context2.abrupt("return",
_this.backstageEpgClient.getEpgDataInRange(range,newChannels));case 3:_context2.next=5;return _regeneratorRuntime.awrap(
Promise.all([
_this.backstageEpgClient.getEpgDataInRange(range,newChannels).catch(backstageCatch),
_this.stubEpgClient.getEpgDataInRange(range,newChannels).catch(stubCatch)]
));case 5:_await$Promise$all5=_context2.sent;_await$Promise$all6=_slicedToArray(_await$Promise$all5,2);backstage=_await$Promise$all6[0];stub=_await$Promise$all6[1];
result=[].concat(_toConsumableArray(addIndexToId(channels,stub)),_toConsumableArray(addIndexToId(channels,backstage)));return _context2.abrupt("return",
result);case 11:case"end":return _context2.stop();}},null,null,null,Promise);};this.


getChannels=function _callee3(){var _getRuntimeConfig6,_this$backstageEpgCli3,_this$backstageEpgCli4,_this$stubEpgClient3;var _await$Promise$all7,_await$Promise$all8,backstage,stub,channels,configMultiplier,channelMultiplier,_args3=arguments;return _regeneratorRuntime.async(function _callee3$(_context3){while(1)switch(_context3.prev=_context3.next){case 0:if((_getRuntimeConfig6=
getRuntimeConfig('features'))!=null&&_getRuntimeConfig6.loadEpgFakeData){_context3.next=2;break;}return _context3.abrupt("return",
(_this$backstageEpgCli3=_this.backstageEpgClient).getChannels.apply(_this$backstageEpgCli3,_args3));case 2:_context3.next=4;return _regeneratorRuntime.awrap(
Promise.all([
(_this$backstageEpgCli4=_this.backstageEpgClient).getChannels.apply(_this$backstageEpgCli4,_args3).catch(backstageCatch),
(_this$stubEpgClient3=_this.stubEpgClient).getChannels.apply(_this$stubEpgClient3,_args3).catch(stubCatch)]
));case 4:_await$Promise$all7=_context3.sent;_await$Promise$all8=_slicedToArray(_await$Promise$all7,2);backstage=_await$Promise$all8[0];stub=_await$Promise$all8[1];
channels=[].concat(_toConsumableArray(stub),_toConsumableArray(backstage));





configMultiplier=getRuntimeConfig().experienceChannelMultiplier;
channelMultiplier=isPlatformWeb?configMultiplier:1;return _context3.abrupt("return",
multiplyChannels(channels,channelMultiplier));case 12:case"end":return _context3.stop();}},null,null,null,Promise);};this.


getBroadcastByRecording=function _callee4(recording){return _regeneratorRuntime.async(function _callee4$(_context4){while(1)switch(_context4.prev=_context4.next){case 0:return _context4.abrupt("return",
_this.stubEpgClient.getBroadcastByRecording(recording));case 1:case"end":return _context4.stop();}},null,null,null,Promise);};this.backstageEpgClient=createBackstageEpgDataClient(params);this.stubEpgClient=new EpgDataClientStub();});



export var createExperienceEpgDataClient=function createExperienceEpgDataClient(params){
return new ExperienceEpgDataClient(params);
};