import { createSubscription, Subscription, SubscriptionStatus } from '@24i/nxg-sdk-photon';
import {
    DeprecatedSubscriptionResponse,
    SubscriptionProductsResponse,
    DeprecatedSubscription,
} from './types';

export const mapSubscriptionProductsResponse = (response: SubscriptionProductsResponse) => {
    return {
        subscriptions: response
            .filter((product) => product.type === 'subscription')
            .map(createSubscription),
    };
};
export const mapDeprecatedSubscriptionToSubscriptionProduct = (
    input: DeprecatedSubscription,
    status = SubscriptionStatus.INACTIVE
): Subscription => {
    return {
        id: input.id,
        name: input.name,
        currency: input.currency,
        description: input.description,
        externalAccessValue: input.id,
        externalAccessType: 'id',
        price: input.price,
        status: input.status,
        active: status === SubscriptionStatus.ACTIVE,
        type: 'subscription',
        period: input.period,
        nextBillingDate: (input.customerDetails?.expiresAt || 0) * 1000,
        wasCancelled: input.customerDetails?.status === SubscriptionStatus.CANCELLED,
        isWebPayment:
            input?.customerDetails?.paymentMethod !== 'android' &&
            input?.customerDetails?.paymentMethod !== 'ios',
        paymentMethod: input?.customerDetails?.paymentMethod,
    };
};
export const mapDeprecatedSubscriptionsResponse = (
    response: DeprecatedSubscriptionResponse,
    status = SubscriptionStatus.INACTIVE
): { subscriptions: Subscription[] } | never => {
    return {
        subscriptions: response.subscriptions
            .map((item) => mapDeprecatedSubscriptionToSubscriptionProduct(item, status))
            .map(createSubscription),
    };
};
