import {
    Asset,
    ASSET_TYPE,
    Edition,
    PageSection,
    ROW_TYPES,
    TimelineEvent,
} from '@24i/nxg-sdk-photon';
import {
    DRM_TYPE,
    ISourceTextTrack,
    ISourceTimelineEvent,
    ISourceTimelineEventType,
    MIME_TYPE,
    TrackKind,
    TrackType,
} from '@24i/player-base';
import { firstLetterUppercase } from '@24i/nxg-core-utils';

export const mapToPlayerMimetype = (type: string): MIME_TYPE => {
    switch (type) {
        case 'dash':
            return MIME_TYPE.DASH;
        case 'hls':
            return MIME_TYPE.HLS;
        case 'mp4':
            return MIME_TYPE.MP4_VIDEO;
        case 'webvtt':
            return MIME_TYPE.WEBVTT;
        default:
            return type as MIME_TYPE;
    }
};

export const mapToPlayerDrmSystem = (drmType: string): DRM_TYPE => {
    switch (drmType) {
        case 'widevine':
            return DRM_TYPE.WIDEVINE;
        case 'fairplay':
            return DRM_TYPE.FAIRPLAY;
        case 'playready':
            return DRM_TYPE.PLAYREADY;
        default:
            return drmType as DRM_TYPE;
    }
};

export const mapToSourceTextTrack = (subtitles: any): ISourceTextTrack => {
    const textTrack = () => {
        if (subtitles?.sources?.length) {
            for (let i = 0, j = subtitles.sources.length; i < j; i++) {
                if (mapToPlayerMimetype(subtitles.sources[i].type) === MIME_TYPE.WEBVTT) {
                    return {
                        mimeType: MIME_TYPE.WEBVTT,
                        url: subtitles.sources[i].src,
                    };
                }
            }
        }

        return {};
    };

    const {
        label,
        language,
        default: isDefault = false,
        selected = false,
        forced = false,
    } = subtitles;

    return {
        id: label,
        label,
        language,
        default: isDefault,
        selected,
        forced,
        kind: TrackKind.Subtitles,
        type: TrackType.Text,
        ...textTrack(),
    };
};

const mapToSouceTimelineEventType = (type: string): ISourceTimelineEventType => {
    switch (type) {
        case 'recap':
            return ISourceTimelineEventType.RECAP;
        case 'intro':
            return ISourceTimelineEventType.INTRO;
        case 'credits':
            return ISourceTimelineEventType.CREDITS;
        default:
            return type as ISourceTimelineEventType;
    }
};

export const mapToSourceTimelineEvent = (timelineEvent: TimelineEvent): ISourceTimelineEvent => {
    const { type, name, start, end } = timelineEvent;
    return {
        type: mapToSouceTimelineEventType(type),
        name,
        start: start * 1000,
        end: end * 1000,
    };
};

export const mapEditionToAssetTrailer = (edition: Edition, asset: Asset): Asset => {
    return {
        ...asset,
        editionId: edition.id,
        isTrailer: true,
    };
};

export const mapSearchSectionsLabels = (
    section: PageSection,
    t?: (label: string, { count }: { count: number }) => string
): PageSection => {
    let label = section.label || '';
    const itemsLength = section.items?.length || 0;

    if (t)
        switch (label) {
            case ASSET_TYPE.EPISODE:
                label = t('asset.series.episode.label', { count: itemsLength });
                break;
            case ASSET_TYPE.SERIES:
                label = t('asset.series.label', { count: itemsLength });
                break;
            case ASSET_TYPE.MOVIE:
                label = t('asset.movie', { count: itemsLength });
                break;
            case ASSET_TYPE.LIVE_EVENT:
                label = t('asset.liveEvent', { count: itemsLength });
                break;
            case ASSET_TYPE.CLIP:
                label = t('asset.clip', { count: itemsLength });
                break;
            default:
                break;
        }

    if (section.items && section.items.length > 0 && label) label += ` (${section.items.length})`;

    return {
        ...section,
        label: firstLetterUppercase(label),
    };
};

/** Currently Backstage only supports a basic search functionality that returns all results in a single grid.
 *  In the future we want to return multiple rows of different content.
 *  This map function is temporary and it was created only to test multiple rows usecase */
export const mapSearchAssetsToSections = (
    initialItems: Asset[],
    t?: (label: string, { count }: { count: number }) => string
): PageSection[] => {
    const sections: { [sectionType: string]: PageSection } = {};

    for (let i = 0; i < initialItems.length; i++) {
        const currentAsset = initialItems[i];
        const { type = '' } = currentAsset;

        if (!sections[type]) {
            sections[type] = {
                id: type,
                display: 'landscape',
                label: type,
                items: [currentAsset],
                type: ROW_TYPES.SINGLE_ROW,
                // Temporary - I know itemLimit 9999 is not ideal, but backstage doesn't support
                // fetching /search assets with query asset_type. This way we can't get all items
                // when "show all" button from row in Web is clicked. As a workaround for now we'll show
                // all searched assets by type in a row but as mentioned above, in the future backstage should
                // support page sections for search items.
                itemLimit: 9999,
            };
        } else {
            sections[type].items = [...(sections[type].items || []), currentAsset];
        }
    }

    const mappedSections: PageSection[] = Object.values(sections).map((section) =>
        mapSearchSectionsLabels(section, t)
    );

    return mappedSections;
};
