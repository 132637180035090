import { isPlatformAndroid, isPlatformIos, isPlatformTvos, isPlatformWeb } from 'renative';

export const isValidDTAP = (environment: string) => {
    const environments = ['Development', 'Testing', 'Acceptance', 'Production'];
    return environments.includes(environment);
};

export const getEnvironment = (sentryEnvironment: string) => {
    return isValidDTAP(sentryEnvironment) ? sentryEnvironment : 'Development';
};

export const getPlatform = () => {
    let platform = 'undefined';

    if (isPlatformIos) platform = 'ios';
    if (isPlatformTvos) platform = 'tvos';
    if (isPlatformAndroid) platform = 'android';
    if (isPlatformWeb) platform = 'web';

    return platform;
};
