import { AnalyticsClient, AnalyticsConfig } from '@24i/nxg-sdk-photon';
import {
    ANALYTICS_TRIGGERS,
    ANALYTICS_EVENTS,
} from '@24i/nxg-sdk-smartott-shared/src/analytics/constants';
import { AnyPayload, EventBody } from '@24i/nxg-sdk-photon/src/clients/AnalyticsClient';
import axios from 'axios';
import { isPlatformNative, isPlatformWeb, isPlatformTvos } from '@24i/nxg-core-utils';
import { Storage } from '@24i/nxg-sdk-quantum';
import { GOOGLE_ANALYTICS_TRIGGERS, TRIGGER_NAME_TO_GA_EVENTS, EVENTS } from './constants';
import { EventMappingFunction, UserPayload } from './types';
import { mapScreenView } from './mappers/mapScreenView';
import { mapScroll } from './mappers/mapScroll';
import { mapOpenPlayerSession } from './mappers/mapOpenPlayerSession';
import { mapSelectContent } from './mappers/mapSelectContent';
import { mapVideoStart } from './mappers/mapVideoStart';
import { mapVideoProgress } from './mappers/mapVideoProgress';
import { mapVideoComplete } from './mappers/mapVideoComplete';
import { mapVideoPause } from './mappers/mapVideoPause';
import { mapVideoStop } from './mappers/mapVideoStop';
import { mapClosePlayerSession } from './mappers/mapClosePlayerSession';
import { mapCloseProgress } from './mappers/mapCloseProgress';
import { mapUserProps } from './mappers/mapUserProps';
import { getUrl, getTagsBody } from './utils';
import { analytics, firebase } from './Firebase';

let webAnalytics;
let appInstanceId: string | null;
const GA_SESSION_COUNTER = 'GA_session_counter';

const eventsMapping: Record<EVENTS, EventMappingFunction> = {
    [EVENTS.SCREEN_VIEW]: mapScreenView,
    [EVENTS.SCROLL]: mapScroll,
    [EVENTS.OPEN_PLAYER_SESSION]: mapOpenPlayerSession,
    [EVENTS.SELECT_CONTENT]: mapSelectContent,
    [EVENTS.VIDEO_START]: mapVideoStart,
    [EVENTS.VIDEO_PROGRES]: mapVideoProgress,
    [EVENTS.VIDEO_COMPLETE]: mapVideoComplete,
    [EVENTS.VIDEO_PAUSE]: mapVideoPause,
    [EVENTS.VIDEO_STOP]: mapVideoStop,
    [EVENTS.CLOSE_PLAYER_SESSION]: mapClosePlayerSession,
    [EVENTS.CLOSE_PROGRESS]: mapCloseProgress,
};

const sendUserProps = async (payload: AnyPayload): Promise<void> => {
    const mappedUserProps = mapUserProps(payload as UserPayload);
    if (isPlatformWeb) {
        // @ts-expect-error firebase is only for web
        firebase.analytics().setUserProperties({
            ...mappedUserProps,
        });
    }
    if (isPlatformNative) {
        await Promise.all(
            Object.keys(mappedUserProps).map((paramName) =>
                analytics().setUserProperty(paramName, mappedUserProps[paramName])
            )
        );
    }
};

const sendTagsEvent = async (
    eventBody: EventBody,
    baseURL: string,
    version: number,
    measurementId: string,
    isFirstVisit: boolean,
    sessionCount: number
): Promise<void> => {
    try {
        const url = getUrl(baseURL, version, measurementId, eventBody, isFirstVisit, sessionCount);
        const tagsBody = getTagsBody(eventBody);
        await axios.post(url, tagsBody);
    } catch (error) {
        console.error('Google Analytics err:', error);
    }
};

export class GoogleAnalyticsClient implements AnalyticsClient {
    constructor() {
        this.triggers = GOOGLE_ANALYTICS_TRIGGERS;
    }

    name = 'GA4';

    triggers: string[];

    baseURL = '';

    measurementId = '';

    version = 0;

    apiKey = '';

    projectId = '';

    appId = '';

    isFirstVisit = false;

    sessionCount = 0;

    async init(config: AnalyticsConfig): Promise<void> {
        this.baseURL = config.baseURL;
        this.measurementId = config.options.measurementId as string;
        this.version = config.options.version as number;
        this.apiKey = config.options.apiKey as string;
        this.projectId = config.options.projectId as string;
        this.appId = config.options.appId as string;

        const firebaseConfig = {
            measurementId: this.measurementId,
            apiKey: this.apiKey,
            projectId: this.projectId,
            appId: this.appId,
        };
        if (isPlatformWeb) {
            // @ts-expect-error firebase is only for web
            firebase.initializeApp(firebaseConfig);
            // @ts-expect-error firebase is only for web
            webAnalytics = firebase.analytics();
        } else if (isPlatformNative) {
            appInstanceId = await analytics().getAppInstanceId();
        } else if (isPlatformTvos) {
            this.sessionCount =
                parseInt((await Storage.getItem(GA_SESSION_COUNTER)) ?? '0', 10) + 1;
            await Storage.setItem(GA_SESSION_COUNTER, this.sessionCount.toString());
        }
    }

    async sendEvent(eventBody: EventBody): Promise<void> {
        const { event, ...cleanBody } = eventBody;
        delete cleanBody.event;
        if (isPlatformWeb) {
            webAnalytics.logEvent(event, { ...cleanBody });
        } else if (isPlatformNative) {
            if (eventBody.event === EVENTS.SCREEN_VIEW) {
                await analytics().logScreenView({
                    ...cleanBody,
                    app_instance_id: appInstanceId,
                });
            } else {
                await analytics().logEvent(event, {
                    ...cleanBody,
                    app_instance_id: appInstanceId,
                });
            }
        } else if (isPlatformTvos) {
            await sendTagsEvent(
                eventBody,
                this.baseURL,
                this.version,
                this.measurementId,
                this.isFirstVisit,
                this.sessionCount
            );
            if (this.isFirstVisit) this.isFirstVisit = false;
        }
    }

    async onEvent(triggerName: ANALYTICS_TRIGGERS, payload: AnyPayload): Promise<void> {
        const events = TRIGGER_NAME_TO_GA_EVENTS[triggerName];
        const eventNameFromPayload = payload.payload.event;
        if (eventNameFromPayload === ANALYTICS_EVENTS.INSTALLED_APP) this.isFirstVisit = true;
        if (eventNameFromPayload === ANALYTICS_EVENTS.LOADED_APP) await sendUserProps(payload);
        if (events)
            await Promise.all(
                events.map(async (event: string) => {
                    const mappingFunction = eventsMapping[event];
                    const eventBody = mappingFunction && mappingFunction(triggerName, payload);
                    if (eventBody) await this.sendEvent(eventBody);
                })
            );
    }
}

export const createGoogleAnalyticsClient = () => new GoogleAnalyticsClient();
