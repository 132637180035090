import { useCallback, useEffect, useState, useMemo } from 'react';
import { Asset, ASSET_TYPE, Broadcast, Channel } from '@24i/nxg-sdk-photon';
import { isWithinInterval } from 'date-fns';

import { useEpgData } from '@24i/nxg-sdk-smartott-shared/src/context/EpgData';

export interface IChannel {
    channel: Channel;
    isCurrentlyPlaying: boolean;
    currentProgram?: Broadcast;
    allowsRestart?: boolean;
}

export interface IEpgData {
    channels: IChannel[];
}

export const useEpgDataManager = (asset: Asset): IEpgData | undefined => {
    const epgDataClient = useEpgData();
    const [rawEpgData, setRawEpgData] = useState<Channel[]>([]);

    const epgData = useMemo(() => {
        const channels = rawEpgData
            .map((fetchedChannel): IChannel => {
                let channelId;
                if (asset.type === ASSET_TYPE.BROADCAST) {
                    channelId = asset.channelId || (asset as any).broadcastMetadata?.channelId;
                } else if (asset.type === ASSET_TYPE.CHANNEL) {
                    channelId = asset.id || (asset as any).broadcastMetadata?.channelId;
                }
                return {
                    channel: fetchedChannel,
                    // TODO: `isCurrentlyPlaying` needs a better check. For now it's using the EpgDataChannel data struct.
                    isCurrentlyPlaying: channelId === fetchedChannel.id,
                    currentProgram: fetchedChannel?.programs?.find((program) => {
                        return program.start <= Date.now() && program.end > Date.now();
                    }),
                    allowsRestart: fetchedChannel.allowsRestart ?? true,
                };
            })
            .sort((channelA, channelB) => {
                return channelA.channel.number - channelB.channel.number;
            });
        return { channels };
    }, [rawEpgData, asset]);

    const fetchEpgData = async () => {
        /*
         * TODO: Add better filtering once EPG data are normalized. Right now it's not clear
         * TODO: how the EPG will be handled in the future. For now, keep this as is.
         */
        const isLiveAsset =
            asset?.type === ASSET_TYPE.CHANNEL ||
            (asset?.isLive && [ASSET_TYPE.EPG, ASSET_TYPE.BROADCAST].includes(asset.type));
        if (!isLiveAsset) {
            return;
        }

        const bsData = await epgDataClient.getEpgData(0, 2, new Date());
        setRawEpgData(bsData);
    };

    useEffect(() => {
        fetchEpgData();
    }, [asset]);

    /*
     * Set the data updating interval, so the EPG data are updated once per 5 minutes
     */
    useEffect(() => {
        const dataUpdatingInterval = setInterval(async () => {
            fetchEpgData();
        }, 300000);

        return () => {
            clearInterval(dataUpdatingInterval);
        };
    }, []);

    return epgData;
};

export const useEpgDataFilteredForNow = () => {
    return useCallback((epgDataToFilter: IEpgData) => {
        const finalPrograms: Broadcast[] = [];
        if (epgDataToFilter.channels instanceof Array) {
            epgDataToFilter.channels.forEach((channel) => {
                // Filter falsy programs (null / undefined)
                const programs = channel?.channel?.programs?.filter((p) => p) || [];
                const currentProgram = programs.find((program) =>
                    isWithinInterval(Date.now(), { start: program.start, end: program.end })
                );
                if (currentProgram) finalPrograms.push(currentProgram);
            });
        }
        return finalPrograms;
    }, []);
};
