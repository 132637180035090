import { Source } from '@24i/nxg-sdk-quarks/src/components/Image/types';
import { Asset, Image } from '@24i/nxg-sdk-photon/src';

export interface ImageService {
    getImageUrl(
        opts: { imagePath: string; height?: number; width?: number },
        images?: Image[]
    ): string;

    getImageSourceWithFallback(source?: Source): Source | number;

    getImageSource(type?: string): string;

    getBetterQualityAsset(url: string): string;

    getPackshotSize(): number;

    getObscuredImage(asset?: Asset): Source | undefined;
}

export abstract class AbstractImageServiceClient implements ImageService {
    private baseURI: string;

    constructor(opts: { baseURI: string }) {
        this.baseURI = opts.baseURI;
    }

    abstract getImageUrl: ImageService['getImageUrl'];

    abstract getImageSourceWithFallback: ImageService['getImageSourceWithFallback'];

    abstract getImageSource: ImageService['getImageSource'];

    abstract getBetterQualityAsset: ImageService['getBetterQualityAsset'];

    abstract getPackshotSize: ImageService['getPackshotSize'];

    abstract getObscuredImage: ImageService['getObscuredImage'];
}
