import React from 'react';
import HTML from 'react-native-render-html';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { useDimensions } from '@24i/nxg-sdk-quantum';
import { HtmlViewProps } from '../types';
import getHtmlStyle from '../styles';

const HtmlComponent = ({
    html,
    onLinkPress,
    getStyles = getHtmlStyle,
}: HtmlViewProps): JSX.Element => {
    const { theme } = useTheme();
    const dimensions = useDimensions();
    const styles = getStyles(theme, dimensions);

    return <HTML html={html} tagsStyles={styles} onLinkPress={onLinkPress} />;
};

export { getHtmlStyle };
export default HtmlComponent;
