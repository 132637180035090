import { useState } from 'react';
import { Asset } from '@24i/nxg-sdk-photon';
import { ISource, MIME_TYPE, ISourcePoster, IPlayerError, ISourceMetadata } from '@24i/player-base';
// USE /src imports to help next.js process less files to reduce bundle, avoid some problematic files, and speed up build.
import { useCastState, CastState } from '@24i/nxg-sdk-smartott/src/context/Cast';
import { useContentData } from '@24i/nxg-sdk-smartott-shared/src/context/ContentData';
import { useEffectWithCancelledFlag } from '@24i/nxg-sdk-smartott-shared/src/hooks/useEffectWithCancelledFlag';
// USE /src imports to help next.js process less files to reduce bundle, avoid some problematic files, and speed up build.
import { useTranslation } from 'react-i18next';

export const ERROR_TYPES_E08 = 'maximum number of concurrent streams reached';
export const ERROR_TYPES_E21 = 'PRODUCTS_MISMATCH_BLOCKED';

const MAX_VALID_PROGRAM_DURATION = 86400000;

export interface SourceManager {
    source?: ISource;
    getStream: (asset?: Asset) => void;
    sourceError?: Error | IPlayerError | null;
}

const mapToPlayerMimetype = (type: string): MIME_TYPE | undefined => {
    switch (type) {
        case 'dash':
            return MIME_TYPE.DASH;
        case 'hls':
            return MIME_TYPE.HLS;
        case 'mp4':
            return MIME_TYPE.MP4_VIDEO;
        case 'movie':
            return undefined;
        default:
            return type as MIME_TYPE;
    }
};

const errorFormatter = (err: { code?: string; message: string }): IPlayerError | null => {
    if (err.message === ERROR_TYPES_E08) {
        return { ...err, code: 'E08' };
    }
    if (err.message === ERROR_TYPES_E21) {
        return { message: err.message, code: 'E21' };
    }
    return { code: 'F00', ...err };
};

const getMetadata = (asset: Asset): ISourceMetadata => {
    const programStart = (asset?.startsAt || 0) * 1000 || undefined;
    const programEnd = (asset?.endsAt || 0) * 1000 || undefined;
    const isStartEndInvalid =
        programStart && programEnd && programEnd - programStart > MAX_VALID_PROGRAM_DURATION;
    return {
        title: asset?.title || '',
        description: asset?.subtitle || '',
        programStart: isStartEndInvalid ? undefined : programStart,
        programEnd: isStartEndInvalid ? undefined : programEnd,
    };
};

interface IGetStreamReturn {
    source?: ISource;
    error?: Error | IPlayerError | null;
    errorMessage?: string;
}

const useSourceManager = (
    itemAsset?: Asset,
    getStreamOverride?: { (asset: Asset): Promise<ISource | string> },
    enabled = true
): SourceManager => {
    const [source, setSource] = useState<ISource | undefined>();
    const { prepareStream } = useContentData();
    const [sourceError, setSourceError] = useState<Error | IPlayerError | null>(null);

    const { t } = useTranslation();
    const isCastConnected = useCastState() === CastState.CONNECTED;

    const getStream = async (streamAsset?: Asset): Promise<IGetStreamReturn> => {
        let sourceToBeUsed: ISource | undefined;
        let fetchStream;
        const asset = streamAsset || itemAsset;

        // TODO: Refactor to something better. This is improved legacy madness.
        try {
            if (!asset) {
                return {
                    errorMessage: `${t('error.F00.body')} - No Asset`,
                };
            }
            // Provided Asset has an embedded stream
            if (asset.stream && !asset.editionId) {
                // Asset has information already fetched
                if (typeof asset.stream === 'string') {
                    sourceToBeUsed = {
                        url: asset.stream,
                        mimeType:
                            typeof asset.type === 'string'
                                ? mapToPlayerMimetype(asset.type)
                                : undefined,
                        // @ts-ignore I'm not really sure about this drm attribute on asset.
                        // If it's a virtual property that we add to an asset after fetching something we should extend the asset type.
                        // Otherwise we should use the drm that it's inside stream. I'll not modify it now since it's working. To review...
                        drm: asset.drm,
                    };
                } else if (typeof asset.stream === 'function') {
                    // Asset provides a chained stream getting fnc.
                    fetchStream = asset.stream;
                }
            } else if (getStreamOverride) {
                fetchStream = getStreamOverride;
            } else {
                fetchStream = prepareStream;
            }
            if (fetchStream) {
                try {
                    sourceToBeUsed = await fetchStream(asset, asset.id, isCastConnected);
                } catch (error) {
                    const resultError = error as Error;
                    return {
                        error: errorFormatter(resultError),
                        errorMessage: resultError.message,
                    };
                }
            }
            if (typeof sourceToBeUsed !== 'object') {
                return {
                    error: { name: 'SOURCE_TYPE_EXCEPTION', message: '' },
                    errorMessage: '',
                };
            }

            const posters: ISourcePoster[] = [];
            if (asset.still) posters.push({ url: asset.still });
            if (asset.background) posters.push({ url: asset.background });
            if (asset.poster) posters.push({ url: asset.poster });

            return {
                source: {
                    posters,
                    metadata: getMetadata(asset),
                    ...sourceToBeUsed,
                },
            };
        } catch (error: unknown) {
            return {
                error: error instanceof Error ? error : null,
                errorMessage: `${t('error.F00.body')} - No Asset`,
            };
        }
    };

    const exportedGetStream = async (streamAsset?: Asset): Promise<ISource | string> => {
        const { source: nextSource, errorMessage } = await getStream(streamAsset);
        return nextSource || errorMessage || '';
    };

    useEffectWithCancelledFlag(
        async ({ getIsCancelled }) => {
            setSource(undefined);
            setSourceError(null);

            let nextSource;
            let nextError: Error | IPlayerError | null = null;
            if (enabled) {
                const data = await getStream(itemAsset);
                nextSource = data.source;
                nextError = data.error || null;
            }
            if (getIsCancelled()) {
                return;
            }
            setSource(nextSource);
            setSourceError(nextError);
        },
        [itemAsset, isCastConnected, enabled]
    );

    if (!itemAsset) {
        return {
            source: undefined,
            getStream: exportedGetStream,
            sourceError: null,
        };
    }

    return { source, getStream: exportedGetStream, sourceError: itemAsset ? sourceError : null };
};

export { useSourceManager };
