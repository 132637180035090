
import en from'@24i/prd-localization-files-experienceapp/dist/en.json';
import es from'@24i/prd-localization-files-experienceapp/dist/es.json';
import fr from'@24i/prd-localization-files-experienceapp/dist/fr.json';
import de from'@24i/prd-localization-files-experienceapp/dist/de.json';
import he from'@24i/prd-localization-files-experienceapp/dist/he.json';

export var i18nOptions={
resources:{
en:{sott:en},
es:{sott:es},
fr:{sott:fr},
de:{sott:de},
he:{sott:he}
},
react:{
useSuspense:false
},
fallbackLng:'en'
};