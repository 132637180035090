import React,{useMemo}from'react';
import{ScrollView,View,Text,Interactable}from'@24i/nxg-sdk-quarks';
import{useTheme}from'@24i/nxg-sdk-higgs';
import{useTranslation}from'react-i18next';
import{withWebHeader}from'@24i/nxg-sdk-smartott/src/navigation/components/withWebHeader';
import SettingsOptionPage from'@24i/nxg-sdk-smartott/src/screens/SettingsScreen/components/SettingsOptionPage/View/index.web';

import{getStyles}from"../styles/index.web";

var ServiceScreen=function ServiceScreen(props){
var
selectedService=






props.selectedService,serviceOptions=props.serviceOptions,isBackstageLoginEnabled=props.isBackstageLoginEnabled,onLoginToBackstage=props.onLoginToBackstage,signedInWithBackstage=props.signedInWithBackstage,onSignOutOfBackstage=props.onSignOutOfBackstage,onSelectedOption=props.onSelectedOption;

var _useTranslation=useTranslation(),t=_useTranslation.t;
var _useTheme=useTheme(),theme=_useTheme.theme;
var styles=useMemo(function(){return getStyles(theme);},[theme]);

var renderOptions=function renderOptions(){return(
serviceOptions==null?void 0:serviceOptions.map(function(option){return(
React.createElement(Interactable,{
testID:option.testID,
key:option.value,
onPress:function onPress(){return onSelectedOption(option.title);},
style:[
styles.itemContainer,
{
opacity:option.title===(selectedService==null?void 0:selectedService.title)?1:0.7,
':hover':{opacity:1}
}]},


React.createElement(Text,{style:styles.itemText},option.title)
));}
));};

return(
React.createElement(ScrollView,null,
React.createElement(Text,{style:styles.screenDescription},t('settings.service.description')),
React.createElement(View,{style:styles.selectorContainer},
React.createElement(SettingsOptionPage,{
description:t('common.selected')+" "+t(
'settings.service.title'
).toLocaleLowerCase(),
renderOptions:renderOptions,
subtitle:(selectedService==null?void 0:selectedService.title)||'',
optionalButtonProps:
isBackstageLoginEnabled?
{
title:signedInWithBackstage?
'Sign out of Backstage':
'Sign in with Backstage',
onPress:signedInWithBackstage?
onSignOutOfBackstage:
onLoginToBackstage,
additionalContainerStyles:styles.backstageButtonStyles
}:
undefined}

)
)
));

};

export default withWebHeader(ServiceScreen);