import { BaseApiParams } from '@24i/nxg-core-utils/src/api';
import autoBind from 'auto-bind';
import {
    ProcessReceiptRequest,
    PurchaseDataClient,
    Product,
    Edition,
    PurchasesProps,
    PLAYLIST_TYPE,
    SubscriptionStatus,
    Subscription,
} from '@24i/nxg-sdk-photon';
import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';
import { log } from '@24i/nxg-core-utils/src/logger';
import { BackstageApiBase } from '../../base';
import { EditionsResponseGuard, PlaylistResponseGuard } from '../ContentDataClient/guards';
import {
    DeprecatedSubscriptionResponseGuard,
    ExternalCheckoutResponseGuard,
    ProcessReceiptResponseGuard,
    ProductsResponseGuard,
} from './guards';
import { mapDeprecatedSubscriptionsResponse } from './mappers';
import { determineEndpointType } from '../ContentDataClient/shared';

export class BackstagePurchaseDataClient extends BackstageApiBase implements PurchaseDataClient {
    constructor(params: BaseApiParams) {
        super(params);
        autoBind(this);
    }

    // TODO: when backstage adds missing values then
    // uncomment this and remove the old one
    // async fetchAvailableSubscriptions() {
    //     return this.request({
    //         path: '/products/subscription',
    //         method: 'GET',
    //         guard: SubscriptionProductResponseGuard,
    //     }).then(mapSubscriptionProductsResponse);
    // }

    async fetchAvailableSubscriptions() {
        const promise = this.request({
            path: '/user/subscriptions/available',
            method: 'GET',
            guard: DeprecatedSubscriptionResponseGuard,
        });
        const entitledPromise = this.fetchEntitledSubscriptions();
        const [response, entitledResponse] = await Promise.all([promise, entitledPromise]);
        const result = mapDeprecatedSubscriptionsResponse(response);
        // Kind of an ugly hack because backstage doesn't have an endpoint
        // for all subscriptions that can be purchased but still better to hack
        // it here than in the sdk
        return {
            subscriptions: result.subscriptions.filter(
                (sub) => !entitledResponse.subscriptions.find((s) => s.id === sub.id)
            ),
        };
    }

    // TODO: when backstage adds missing values then
    // uncomment this and remove the old one
    // async fetchEntitledSubscriptions() {
    //     return this.request({
    //         path: '/user/products',
    //         method: 'GET',
    //         guard: ProductResponseGuard,
    //     }).then(mapSubscriptionProductsResponse);
    // }

    async fetchEntitledSubscriptions() {
        return this.request({
            path: '/user/subscriptions/entitled',
            method: 'GET',
            guard: DeprecatedSubscriptionResponseGuard,
        }).then((response) =>
            mapDeprecatedSubscriptionsResponse(response, SubscriptionStatus.ACTIVE)
        );
    }

    // eslint-disable-next-line class-methods-use-this
    async fetchSubscriptionsForChange() {
        return mapDeprecatedSubscriptionsResponse({ subscriptions: [] });
    }

    async fetchUserProducts() {
        return this.request({
            path: '/user/products',
            method: 'GET',
            guard: ProductsResponseGuard,
        });
    }

    async fetchEditions({ id, type }: PurchasesProps) {
        const editionType = determineEndpointType({ type });

        return this.request({
            path: `/media/${editionType}/${id}/editions`,
            method: 'GET',
            guard: EditionsResponseGuard,
        });
    }

    async fetchExternalCheckoutLink(productId: string) {
        return this.request({
            path: `/user/subscriptions/external-checkout/${productId}`,
            method: 'GET',
            guard: ExternalCheckoutResponseGuard,
        });
    }

    async fetchIsAssetPurchased(asset: PurchasesProps, editionId?: string) {
        const products = await this.fetchAvailableAssetPurchases(asset, editionId);

        if (isEmpty(products.availablePurchases)) {
            return { isPurchased: true };
        }

        return { isPurchased: false };
    }

    async fetchAvailableAssetPurchases(asset: PurchasesProps, editionId?: string) {
        let purchases: Product[] = [];
        try {
            const editions = await this.fetchEditions(asset);

            const availableEditions = editionId
                ? editions.filter((ed) => ed.id === editionId)
                : editions;

            const sharedProducts = availableEditions.reduce(
                (editionProducts: Product[], edition: Edition) => {
                    const editionProduct = edition?.blocked?.[0]?.resolvement?.data?.products;

                    if (editionProduct) {
                        return editionProducts.concat(editionProduct);
                    }

                    return editionProducts;
                },
                []
            );

            purchases = uniqBy(sharedProducts, 'id');
        } catch (e) {
            log(e);
        }

        return { availablePurchases: purchases };
    }

    fetchMyPlaylistByType = (type: PLAYLIST_TYPE) => {
        return this.request({
            path: `/playlists/${type}`,
            method: 'GET',
            guard: PlaylistResponseGuard,
        });
    };

    async fetchMyPurchases() {
        return this.fetchMyPlaylistByType(PLAYLIST_TYPE.PURCHASED);
    }

    async fetchMyRentals() {
        return this.fetchMyPlaylistByType(PLAYLIST_TYPE.RENTALS);
    }

    async fetchMyExpiredRentals() {
        return this.fetchMyPlaylistByType(PLAYLIST_TYPE.EXPIRED_RENTALS);
    }

    async cancelSubscription(subscription: Subscription) {
        return this.request({
            path: `/user/subscriptions/cancel/${subscription.id}`,
            method: 'DELETE',
        });
    }

    async processPayment(productId: string, receipt: ProcessReceiptRequest) {
        return this.request({
            path: `/user/products/${productId}/record-receipt`,
            method: 'POST',
            body: receipt,
            guard: ProcessReceiptResponseGuard,
        });
    }

    async processReceipt(receipt: ProcessReceiptRequest) {
        return this.request({
            path: '/user/subscriptions/process-receipt',
            method: 'POST',
            body: receipt,
            guard: ProcessReceiptResponseGuard,
        });
    }
}

export const createBackstagePurchaseDataClient = (params: BaseApiParams) => {
    return new BackstagePurchaseDataClient(params);
};
