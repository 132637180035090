import _extends from"@babel/runtime/helpers/extends";import _toConsumableArray from"@babel/runtime/helpers/toConsumableArray";import React from'react';

import SettingsScreen,{
defaultSettingsSubscreens}from
'@24i/nxg-sdk-smartott/src/screens/SettingsScreen';



import SettingsTheme from"../../components/Settings/SettingsTheme";
import SettingsService from"../../components/Settings/SettingsService";

var themeSettingsOptions=function themeSettingsOptions(_ref){var _theme$icons,_theme$icons$settings,_theme$icons$settings2;var t=_ref.t,theme=_ref.theme;return{
menuTitle:t('settings.theme.title'),
name:'theme',
title:'theme',
icon:theme==null?void 0:(_theme$icons=theme.icons)==null?void 0:(_theme$icons$settings=_theme$icons.settings)==null?void 0:(_theme$icons$settings2=_theme$icons$settings.appTheme)==null?void 0:_theme$icons$settings2.iconName,
nativeRenderOptions:{Component:SettingsTheme},
webMenuLink:{href:'/settings/theme',as:'/settings/theme'}
};};

var serviceSettingsOptions=function serviceSettingsOptions(_ref2){var _theme$icons2,_theme$icons2$setting,_theme$icons2$setting2;var t=_ref2.t,theme=_ref2.theme;return{
menuTitle:t('settings.service.title'),
name:'service',
title:'service',
icon:theme==null?void 0:(_theme$icons2=theme.icons)==null?void 0:(_theme$icons2$setting=_theme$icons2.settings)==null?void 0:(_theme$icons2$setting2=_theme$icons2$setting.service)==null?void 0:_theme$icons2$setting2.iconName,
nativeRenderOptions:{Component:SettingsService},
webMenuLink:{href:'/settings/service',as:'/settings/service'}
};};

var OverridenSettingsScreen=function OverridenSettingsScreen(props){
var subMenuScreens=[].concat(_toConsumableArray(
defaultSettingsSubscreens),[
themeSettingsOptions,
serviceSettingsOptions]).
filter(Boolean);

return React.createElement(SettingsScreen.Main,_extends({subMenuScreens:subMenuScreens},props));
};

export default OverridenSettingsScreen;