/* eslint-disable max-statements */
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { ComponentOverridesProvider } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { ModalProvider } from '@24i/nxg-sdk-gluons/src/context/Modal';
import { useTheme } from '@24i/nxg-sdk-higgs';
import Theme from '@24i/nxg-sdk-higgs/src/Theme';
import Head from 'next/head';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PinProtectionProvider from '@24i/nxg-sdk-pin-protection/src/context/PinProtection';
import type { FooterProps } from '../../components/Footer';
import Footer from '../../components/Footer';
import ReminderModal from '../../components/ReminderModal';
import GeoblockCheck from '../../context/GeoblockCheck';
import RootNavigation from '../../navigation/index.web';

const Application = ({ Component, pageProps, renderFooter }) => {
    const { theme } = useTheme();
    const router = useRouter();
    const { t } = useTranslation();

    useEffect(() => {
        const handleRouteChange = () => {
            window.scrollTo(0, 0);
        };
        router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, []);

    return (
        <>
            <Head>
                <title>{t('common.appName.description')}</title>
            </Head>
            <RootNavigation renderFooter={renderFooter}>
                <main>
                    <GeoblockCheck>
                        <Component {...pageProps} key={router.asPath.split('?')[0]} />
                    </GeoblockCheck>
                </main>
            </RootNavigation>
            {/* @ts-ignore */}
            <style global jsx>{`
                html,
                body,
                div#__next {
                    background-color: ${theme.color.background1};
                }
            `}</style>
        </>
    );
};

const ThemedApplication = ({ appTheme, componentOverrides, isPageNotFound, ...props }) => {
    const { Component, pageProps, renderFooter } = props;

    // TODO - was return <Component />; instead of null, but static pages wont build with it
    if (isPageNotFound || !appTheme) return null;

    return (
        <Theme theme={appTheme}>
            <ComponentOverridesProvider components={componentOverrides}>
                <PinProtectionProvider>
                    <ModalProvider>
                        <ReminderModal />
                        <Application
                            Component={Component}
                            pageProps={pageProps}
                            renderFooter={renderFooter}
                            {...props}
                        />
                    </ModalProvider>
                </PinProtectionProvider>
            </ComponentOverridesProvider>
        </Theme>
    );
};

export const defaultRenderFooter = (props: FooterProps) => <Footer {...props} />;

ThemedApplication.defaultProps = {
    renderFooter: defaultRenderFooter,
};

export default ThemedApplication;
