import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Linking } from 'react-native';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { View } from '@24i/nxg-sdk-quarks';
import { Loader } from '@24i/nxg-sdk-gluons';
import { PlayerUI, Player } from '@24i/player-ui-react';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { IPlayerHtmlEngine } from '@24i/player-base';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import useBlockedModal from '@24i/nxg-sdk-smartott/src/components/BlockedModal/hooks/useBlockedModal';
import { ASSET_TYPE } from '@24i/nxg-sdk-photon/src/enums';
import { SOTT_DEFAULT_SCREENS } from '@24i/nxg-sdk-smartott/src/navigation/constants';
import { onAnalytics, ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics';
import { useSessionId } from '@24i/nxg-sdk-smartott-shared/src/analytics/hooks/useSessionId';
import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import { handlePlaybackError } from '../helpers';
import getPlaybackScreenStyles from '../styles';
import { useEpgDataManager } from '../managers';
import { IPlaybackScreen } from '../types/index';
import withErrorModal from '../../../components/GenericModal/hoc/ErrorModal';

const PlaybackScreen: FunctionComponent<IPlaybackScreen> = ({
    styles = getPlaybackScreenStyles,
    onError,
    asset,
    seriesData,
    setVideoPlayerRef,
    onPlay,
    onEnd,
    onStop,
    onSeeking,
    onSeeked,
    onDurationChange,
    onPause,
    onAdBreakEnd,
    onAdBreakStart,
    onBitrateChange,
    onUnlockAction,
    onCurrentProgramChange,
    engine,
    source,
    startPosition,
    startPositionType,
    useDurationOverRemaining = true,
    blockingReason,
    blockingMessage,
    related,
    openErrorModal,
    errorMessage,
    dvrWindowOverride,
    shouldShowAirtime,
    isCheckingBlockers,
    onGoToNextEpisode,
    ...playerProps
}) => {
    const router = useRouter();
    const epgData = useEpgDataManager(asset);
    const { theme } = useTheme();

    const themedStyles = styles(theme);
    const { openBlockedModal, closeBlockedModal } = useBlockedModal();
    const { sessionId } = useSessionId();
    const [blocker, setBlocker] = useState(blockingReason);
    const deepLinking = useFeature('deepLinking');

    const deepLinkingEnabled = deepLinking?.enabled && deepLinking.storeUrls;
    const appStoreUrl = deepLinking?.storeUrls?.ios;
    const playStoreUrl = deepLinking?.storeUrls?.android;
    const { userAgent } = navigator;

    const isIOS = /iPhone|iPad|iPod/.test(userAgent);
    const isAndroid = /Android/.test(userAgent);

    useEffect(() => {
        if (deepLinkingEnabled && document.referrer === '') {
            if (isAndroid && playStoreUrl) {
                Linking.openURL(playStoreUrl);
            } else if (isIOS && appStoreUrl) {
                Linking.openURL(appStoreUrl);
            }
        }
    }, []);

    useEffect(() => {
        setBlocker(blockingReason);
    }, [blockingReason]);

    useEffect(
        () => () => {
            // send stop event when the user leaves the playback screen
            // this seems is not supported by player itself for web
            if (onEnd) onEnd();
        },
        []
    );

    useEffect(() => {
        if (asset) {
            onAnalytics(ANALYTICS_TRIGGERS.SCENE_VIEW, {
                params: asset,
                screen: SOTT_DEFAULT_SCREENS.PLAYBACK,
            });
        }

        if (asset && !!seriesData.series?.title) {
            const finalAsset = {
                ...asset,
                seriesTitle: seriesData.series?.title,
            };
            onAnalytics(ANALYTICS_TRIGGERS.PLAYER_OPEN, { asset: finalAsset, sessionId });
            if (source)
                onAnalytics(ANALYTICS_TRIGGERS.PLAYBACK_START, {
                    asset: finalAsset,
                    source,
                    sessionId,
                });
        }
    }, [asset, seriesData.series?.title, source]);

    const onBackHandler = useCallback(async () => {
        if (onStop) await onStop();
        router.back();
    }, [onStop]);

    const onEndHandler = useCallback(() => {
        if (onEnd) onEnd();
        if (asset.isTrailer || !onGoToNextEpisode || !onGoToNextEpisode(asset)) {
            router.back();
        }
    }, [onEnd, asset, seriesData]);

    const onClose = () => {
        router.back();
        closeBlockedModal();
    };

    useEffect(() => {
        handlePlaybackError(errorMessage, openBlockedModal, setBlocker, openErrorModal, onClose);
    }, [errorMessage?.title, errorMessage?.body]);

    const playerSource = source || null;
    const isLiveBroadcast: boolean =
        !!asset?.isLive &&
        [ASSET_TYPE.CHANNEL, ASSET_TYPE.EPG, ASSET_TYPE.BROADCAST].includes(asset.type);
    const shouldShowFooterPlayerButtons = !blocker || isLiveBroadcast;

    return (
        <>
            <View
                style={{
                    ...themedStyles?.holder,
                    userSelect: 'none',
                }}
            >
                {(playerSource || blocker) && engine ? (
                    <Player
                        engine={engine as IPlayerHtmlEngine}
                        onError={onError}
                        onEnd={onEndHandler}
                        hasBlockingReason={!!blocker}
                        {...playerProps}
                        {...{
                            source: playerSource,
                            onPlay,
                            onSeeking,
                            onSeeked,
                            onDurationChange,
                            onPause,
                            onStop,
                            onAdBreakEnd,
                            onAdBreakStart,
                            setVideoPlayerRef,
                            startPosition,
                            startPositionType,
                            onBitrateChange,
                            isCheckingBlockers,
                        }}
                    >
                        {(playerInstance) => (
                            <PlayerUI
                                {...{
                                    playerInstance,
                                    asset,
                                    seriesData,
                                    epgData,
                                    useDurationOverRemaining,
                                    dvrWindowOverride,
                                    shouldShowFooterPlayerButtons,
                                    shouldShowAirtime,
                                    onUnlockAction,
                                    onCurrentProgramChange,
                                }}
                                shouldShowBufferedRanges={false}
                                blockingReason={blocker}
                                blockingMessage={blockingMessage}
                                onBack={onBackHandler}
                                onGoToNextEpisode={onGoToNextEpisode}
                                relatedItems={related}
                            />
                        )}
                    </Player>
                ) : (
                    <Loader
                        color={theme.color?.textPrimary || 'white'}
                        size="large"
                        disableImageBackground
                        additionalStyles={themedStyles?.holder}
                    />
                )}
            </View>
        </>
    );
};

export default withErrorModal(overridable(PlaybackScreen, 'PlaybackScreen'));
