import { MonitoringDataClient, MonitorConfig } from '@24i/nxg-sdk-photon';
import { getEnvironment, getPlatform } from './utils';
import { init, captureException, withScope } from './Sentry';

export class SentryMonitoringDataClient implements MonitoringDataClient {
    constructor(props) {
        this.env = props?.monitoringEnvironment ? props?.monitoringEnvironment : 'Development';
        this.version = props?.appVersion;
        this.props = props;
    }

    name = 'sentry';

    env = 'Development';

    version = '0.1.0';

    props: any;

    async init(config: MonitorConfig): Promise<void> {
        const { id } = config;
        init({
            dsn: id,
            dist: getPlatform(),
            enabled: !__DEV__,
            enableNative: !__DEV__,
            release: this.version,
            tracesSampleRate: 0.2,
            ...(this.env && { environment: getEnvironment(this.env) }),
        });
    }

    // eslint-disable-next-line class-methods-use-this
    async logEvent() {}

    // eslint-disable-next-line class-methods-use-this
    async logException(exception: unknown, context?: any) {
        withScope((scope) => {
            if (context) {
                scope.setContext('extra', context);
            }
            captureException(exception, scope);
        });
    }
}

export const createSentryMonitoringDataClient = (runtimeConfig: unknown) => {
    return new SentryMonitoringDataClient(runtimeConfig);
};
