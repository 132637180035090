import { BaseApiParams } from '@24i/nxg-core-utils/src/api';
import { Asset, getIsPodcastSeries, PlayerDataClient } from '@24i/nxg-sdk-photon';
import { ISource, MIME_TYPE } from '@24i/player-base';
import { BackstageApiBase } from '../../base';

export class BackstagePlayerDataClient extends BackstageApiBase implements PlayerDataClient {
    assetHasPlaybackAction = (asset: Asset, epgType: number) => {
        if (!asset) {
            return false;
        }

        const isMovie = asset.type === 'movie';
        const isSeries = asset.type === 'series';
        const isEpisode = asset.type === 'episode';
        const isEpgLive = asset.type === 'epg' && asset.isLive;
        const isPodcastSeries = getIsPodcastSeries(asset);
        const hasCatchup = epgType === 1 && asset.enableCatchUp;

        const isLive =
            (asset.type === 'broadcast' || asset.type === 'live_event') &&
            asset.start &&
            asset.start < Date.now() &&
            asset.end &&
            asset.end > Date.now();

        const isInProgress = asset.isLive && (asset.progress ?? 0) > 0 && (asset.progress ?? 0) < 1;

        return (
            (isMovie ||
                isSeries ||
                isPodcastSeries ||
                hasCatchup ||
                isEpgLive ||
                isLive ||
                isInProgress ||
                isEpisode) ??
            false
        );
    };

    // eslint-disable-next-line class-methods-use-this
    async fetchStream(asset /* assetId */): Promise<ISource> {
        let url =
            'https://bitdash-a.akamaihd.net/content/MI201109210084_1/' +
            'm3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8';
        if (asset.type === 'broadcast') {
            url = 'https://cph-p2p-msl.akamaized.net/hls/live/2000341/test/master.m3u8';
        }
        return Promise.resolve({
            url,
            mimeType: MIME_TYPE.HLS,
        });
    }
}

export const createBackstagePlayerDataClient = (params: BaseApiParams) => {
    return new BackstagePlayerDataClient(params);
};
