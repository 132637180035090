import _createClass from"@babel/runtime/helpers/createClass";import _classCallCheck from"@babel/runtime/helpers/classCallCheck";import _inherits from"@babel/runtime/helpers/inherits";import _possibleConstructorReturn from"@babel/runtime/helpers/possibleConstructorReturn";import _getPrototypeOf from"@babel/runtime/helpers/getPrototypeOf";import _regeneratorRuntime from"@babel/runtime/regenerator";function _createSuper(Derived){var hasNativeReflectConstruct=_isNativeReflectConstruct();return function _createSuperInternal(){var Super=_getPrototypeOf(Derived),result;if(hasNativeReflectConstruct){var NewTarget=_getPrototypeOf(this).constructor;result=Reflect.construct(Super,arguments,NewTarget);}else{result=Super.apply(this,arguments);}return _possibleConstructorReturn(this,result);};}function _isNativeReflectConstruct(){if(typeof Reflect==="undefined"||!Reflect.construct)return false;if(Reflect.construct.sham)return false;if(typeof Proxy==="function")return true;try{Boolean.prototype.valueOf.call(Reflect.construct(Boolean,[],function(){}));return true;}catch(e){return false;}}
import{BackstageUserDataClient}from'@24i/nxg-integration-backstage';


var URL=
'https://raw.githubusercontent.com/24i/smart-apps-version-check/main/production/version.json';

export var ExperienceAppVersionClient=function(_BackstageUserDataCli){_inherits(ExperienceAppVersionClient,_BackstageUserDataCli);var _super=_createSuper(ExperienceAppVersionClient);function ExperienceAppVersionClient(){var _this;_classCallCheck(this,ExperienceAppVersionClient);for(var _len=arguments.length,args=new Array(_len),_key=0;_key<_len;_key++){args[_key]=arguments[_key];}_this=_super.call.apply(_super,[this].concat(args));_this.
fetchVersionConfig=function _callee(){var data;return _regeneratorRuntime.async(function _callee$(_context){while(1)switch(_context.prev=_context.next){case 0:_context.next=2;return _regeneratorRuntime.awrap(
fetch(URL));case 2:data=_context.sent;return _context.abrupt("return",
data.json());case 4:case"end":return _context.stop();}},null,null,null,Promise);};return _this;}return _createClass(ExperienceAppVersionClient);}(BackstageUserDataClient);



export var createExperienceAppVersionClient=function createExperienceAppVersionClient(params){
return new ExperienceAppVersionClient(params);
};