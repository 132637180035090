import React, { forwardRef } from 'react';
import {
    ActivityIndicator as NativeActivityIndicator,
    ActivityIndicatorProps as NativeActivityIndicatorProps,
    ViewStyle,
} from 'react-native';
import { schemaFromDefaultStyles } from '@24i/nxg-sdk-higgs';
import View from '../View';

const defaultStyles: ViewStyle = {
    justifyContent: 'center',
    alignItems: 'center',
};

interface ActivityIndicatorHolderStyles {
    holderStyles?: ViewStyle;
}

export type ActivityIndicatorProps = NativeActivityIndicatorProps & ActivityIndicatorHolderStyles;

const ActivityIndicator = forwardRef<NativeActivityIndicator, ActivityIndicatorProps>(
    (
        {
            holderStyles = defaultStyles,
            size = 'small',
            color = '#0000ff',
            animating = true,
            hidesWhenStopped = true,
            ...props
        },
        ref
    ) => (
        <View style={holderStyles}>
            <NativeActivityIndicator
                ref={ref}
                size={size}
                color={color}
                animating={animating}
                hidesWhenStopped={hidesWhenStopped}
                {...props}
            />
        </View>
    )
);

ActivityIndicator.displayName = 'ActivityIndicator_Quarks';

// TODO: [Types] Type properly. Enabling TS for this package caused a lot of errors
// @ts-expect-error
ActivityIndicator.styles = schemaFromDefaultStyles(defaultStyles);

export default ActivityIndicator;
export { ActivityIndicator };
