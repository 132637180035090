import { isPlatformWeb } from 'renative';
import { Asset, ASSET_TYPE, PageSection, Season } from '@24i/nxg-sdk-photon';
import { useContentData } from '@24i/nxg-sdk-smartott-shared/src/context/ContentData';
import { useCast } from '@24i/nxg-sdk-smartott/src/context/Cast';
import prepareAutoPlay from '@24i/nxg-sdk-smartott/src/utils/prepareAutoPlay';

import { useSourceManager } from '../../PlaybackScreen/managers';
import useAssetActions from '../../../hooks/useAssetActions';
import { useStore } from '../../../context/ApplicationStore';
import { CustomAsset, DynaRowSharedProps, DynaRowScreenProps } from '../types';
import useSectionsStructureQuery from '../../../hooks/query/dynarow/useSectionsStructureQuery';
import usePlaylistsQuery from '../../../hooks/query/dynarow/usePlaylistsQuery';
import useAssetBlockersValidation from '../../../hooks/useAssetBlockersValidation';

const PLAYLISTS_MOBILE_PAGE_SIZE = 5;

const useShared = ({
    onFetchStream,
    goToPlayer: customGoToPlayer,
    pageId = '',
    sections: sectionsFromProps,
}: DynaRowScreenProps): DynaRowSharedProps => {
    const { selectedProfile } = useStore();
    const { getStream } = useSourceManager(undefined, onFetchStream);

    const { startPlayback, isCanGoToPlayer, isCanGoToDetail, getEpisodeToWatch } =
        useAssetActions();
    const { isCastConnected, startCastingStream } = useCast();
    const { handleBlockersCheck } = useAssetBlockersValidation({});
    const { fetchEpisodes } = useContentData();

    // If sections are delivered from props, there is no need to fetch data through pageId. Placing it an empty string will disable this and following request.
    const shouldFetchData = !sectionsFromProps;
    const pageIdToFetch = shouldFetchData ? pageId : '';

    const {
        data: pageSections,
        isError: isPageSectionsError,
        isLoading: arePageSectionsLoading,
        isIdle: arePageSectionsIdle,
    } = useSectionsStructureQuery(pageIdToFetch, selectedProfile);

    // TODO - Legacy logic, why do we have different behavior for web and mobile?
    const playListSize = isPlatformWeb ? pageSections?.length : PLAYLISTS_MOBILE_PAGE_SIZE;

    const {
        data: sectionsWithPlaylistsPages,
        fetchNextPage,
        refetch,
        isError: isPlaylistsError,
        isLoading: isPlaylistLoading,
        isIdle: isPlaylistIdle,
    } = usePlaylistsQuery(pageSections || [], playListSize, selectedProfile);

    const sectionsFromQuery = sectionsWithPlaylistsPages?.pages
        ?.flat()
        .filter((section) => !!section?.items?.length || !!section?.additionalItems?.length);

    const sections: PageSection[] | undefined = sectionsFromProps || sectionsFromQuery;

    const isDataLoading =
        shouldFetchData &&
        (arePageSectionsLoading || arePageSectionsIdle || isPlaylistLoading || isPlaylistIdle);

    const handleGoToPlayer = async (
        item: Asset | CustomAsset,
        handlePurchaseAsset?: (asset: Asset) => void
    ) => {
        if (customGoToPlayer) {
            customGoToPlayer(item);
            return;
        }

        const { hasBlocker, MVPCustomMessage } = await handleBlockersCheck({
            asset: item,
            handlePurchase: handlePurchaseAsset,
            openAPTModalOnBlock: false,
        });

        if (hasBlocker && !MVPCustomMessage) return; // allow Adobe Primetime block cases to arrive to playbackScreen and show modal there

        if (isCastConnected) {
            const sourceData = await getStream(item);
            await startCastingStream(item, sourceData);
        } else {
            startPlayback(item);
        }
    };

    const handleSeriesPlayerAction = async (
        show: Asset,
        handlePurchaseAsset?: (asset: Asset) => void
    ) => {
        const seasons: Season[] = await fetchEpisodes(show.id);
        let episode;
        let seriesTitle;
        let episodeWithSeriesTitle;
        if (!show.isTrailer) {
            episode = getEpisodeToWatch(seasons || []);
            seriesTitle = show.title;
            episodeWithSeriesTitle = {
                ...episode,
                seriesTitle,
            };
        }
        handleGoToPlayer(episodeWithSeriesTitle, handlePurchaseAsset);
    };

    const onPackshotPress = async ({
        onLinkPress,
        onGoToDetails,
        section,
        asset,
        onPurchaseAsset,
    }: {
        onLinkPress: () => void;
        onGoToDetails: () => void;
        section: PageSection;
        asset: Asset;
        onPurchaseAsset?: (asset: Asset) => void;
    }) => {
        if (asset.type === ASSET_TYPE.CUSTOM) {
            onLinkPress();
        } else if (section.actionForAllItems === 'player' && asset.type === ASSET_TYPE.SERIES) {
            await prepareAutoPlay();
            handleSeriesPlayerAction(asset, onPurchaseAsset);
        } else if (
            (section.actionForAllItems === 'player' && isCanGoToPlayer(asset)) ||
            (section.actionForAllItems === 'detail' && !isCanGoToDetail(asset))
        ) {
            await prepareAutoPlay();
            handleGoToPlayer(asset, onPurchaseAsset);
        } else {
            onGoToDetails();
        }
    };

    return {
        handleGoToPlayer,
        fetchNextPage: () => fetchNextPage(),
        refetch: () => refetch(),
        onPackshotPress,
        hasError: isPageSectionsError || isPlaylistsError,
        sections,
        isLoading: isDataLoading,
        shouldFetchData,
    };
};

export default useShared;
