import { createGuard } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';

export const ImageGuard = createGuard('Image', {
    url: t.string,
    size: createGuard('Size', undefined, {
        width: t.number,
        height: t.number,
    }),
});

export const AssetImagesGuard = createGuard('AssetImages', undefined, {
    cover: t.array(ImageGuard),
    still: t.array(ImageGuard),
    poster: t.array(ImageGuard),
    background: t.array(ImageGuard),
    highlight: t.array(ImageGuard),
    logo: t.array(ImageGuard),
    portrait: t.array(ImageGuard),
    heroPortrait: t.array(ImageGuard),
    heroLandscape: t.array(ImageGuard),
    backgroundPortrait: t.array(ImageGuard),
});

export type Image = t.TypeOf<typeof ImageGuard>;
export type AssetImages = t.TypeOf<typeof AssetImagesGuard>;
