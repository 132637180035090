import React, { memo } from 'react';
import { Ratio, useTheme } from '@24i/nxg-sdk-higgs';
import { TouchableOpacity } from 'react-native';
import { Icon, View } from '@24i/nxg-sdk-quarks';
import { isPlatformWeb, isFactorTv } from 'renative';
import { PackshotActionButtonProps } from './types';
import getPackshotActionBtnStyles from './styles';

const PackshotActionButton = (props: PackshotActionButtonProps) => {
    const {
        isHighlighted,
        testID,
        actionIcon,
        onPress,
        styles: getStyles = getPackshotActionBtnStyles,
        overrideDisplayIcon = true,
    } = props;
    const { theme } = useTheme();
    const styles = getStyles(theme);

    const getIconSize = () => {
        if (isPlatformWeb) {
            if (isHighlighted) return 36;
            return 30;
        }
        if (isFactorTv) {
            if (isHighlighted) return Ratio(80);
            return Ratio(60);
        }
        return 30;
    };

    if (!actionIcon) return null;

    if (!overrideDisplayIcon) {
        return (
            <TouchableOpacity
                testID={testID}
                onPress={(event) =>
                    isPlatformWeb ? onPress?.(event) : onPress?.({ data: {}, event })
                }
            />
        );
    }

    return (
        <View style={styles.itemActionButtonContainer}>
            <TouchableOpacity
                testID={testID}
                style={[
                    styles.itemActionButton,
                    isHighlighted && styles.itemActionButtonHighlighted,
                ]}
                onPress={(event) =>
                    isPlatformWeb ? onPress?.(event) : onPress?.({ data: {}, event })
                }
            >
                <Icon
                    font={theme.icons.packshot[actionIcon]?.iconFont}
                    name={theme.icons.packshot[actionIcon].iconName}
                    size={getIconSize()}
                    color={theme.color.textButtonSecondary}
                />
            </TouchableOpacity>
        </View>
    );
};

export default memo(PackshotActionButton);
