import React from 'react';
import { useTranslation } from 'react-i18next';
import { withWebHeader } from '@24i/nxg-sdk-smartott/src/navigation/components/withWebHeader';
import { firstLetterUppercase } from '@24i/nxg-core-utils';
import SettingsDropdownPicker from '@24i/nxg-sdk-smartott/src/components/SettingsDropdownPicker';
import { Storage } from '@24i/nxg-sdk-quantum';
import { ASYNC_STORAGE_KEY_I18_NEXT_LANG } from '@24i/nxg-core-utils/src/constants';

const LanguageScreen = () => {
    const { t, i18n } = useTranslation();
    const langArray = Object.keys(i18n.options.resources || {}).map((lang) => {
        return lang;
    });
    const getTestID = (index): string => {
        return `${t(`common.${langArray[index]}`).toLowerCase()}_button`;
    };
    const onLanguageChange = (index: number) => {
        const selectedLanguage = langArray[index];
        // The line below keeps in sync the user-language and i18nextLng values inside localStorage
        Storage.setItem(ASYNC_STORAGE_KEY_I18_NEXT_LANG, selectedLanguage);
        i18n.changeLanguage(langArray[index]);
    };
    return (
        <SettingsDropdownPicker
            options={langArray.map((lang) => firstLetterUppercase(t(`settings.language.${lang}`)))}
            selectedItem={firstLetterUppercase(t(`settings.language.${i18n.language}`))}
            onSelectionPress={(index) => onLanguageChange(index)}
            title={t('settings.language.description')}
            buttonTestID="app_language_picker"
            getItemsTestID={getTestID}
        />
    );
};

export default withWebHeader(LanguageScreen);
